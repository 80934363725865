import { LockOutlined } from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Button, Card, Col, Flex, Form, Row, Spin, Tabs } from "antd";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import * as yup from "yup";
import { CustomInputPassword } from "../../shared/formFields/CustomInputPassword";
import {
  ResetPasswordStatus,
  clearResetPasswordPage,
} from "../../store/user/userSlice";
import {
  resetPassword,
  verifyResetPasswordToken,
} from "../../store/user/userThunks";

const createPasswordFormSchema = yup.object().shape({
  password: yup
    .string()
    .required("Required")
    .matches(
      /^(?=.*\p{L})(?=.*\d)\S{8,}$/u,
      "Password must contain at least 8 characters, a letter and a number."
    )
    .matches(),
  confirm_password: yup
    .string()
    .required("Required")
    .test("passwords-match", "Passwords must match", function (value) {
      return value === this.parent.password;
    }),
});

const CreatePassword = () => {
  //
  const dispatch = useDispatch();
  const tokenStatus = useSelector(
    (state) => state.user.resetPassword.tokenStatus
  );
  const isSuccess = useSelector((state) => state.user.resetPassword.isSuccess);

  //
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const resetToken = queryParams.get("reset_password_token");

  //
  useEffect(() => {
    dispatch(clearResetPasswordPage());
    dispatch(verifyResetPasswordToken({ reset_password_token: resetToken }));
    return () => {
      dispatch(clearResetPasswordPage());
    };
  }, []);

  return isSuccess ? (
    <Alert
      message={
        <div>
          Your password has successfully been reset.{" "}
          <Link to={"/login"}>Click here</Link> to go to the login page.
        </div>
      }
      type="success"
      showIcon
      className="default-margin"
    />
  ) : tokenStatus == ResetPasswordStatus.SUCCESS ? (
    <Flex justify="center" align="center" className="expanded-height" vertical>
      <Card style={{ minWidth: 350, maxWidth: 350 }}>
        <Tabs
          defaultActiveKey="1"
          centered
          items={[
            {
              label: `Air1 Insurance - Create Password`,
              key: "1",
              children: <CreatePasswordForm resetToken={resetToken} />,
            },
          ]}
        />
      </Card>
    </Flex>
  ) : tokenStatus == ResetPasswordStatus.UNKNOWN ? (
    <div style={{ height: "100%" }}>
      <Flex
        justify="center"
        align="center"
        className="expanded-height"
        vertical
      >
        <Spin spinning={true} />
      </Flex>
    </div>
  ) : (
    <Alert
      message={`Error: This link is expired or invalid!`}
      type="error"
      showIcon
      className="default-margin"
    />
  );
};

const CreatePasswordForm = ({ resetToken }) => {
  const { handleSubmit, control } = useForm({
    mode: "onSubmit",
    defaultValues: {
      confirm_password: "",
      password: "",
    },
    resolver: yupResolver(createPasswordFormSchema),
  });

  //
  const dispatch = useDispatch();

  //
  const isLoading = useSelector((state) => state.user.resetPassword.isLoading);

  const onSubmit = (values) => {
    dispatch(
      resetPassword({
        password: values.password,
        reset_password_token: resetToken,
      })
    );
  };

  return (
    <Form
      name="login_form"
      autoComplete="off"
      onFinish={() => handleSubmit(onSubmit)()}
      style={{ marginTop: 16, marginBottom: 14 }}
    >
      <Row style={{ marginBottom: 12 }}>
        <Col>Please enter your new password below:</Col>
      </Row>

      <CustomInputPassword
        formItem={{ required: true }}
        control={control}
        name="password"
        prefix={<LockOutlined />}
        type="password"
        placeholder="Password"
      />

      <CustomInputPassword
        formItem={{ required: true }}
        control={control}
        name="confirm_password"
        prefix={<LockOutlined />}
        type="password"
        placeholder="Confirm Password"
      />

      <Row>
        <Col span={24}>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            style={{ width: "100%" }}
          >
            Create New Password
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default CreatePassword;
