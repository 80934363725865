import { Button, ConfigProvider, Flex, Result } from "antd";
import { useNavigate } from "react-router-dom";
import { mainTheme } from "../../shared/themes";

const errorMessage = (status) => {
  if (status === "404") {
    return "Sorry, the page you visited does not exist.";
  }
  return "Sorry, something went wrong.";
};

const ResultPage = ({ status }) => {
  const navigate = useNavigate();
  return (<ConfigProvider theme={mainTheme}>
    <Flex
      justify="center"
      align="center"
      style={{height: "100vh"}}
      vertical
    >
      <Result
        status={status}
        title={status}
        subTitle={errorMessage(status)}
        extra={
          <Button type="primary" onClick={() => navigate("/")}>
            Back Home
          </Button>
        }
      />
    </Flex></ConfigProvider>
  );
};

export default ResultPage;
