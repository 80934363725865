import { Checkbox } from "antd";
import React, { memo } from "react";

import { useController } from "react-hook-form";
import { checkFormFieldsEquality } from "./checkFormFieldsEquality";

export const CustomCheckbox = (props) => {
  const {
    field,
    fieldState: { error, isTouched, isDirty },
  } = useController({
    name: props.name,
    control: props.control,
  });

  return (
    <Content
      {...props}
      field={field}
      error={error}
      isTouched={isTouched}
      isDirty={isDirty}
    />
  );
};

const Content = memo((props) => {
  const { field, error, formItem, setValue, name, ...inputProps } = props;
  delete inputProps.isTouched;
  delete inputProps.isDirty;
  return (
    <Checkbox
      {...field}
      checked={field.value}
      {...inputProps}
      onChange={(v) => {
        field.onChange(v);
        if (inputProps.onChange != null) {
          inputProps.onChange(v);
        }
      }}
    />
  );
}, checkFormFieldsEquality);
