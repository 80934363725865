import * as yup from "yup";

export const userFormSchema = yup.object().shape({
  name: yup.string().required("Full name required"),
  email: yup
    .string()
    .trim()
    .required("Email required")
    .email("Email invalid"),
});
