import { Alert, Button, Col, ConfigProvider, Layout, Row, Space } from "antd";
import { mainTheme, outerLayersTheme } from "../../../shared/themes";
import AuthenticatedHeaderDropdown from "./AuthenticatedHeaderDropdown";
import LayoutFooter from "./LayoutFooter";
import MainHeader from "./MainHeader";
import SideMenu from "./SideMenu";
import SubHeader from "./SubHeader";
import NotificationsWrapper from "./NotificationsWrapper";
import { SelectOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const PrivateLayout = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <ConfigProvider theme={mainTheme}>
        <NotificationsWrapper>
          <Layout className="layout">
            <ConfigProvider theme={outerLayersTheme}>
              <MainHeader>
                <Row justify="end">
                  {process.env.REACT_APP_SHOW_ENVIRONMENT_FLAG !== "false" && (
                    <Col>
                      <Row>
                        <Col
                          style={{ marginRight: "16px" }}
                          xs={0}
                          sm={0}
                          md={0}
                          lg={24}
                        >
                          <Space align="center">
                            <Alert
                              message={`${process.env.REACT_APP_SHOW_ENVIRONMENT_FLAG}`}
                              type="info"
                              showIcon
                            />
                          </Space>
                        </Col>
                      </Row>
                    </Col>
                  )}
                  <Col>
                    <Row>
                      <Col
                        xs={0}
                        sm={0}
                        md={24}
                        style={{ marginRight: "16px" }}
                      >
                        <Button
                          onClick={() =>
                            navigate("/all-forms/private-aviation")
                          }
                        >
                          <SelectOutlined />
                          Private Aviation Application
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                  <Col style={{ marginRight: "16px" }}>
                    <AuthenticatedHeaderDropdown canOpenPanel={false} />
                  </Col>
                </Row>
              </MainHeader>
            </ConfigProvider>
            <Layout hasSider={true}>
              <ConfigProvider theme={outerLayersTheme}>
                <SideMenu />
              </ConfigProvider>

              <Layout className="body-layout-parent">
                <div className="scroll-body-layout">
                  <SubHeader />
                  <div
                    style={{
                      background: "#f5f5f5",
                      flex: 1,
                    }}
                  >
                    <div style={{ marginBottom: 16 }}>{props.children}</div>
                  </div>
                  <LayoutFooter />
                </div>
              </Layout>
            </Layout>
          </Layout>
        </NotificationsWrapper>
      </ConfigProvider>
    </div>
  );
};

export default PrivateLayout;
