import { Form, Radio } from "antd";
import React, { memo } from "react";

import { useController } from "react-hook-form";
import { checkFormFieldsEquality } from "./checkFormFieldsEquality";

export const CustomRadioGroup = (props) => {
  const {
    field,
    fieldState: { error, isTouched, isDirty },
  } = useController({
    name: props.name,
    control: props.control,
  });

  return (
    <Content
      {...props}
      field={field}
      error={error}
      isTouched={isTouched}
      isDirty={isDirty}
    >
      {props.children}
    </Content>
  );
};

const Content = memo((props) => {
  const {
    field,
    error,
    formItem,
    name,
    shouldHideErrors,
    showErrors,
    ...inputProps
  } = props;
  delete inputProps.isTouched;
  delete inputProps.isDirty;
  delete inputProps.removeMargin;

  return (
    <div>
      <Form.Item
        name={name}
        validateStatus={
          error && (!shouldHideErrors || showErrors) ? "error" : ""
        }
        help={
          (!shouldHideErrors || showErrors) && error?.message != null
            ? error?.message
            : null
        }
        {...formItem}
      >
        <div>
          <Radio.Group
            {...field}
            {...inputProps}
            onChange={(e) => {
              field.onChange(e);
              inputProps.onChange && inputProps.onChange(e);
            }}
            style={props.removeMargin ? null : { marginLeft: "10px" }}
          >
            {props.children}
          </Radio.Group>
        </div>
      </Form.Item>
    </div>
  );
}, checkFormFieldsEquality);
