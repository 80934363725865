import { Card, Col, Radio, Row } from "antd";
import { useFormContext } from "react-hook-form";
import {
  HANGARED_AIRCRAFT_STORAGE,
  MOORED_AIRCRAFT_STORAGE,
  aircraft_storage,
} from "../../../../../shared/constants";
import { CustomInput } from "../../../../../shared/formFields/CustomInput";
import { CustomRadioGroup } from "../../../../../shared/formFields/CustomRadioGroup";
import { CustomSelectField } from "../../../../../shared/formFields/CustomSelectField";
import { CustomInputNumber } from "../../../../../shared/formFields/CustomInputNumber";

const AirportInformation = () => {
  const { control, watch, setValue, trigger, getValues } = useFormContext();

  const aircraftStorage = watch("aircraft_storage");
  const ownHangarCheckbox = watch("own_hangar_checkbox");
  const sameLegalEntityCheckbox = watch("same_legal_entity_checkbox");

  return (
    <div style={{ marginBottom: "12px" }}>
      <Card className="form-card-style" bordered={false}>
        <Row gutter={12}>
          <Col xs={24} sm={24} md={12}>
            <CustomInput
              formItem={{
                label: "Home base of aircraft",
                required: true,
              }}
              control={control}
              name="aircraft_home_base"
              placeholder="Airport or aerodrome name"
            />
          </Col>
          <Col xs={24} sm={24} md={12}>
            <CustomSelectField
              mode="multiple"
              options={aircraft_storage}
              formItem={{
                label: "Aircraft storage",
                required: true,
              }}
              control={control}
              name="aircraft_storage"
              placeholder="Select aircraft storage"
              onChange={(v) => {
                if (!v.includes(HANGARED_AIRCRAFT_STORAGE)) {
                  setValue("own_hangar_checkbox", null, {
                    shouldValidate: true,
                  });
                  setValue("same_legal_entity_checkbox", null, {
                    shouldValidate: true,
                  });
                  setValue("legal_entity_name", "", { shouldValidate: true });
                }
                if (!v.includes(MOORED_AIRCRAFT_STORAGE)) {
                  setValue("moor_duration", null, {
                    shouldValidate: true,
                  });
                }
              }}
            />
          </Col>
        </Row>
        {aircraftStorage?.includes(HANGARED_AIRCRAFT_STORAGE) && (
          <Row gutter={12}>
            <Col xs={24} sm={24} md={24}>
              <CustomRadioGroup
                formItem={{
                  required: true,
                  label: "Do you own the hangar?",
                }}
                control={control}
                name="own_hangar_checkbox"
                onChange={(e) => {
                  if (
                    e.target.value &&
                    (getValues("same_legal_entity_checkbox") ||
                      getValues("same_legal_entity_checkbox") == null)
                  ) {
                    trigger("legal_entity_name");
                  }
                  if (!e.target.value) {
                    trigger("same_legal_entity_checkbox");
                  }
                }}
              >
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </CustomRadioGroup>
            </Col>

            {ownHangarCheckbox && (
              <Col xs={24} sm={24} md={24}>
                <CustomRadioGroup
                  formItem={{
                    required: true,
                    label:
                      "Is the hangar owned by the same legal entity as your aircraft?",
                  }}
                  control={control}
                  name="same_legal_entity_checkbox"
                  onChange={(e) => {
                    trigger("same_legal_entity_checkbox");
                    if (e.target.value) {
                      trigger("legal_entity_name");
                    }
                  }}
                >
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </CustomRadioGroup>
              </Col>
            )}
            {(ownHangarCheckbox === false ||
              sameLegalEntityCheckbox === false) && (
              <Col xs={24} sm={24} md={24}>
                <CustomInput
                  formItem={{
                    label: "Please identify legal entity of hangar",
                    required: true,
                  }}
                  control={control}
                  name="legal_entity_name"
                  placeholder="Legal entity name"
                />
              </Col>
            )}
          </Row>
        )}
        {aircraftStorage?.includes(MOORED_AIRCRAFT_STORAGE) && (
          <Row gutter={12}>
            <Col xs={24} sm={24} md={24}>
              <CustomInput
                formItem={{
                  label: "How long do you moor your aircraft for in a year?",
                  required: true,
                }}
                control={control}
                name="moor_duration"
                placeholder="Duration"
                style={{ width: 130 }}
              />
            </Col>
          </Row>
        )}

        <Row gutter={12}>
          <Col xs={24} sm={24} md={6}>
            <CustomRadioGroup
              formItem={{
                required: true,
                label: "Type of aerodrome",
              }}
              control={control}
              name="type_of_aerodrome"
            >
              <Radio value={"Certified"}>Certified</Radio>
              <Radio value={"Private"}>Private</Radio>
            </CustomRadioGroup>
          </Col>

          <Col xs={24} sm={24} md={6}>
            <CustomRadioGroup
              formItem={{
                required: true,
                label: "Do you own the airstrip?",
              }}
              control={control}
              name="own_airstrip"
            >
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </CustomRadioGroup>
          </Col>
          <Col xs={24} sm={24} md={6}>
            <CustomInput
              formItem={{
                label: "Runway surface",
                required: true,
              }}
              control={control}
              name="runway_surface"
              placeholder="Runway surface"
              style={{ width: 130 }}
            />
          </Col>
          <Col xs={24} sm={24} md={6}>
            <CustomInputNumber
              formItem={{
                label: "Runway length",
                required: true,
              }}
              control={control}
              name="runway_length"
              placeholder="Length"
              suffix={"feet"}
              style={{ width: 125 }}
            />
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default AirportInformation;
