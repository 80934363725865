import {
  Button,
  Card,
  Col,
  Divider,
  Progress,
  Radio,
  Row,
  Typography,
} from "antd";
import { CustomRadioGroup } from "../../../../../../shared/formFields/CustomRadioGroup";
import { CustomInput } from "../../../../../../shared/formFields/CustomInput";
import { useFormContext } from "react-hook-form";
import { OptionalProductField } from "../../../formFields/OptionalProductField";

export const PrefaceStep4 = ({ navigationButtons }) => {
  const { control, watch, trigger, isSubmitted } = useFormContext();

  return (
    <Card>
      <Row justify="space-between">
        <Col>
          <Typography.Title
            level={4}
            style={{ marginTop: 0, marginBottom: 12 }}
          >
            Additional information
          </Typography.Title>
        </Col>
        <Col>
          <Progress steps={4} percent={100} showInfo={false} />
        </Col>
      </Row>

      <Typography.Text type="secondary">
        Before proceeding, please consider answering these optional questions to
        help us serve you better.
      </Typography.Text>
      <Row style={{ marginTop: 24 }}>
        <Col span={24}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <CustomInput
                formItem={{
                  label: "How did you hear about Air1 Insurance?",
                }}
                control={control}
                name="referral_reason"
                placeholder="How did you hear about Air1 Insurance?"
              />
            </Col>
            <Col span={24}>
              Would you like to receive information about any of the following
              products?
            </Col>
            <Col span={24}>
              {/* <Typography.Text>
                Which of the following products would you like to receive
                information about?
              </Typography.Text> */}
              <Row
                style={{
                  background: "#fafafa",
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
              >
                <Col flex="1 1 10px"></Col>
                <Col flex="2 2 40px">Product</Col>
                <Col flex="3 3 40px">Expiry date</Col>
              </Row>
              <Divider style={{ marginTop: 0, marginBottom: 5 }} />
              <OptionalProductField
                label="Hangar"
                control={control}
                name="hangar_option"
                watch={watch}
                trigger={trigger}
                isSubmitted={isSubmitted}
              />
              <Divider style={{ marginTop: 0, marginBottom: 5 }} />

              <OptionalProductField
                label="Business"
                control={control}
                name="business_option"
                watch={watch}
                trigger={trigger}
                isSubmitted={isSubmitted}
              />
              <Divider style={{ marginTop: 0, marginBottom: 5 }} />
              <OptionalProductField
                label="Home"
                control={control}
                name="home_option"
                watch={watch}
                trigger={trigger}
                isSubmitted={isSubmitted}
              />
              <Divider style={{ marginTop: 0, marginBottom: 5 }} />
              <OptionalProductField
                label="Marine"
                control={control}
                name="marine_option"
                watch={watch}
                trigger={trigger}
                isSubmitted={isSubmitted}
              />
            </Col>
          </Row>
          <div style={{ marginBottom: 24 }} />
          {navigationButtons}
        </Col>
      </Row>
    </Card>
  );
};
