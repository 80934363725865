import { Card, Col, Progress, Radio, Row, Space, Typography } from "antd";
import { CustomRadioGroup } from "../../../../../../shared/formFields/CustomRadioGroup";
import { useFormContext } from "react-hook-form";

export const PrefaceStep2 = ({ navigationButtons }) => {
  const { control, watch } = useFormContext();
  const currentlyInsured = watch("currently_insured");
  return (
    <Card>
      <Row justify="space-between">
        <Col>
          <Typography.Title
            level={4}
            style={{ marginTop: 0, marginBottom: 12 }}
          >
            Policy status
          </Typography.Title>
        </Col>
        <Col>
          <Progress steps={4} percent={50} showInfo={false} />
        </Col>
      </Row>

      <Typography.Text type="secondary">
        Please confirm your current insurance status.
      </Typography.Text>

      <Row style={{ marginTop: 24 }}>
        <Col span={24}>
          <Row>
            <Col span={24}>
              {currentlyInsured === true && (
                <CustomRadioGroup
                  formItem={{
                    required: true,
                    label:
                      "Is this an application for a new aircraft or are you renewing an existing policy?",
                  }}
                  control={control}
                  name="new_or_renew"
                  removeMargin={true}
                >
                  <Space direction="vertical">
                    <Radio value={"new"}>Application for a new aircraft</Radio>
                    <Radio value={"renew"}>Renew an existing policy</Radio>
                  </Space>
                </CustomRadioGroup>
              )}
              {currentlyInsured === false && (
                <CustomRadioGroup
                  formItem={{
                    required: true,
                    label: "Do you currently have insurance on this aircraft?",
                  }}
                  buttonStyle="solid"
                  control={control}
                  name="has_other_insurance"
                  removeMargin={true}
                >
                  <Radio.Button value={true}>Yes</Radio.Button>
                  <Radio.Button value={false}>No</Radio.Button>
                </CustomRadioGroup>
              )}
            </Col>
          </Row>
          {navigationButtons}
        </Col>
      </Row>
    </Card>
  );
};
