/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Collapse, Grid, Radio, Row, Space } from "antd";
import { useFormContext } from "react-hook-form";
import { CustomDatePicker } from "../../../../../../shared/formFields/CustomDatePicker";
import { CustomInput } from "../../../../../../shared/formFields/CustomInput";
import { CustomInputNumber } from "../../../../../../shared/formFields/CustomInputNumber";
import { CustomRadioGroup } from "../../../../../../shared/formFields/CustomRadioGroup";
import { CustomSelectField } from "../../../../../../shared/formFields/CustomSelectField";
import { MembershipNumbers } from "../../../formFields/MembershipNumbers";

const PilotInformationForm = ({ item, baseName, index, showErrors }) => {
  const { control, trigger, watch, getValues } = useFormContext();
  const screens = Grid.useBreakpoint();
  const isXs = screens.xs === true && !screens.sm;
  const hasHadAccidents = watch(baseName + "has_had_accidents");

  return (
    <div key={item.id}>
      <Collapse
        defaultActiveKey={["1", "2"]}
        style={{ marginBottom: "12px" }}
        activeKey={["1", "2"]}
      >
        <Collapse.Panel header="Information" key="1">
          <Row gutter={12}>
            <Col xs={24} sm={24} md={6}>
              <CustomInput
                shouldHideErrors={true}
                showErrors={showErrors}
                formItem={{ required: true, label: "Pilot First Name" }}
                control={control}
                name={baseName + "pilot_first_name"}
                placeholder="Enter first name"
              />
            </Col>
            <Col xs={24} sm={24} md={6}>
              <CustomInput
                shouldHideErrors={true}
                showErrors={showErrors}
                formItem={{ required: true, label: "Pilot Last Name" }}
                control={control}
                name={baseName + "pilot_last_name"}
                placeholder="Enter last name"
              />
            </Col>
            <Col xs={24} sm={24} md={12}>
              <CustomInput
                shouldHideErrors={true}
                showErrors={showErrors}
                formItem={{ required: true, label: "Occupation" }}
                control={control}
                name={baseName + "pilot_occupation"}
                placeholder="Occupation"
              />
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Row style={{ marginBottom: 8 }}>
                <Col span={24}>
                  <span style={{ color: "#FF4D4F" }}>* </span>Date of birth
                </Col>
              </Row>

              <Space>
                <CustomSelectField
                  shouldHideErrors={true}
                  showErrors={showErrors}
                  options={[
                    {
                      value: 1,
                      label: "January",
                    },
                    {
                      value: 2,
                      label: "February",
                    },
                    {
                      value: 3,
                      label: "March",
                    },
                    {
                      value: 4,
                      label: "April",
                    },
                    {
                      value: 5,
                      label: "May",
                    },
                    {
                      value: 6,
                      label: "June",
                    },
                    {
                      value: 7,
                      label: "July",
                    },
                    {
                      value: 8,
                      label: "August",
                    },
                    {
                      value: 9,
                      label: "September",
                    },
                    {
                      value: 10,
                      label: "October",
                    },
                    {
                      value: 11,
                      label: "November",
                    },
                    {
                      value: 12,
                      label: "December",
                    },
                  ]}
                  formItem={{
                    required: true,
                  }}
                  style={{ width: 105 }}
                  control={control}
                  name={baseName + "dob_month"}
                  placeholder="Month"
                  listHeight={500}
                />
                <CustomInputNumber
                  formItem={{
                    required: true,
                  }}
                  maxLength={2}
                  style={{ width: 90 }}
                  control={control}
                  name={baseName + "dob_day"}
                  placeholder="Day"
                  shouldHideErrors={true}
                  showErrors={showErrors}
                />
                <CustomInputNumber
                  formItem={{
                    required: true,
                  }}
                  maxLength={4}
                  style={{ width: 90 }}
                  control={control}
                  name={baseName + "dob_year"}
                  placeholder="Year"
                  shouldHideErrors={true}
                  showErrors={showErrors}
                />
              </Space>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <CustomSelectField
                options={[
                  {
                    value: "PRIV",
                  },
                  {
                    value: "COMM",
                  },
                  {
                    value: "ATP",
                  },
                  {
                    value: "REC",
                  },
                  {
                    value: "STU",
                  },
                  {
                    value: "UL",
                  },
                  {
                    value: "FLOAT",
                  },
                  {
                    value: "MULTI",
                  },
                  {
                    value: "IFR",
                  },
                  {
                    value: "NIGHT",
                  },
                ]}
                listHeight={500}
                mode="multiple"
                shouldHideErrors={true}
                showErrors={showErrors}
                formItem={{
                  label: "License/Ratings",
                  required: true,
                  style: !isXs ? null : { marginBottom: "60px" },
                }}
                control={control}
                name={baseName + "license_ratings"}
                placeholder="Select all that apply"
                isXs={isXs}
              />
            </Col>
            <Col span={24}>
              <CustomRadioGroup
                shouldHideErrors={true}
                showErrors={showErrors}
                formItem={{
                  required: true,
                  label:
                    "Has this pilot had any accidents, claims, license limitations or violations surrender or revocation, or transport canada citations in the last 5 years?",
                  className: "increased-xs-margin",
                }}
                control={control}
                name={baseName + "has_had_accidents"}
                onChange={(e) => {
                  if (!e.target.value) {
                    trigger(baseName + "accidents_claim_amount");
                  }
                }}
              >
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </CustomRadioGroup>
            </Col>
            {hasHadAccidents === true && (
              <Col span={24}>
                <CustomInput
                  shouldHideErrors={true}
                  showErrors={showErrors}
                  formItem={{
                    required: true,
                    label: "Please provide the amount of the claim",
                  }}
                  control={control}
                  name={baseName + "accidents_claim_amount"}
                  placeholder="More details"
                />
              </Col>
            )}
            <Col span={24}>
              <CustomInput
                formItem={{
                  required: false,
                  label:
                    "Please provide information about recurrent training in the last 12 months",
                }}
                control={control}
                name={baseName + "recurrent_training"}
                placeholder="Details on recurrent training"
              />
            </Col>
            <Col span={24}>
              <MembershipNumbers
                name={baseName}
                index={index}
                control={control}
                getValues={getValues}
              />
            </Col>
          </Row>
        </Collapse.Panel>
        <Collapse.Panel header="Hours" key="2">
          <Row gutter={12}>
            <Col xs={24} sm={24} md={6}>
              <CustomInputNumber
                shouldHideErrors={true}
                showErrors={showErrors}
                formItem={{
                  required: true,
                  label: "Total All Aircraft",
                }}
                control={control}
                name={baseName + "total_all_aircraft"}
                placeholder="Hour(s)"
                style={{ width: 150 }}
              />
            </Col>
            <Col xs={24} sm={24} md={6}>
              <CustomInputNumber
                shouldHideErrors={true}
                showErrors={showErrors}
                formItem={{
                  required: true,
                  label: "Total Make & Model",
                }}
                control={control}
                name={baseName + "total_make_model"}
                placeholder="Hour(s)"
                style={{ width: 150 }}
              />
            </Col>
            <Col xs={24} sm={24} md={6}>
              <CustomInputNumber
                formItem={{
                  required: false,
                  label: "Total Floats",
                }}
                control={control}
                name={baseName + "total_floats"}
                placeholder="Hour(s)"
                style={{ width: 150 }}
              />
            </Col>
            <Col xs={24} sm={24} md={6}>
              <CustomInputNumber
                formItem={{
                  required: false,
                  label: "Total Amphibian",
                }}
                control={control}
                name={baseName + "total_amphibian"}
                placeholder="Hour(s)"
                style={{ width: 150 }}
              />
            </Col>
            <Col xs={24} sm={24} md={6}>
              <CustomInputNumber
                formItem={{
                  required: false,
                  label: "Total Retract",
                }}
                control={control}
                name={baseName + "total_retract"}
                placeholder="Hour(s)"
                style={{ width: 150 }}
              />
            </Col>
            <Col xs={24} sm={24} md={6}>
              <CustomInputNumber
                formItem={{
                  required: false,
                  label: "Total Tailwheel",
                }}
                control={control}
                name={baseName + "total_tailwheel"}
                placeholder="Hour(s)"
                style={{ width: 150 }}
              />
            </Col>
            <Col xs={24} sm={24} md={6}>
              <CustomInputNumber
                formItem={{
                  required: false,
                  label: "Total Multi",
                }}
                control={control}
                name={baseName + "total_multi"}
                placeholder="Hour(s)"
                style={{ width: 150 }}
              />
            </Col>
            <Col xs={24} sm={24} md={6}>
              <CustomInputNumber
                formItem={{
                  required: false,
                  label: "Total Turbine",
                }}
                control={control}
                name={baseName + "total_turbine"}
                placeholder="Hour(s)"
                style={{ width: 150 }}
              />
            </Col>
            <Col xs={24} sm={24} md={6}>
              <CustomInputNumber
                formItem={{
                  required: false,
                  label: "Total Rotor",
                }}
                control={control}
                name={baseName + "total_rotor"}
                placeholder="Hour(s)"
                style={{ width: 150 }}
              />
            </Col>
            <Col xs={24} sm={24} md={6}>
              <CustomInputNumber
                shouldHideErrors={true}
                showErrors={showErrors}
                formItem={{
                  required: true,
                  label: "Total last 12 months All Aircraft",
                }}
                control={control}
                name={baseName + "total_last_twelve_months_all"}
                placeholder="Hour(s)"
                style={{ width: 150 }}
              />
            </Col>
            <Col xs={24} sm={24} md={6}>
              <CustomInputNumber
                shouldHideErrors={true}
                showErrors={showErrors}
                formItem={{
                  required: true,
                  label: "Total last 12 months Make & Model",
                }}
                control={control}
                name={baseName + "total_last_twelve_months_make_model"}
                placeholder="Hour(s)"
                style={{ width: 150 }}
              />
            </Col>
            <Col span={24}>
              <CustomInput
                formItem={{
                  label: "Notes and comments",
                }}
                control={control}
                name={baseName + "notes_and_comments"}
                placeholder="Notes and comments"
              />
            </Col>
          </Row>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default PilotInformationForm;
