import { isNull } from "lodash";
import * as yup from "yup";

export const step1Schema = yup.object().shape({
  currently_insured: yup.boolean().required("Required"),
});

export const step2Schema = yup.object().shape({
  new_or_renew: yup.string().when("currently_insured", {
    is: (v) => v === true,
    then: (schema) => schema.required("Required"),
    otherwise: (schema) => schema.nullable(),
  }),
  has_other_insurance: yup.boolean().when("currently_insured", {
    is: (v) => v === false,
    then: (schema) => schema.required("Required"),
    otherwise: (schema) => schema.nullable(),
  }),
});

export const step3Schema = yup.object().shape({
  other_insurance_expiry_date: yup
    .string()
    .when(["currently_insured", "has_other_insurance"], {
      is: (v1, v2) => v1 === false && v2 === true,
      then: (schema) => schema.required("Required"),
      otherwise: (schema) => schema.nullable(),
    }),
  other_insurance_carrier: yup
    .string()
    .when(["currently_insured", "has_other_insurance"], {
      is: (v1, v2) => v1 === false && v2 === true,
      then: (schema) => schema.required("Required"),
      otherwise: (schema) => schema.nullable(),
    }),
  other_insurance_broker: yup
    .string()
    .when(["currently_insured", "has_other_insurance"], {
      is: (v1, v2) => v1 === false && v2 === true,
      then: (schema) => schema.required("Required"),
      otherwise: (schema) => schema.nullable(),
    }),
  coverage_date: yup.string().required("Required"),
});

export const step4Schema = yup.object().shape({
  referral_reason: yup.string().nullable(),
  hangar_option_checkbox: yup.boolean(),
  hangar_option_date: yup.string().when("hangar_option_checkbox", {
    is: true,
    then: (schema) => schema.required("Hangar expiry date required"),
    otherwise: (schema) => schema.nullable(),
  }),
  business_option_checkbox: yup.boolean(),
  business_option_date: yup.string().when("business_option_checkbox", {
    is: true,
    then: (schema) => schema.required("Business expiry date required"),
    otherwise: (schema) => schema.nullable(),
  }),
  home_option_checkbox: yup.boolean(),
  home_option_date: yup.string().when("home_option_checkbox", {
    is: true,
    then: (schema) => schema.required("Home expiry date required"),
    otherwise: (schema) => schema.nullable(),
  }),
  marine_option_checkbox: yup.boolean(),
  marine_option_date: yup.string().when("marine_option_checkbox", {
    is: true,
    then: (schema) => schema.required("Marine expiry date required"),
    otherwise: (schema) => schema.nullable(),
  }),
});
