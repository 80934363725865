import { useLocation } from "react-router-dom";

export const usePathname = () => {
  const { pathname } = useLocation();

  const splittedPathname = pathname
    .split("/")
    .filter(function (element) {
      return element !== "";
    })
    .map(function (element) {
      return `/${element}`;
    });

  const subpaths = splittedPathname.length < 1 ? ["/"] : splittedPathname;

  return { pathname, subpaths };
};
