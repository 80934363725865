export const checkFormFieldsEquality = (prev, next) => {
  return (
    prev.isDirty === next.isDirty &&
    prev.isTouched === next.isTouched &&
    prev.field.value === next.field.value &&
    prev.field.name === next.field.name &&
    prev.name === next.name &&
    prev.value === next.value &&
    prev.error === next.error &&
    prev.disabled === next.disabled &&
    prev.loading === next.loading &&
    prev.onBlur === next.onBlur &&
    prev.isXs === next.isXs &&
    prev.showErrors === next.showErrors &&
    prev.isSubmitted === next.isSubmitted
  );
};
