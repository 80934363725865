import { Button, Card, Col, Row } from "antd";
import React, { memo } from "react";
import { useFieldArray } from "react-hook-form";
import {
  PASSENGER_OPTIONS,
  PREMISES_OPTIONS,
  TPL_OPTIONS,
} from "../../../../shared/constants";
import { CustomSelectField } from "../../../../shared/formFields/CustomSelectField";
import { PlusOutlined } from "@ant-design/icons";

export const PhoneTypes = {
  HOME: "HOME",
  WORK: "WORK",
  CELL: "CELL",
  FAX: "FAX",
};

export class CoverageOption {
  constructor(tpl = 1000000, passenger = 100000, premises = 0) {
    this.third_party_liability = tpl;
    this.passenger_liability = passenger;
    this.premises_liability = premises;
  }
}

export const CustomCoverageOptions = (props) => {
  const { fields, append, remove } = useFieldArray({
    control: props.control,
    name: "custom_coverage_options",
  });

  return (
    <Content
      {...props}
      fields={fields}
      control={props.control}
      append={append}
      remove={remove}
    />
  );
};

const Content = memo((props) => {
  const { fields, control, append, remove } = props;
  return (
    <Row gutter={[12, 12]}>
      {fields.map((item, index) => (
        <Col xs={24} sm={24} md={12} key={item.id}>
          <Card
            title={"Custom Coverage " + (index + 1).toString()}
            extra={
              <Button
                danger={true}
                onClick={() => {
                  remove(index);
                  props.trigger("liability_validation");
                }}
              >
                Remove
              </Button>
            }
          >
            <Row justify="space-between">
              <Col>
                <CustomSelectField
                  options={TPL_OPTIONS}
                  formItem={{
                    required: true,
                    label: "Third Party Liability",
                  }}
                  // style={{ width: "175px" }}
                  control={control}
                  name={`custom_coverage_options[${index}].third_party_liability`}
                />
              </Col>
              <Col>
                <CustomSelectField
                  options={PASSENGER_OPTIONS}
                  formItem={{
                    required: true,
                    label: "Passenger Liability",
                  }}
                  // style={{ width: "175px" }}
                  control={control}
                  name={`custom_coverage_options[${index}].passenger_liability`}
                />
              </Col>

              <Col>
                <CustomSelectField
                  options={PREMISES_OPTIONS}
                  formItem={{
                    required: false,
                    label: "Premises Liability",
                  }}
                  // style={{ width: "175px" }}
                  control={control}
                  name={`custom_coverage_options[${index}].premises_liability`}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      ))}
      <Col span={24}>
        <Row justify="center">
          <Col>
            <Button
              type="link"
              size="medium"
              onClick={() =>
                props.sendMaxiumOptionsWarning(() => {
                  append(new CoverageOption());
                  props.trigger("liability_validation");
                })
              }
            >
              <PlusOutlined /> I want to add custom coverage
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
});
