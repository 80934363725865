import { SelectOutlined } from "@ant-design/icons";
import { Alert, Button, Col, Result, Row } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

const CustomErrorBoundary = ({ error, resetErrorBoundary }) => {
  const navigate = useNavigate();
  return (
    <Result
      status="error"
      title="Something went wrong"
      subTitle="An unexpected error has occured."
      extra={[
        <Row justify="center">
          <Col style={{ maxWidth: 850 }}>
            <Alert
              message={error.message}
              showIcon
              description={error.stack}
              type="error"
              style={{ textAlign: "start" }}
              action={
                <Button
                  size="small"
                  danger
                  onClick={() => {
                    navigate("/", { replace: true });
                    resetErrorBoundary();
                  }}
                >
                  <SelectOutlined />
                  Go Home
                </Button>
              }
            />
          </Col>
        </Row>,
      ]}
    />
  );
};

export default CustomErrorBoundary;
