import { MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { Alert, Button, Flex } from "antd";
import { downloadFile } from "../../../../../shared/downloadFile";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setIsDownloading } from "../../../../../store/downloads/downloadsSlice";

export const Submitted = () => {
  const { applicationId } = useParams();
  const dispatch = useDispatch();
  const isDownloading = useSelector((state) => state.downloads.isDownloading);

  useEffect(() => {
    dispatch(setIsDownloading(false));
  }, []);

  return (
    <div style={{ height: "100%" }} className="centered-body-background">
      <Flex
        justify="center"
        align="center"
        className="expanded-height"
        vertical
      >
        <Alert
          message="Submitted"
          showIcon
          description={
            <p>
              Thank you for submitting your private aviation form. Our team has
              received it, and we will be in touch shortly.
              <br />
              <br />
              If you have any inquiries about your application, please feel free
              to contact us:
              <ul>
                <li style={{ listStyleType: "none" }}>
                  <MailOutlined />
                  &nbsp;&nbsp;
                  <a href="mailto:info@air1insurance.com">
                    info@air1insurance.com
                  </a>
                </li>
                <li style={{ listStyleType: "none" }}>
                  <PhoneOutlined />
                  &nbsp;&nbsp;
                  <a href="tel:8889171177">(888) 917-1177</a>
                </li>
              </ul>
              Additionally, you can
              <Button
                loading={isDownloading}
                type="link"
                size="small"
                onClick={() => {
                  dispatch(
                    downloadFile({
                      defaultName: "PrivateAviationForm",
                      url: `/private_aviation/forms/${applicationId}/serve_pdf`,
                    })
                  );
                }}
              >
                click here
              </Button>
              to download a PDF summary of your application.
            </p>
          }
          type="success"
          style={{
            minWidth: 300,
            maxWidth: 800,
            marginRight: "24px",
            marginLeft: "24px",
          }}
          action={null}
        />
      </Flex>
    </div>
  );
};
