import { Card, Col, Grid, Radio, Row } from "antd";
import { useFormContext } from "react-hook-form";
import { CustomInput } from "../../../../../shared/formFields/CustomInput";
import { CustomInputNumber } from "../../../../../shared/formFields/CustomInputNumber";
import { CustomRadioGroup } from "../../../../../shared/formFields/CustomRadioGroup";
import { CustomSelectField } from "../../../../../shared/formFields/CustomSelectField";
import { TripDurationField } from "../../formFields/TripDurationField";

const AircraftInformationExtended = () => {
  const screens = Grid.useBreakpoint();
  const isXs = screens.xs === true && !screens.sm;
  const { control, watch, trigger } = useFormContext();
  const flyingOutsideCanada = watch("flying_outside_canada");
  const airshows = watch("airshows_checkbox");
  const aircraftForAerobatics = watch("aircraft_for_aerobatics_checkbox");
  const landingOffAirport = watch("landing_off_airport_checkbox");

  return (
    <div>
      <Card className="form-card-style" bordered={false} title="Trips">
        <Row gutter={12}>
          <Col span={24}>
            <CustomRadioGroup
              formItem={{
                required: true,
                label: "Will you be flying outside of Canada?",
                style: flyingOutsideCanada ? { marginBottom: "8px" } : null,
              }}
              control={control}
              name="flying_outside_canada"
              onChange={(e) => {
                if (!e.target.value) {
                  trigger("leaving_canada_amount");
                }
              }}
            >
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </CustomRadioGroup>
          </Col>
        </Row>
        {flyingOutsideCanada && (
          <Row>
            <Col span={24}>
              <CustomSelectField
                options={[
                  {
                    value: "Continental United States of America",
                  },
                  {
                    value: "The French islands of St.Pierre and Miquelon",
                  },
                  {
                    value: "The Republic of Mexico",
                  },
                  {
                    value: "The Bahamas islands",
                  },
                  {
                    value: "The Caribbean",
                  },
                  {
                    value: "Alaska",
                  },
                ]}
                mode="multiple"
                formItem={{
                  label:
                    "Which of the following areas outside of Canada will the aircraft be flying into?",
                }}
                control={control}
                name="aircraft_flying"
                placeholder="Select an option"
              />
            </Col>
            <Col span={24}>
              <CustomInput
                formItem={{
                  label: "Enter any other non listed area outside of Canada",
                }}
                control={control}
                name="other_listed_area"
                placeholder="Other non listed area outside of Canada"
                isXs={isXs}
                style={!isXs ? null : { marginBottom: "20px" }}
              />
            </Col>
            <Col span={24}>
              <TripDurationField
                control={control}
                isXs={isXs}
                trigger={trigger}
              />
            </Col>
            <Col span={24}>
              <CustomInputNumber
                formItem={{
                  required: true,
                  label:
                    "Roughly how many times will the aircraft be leaving Canada in a 12 month period?",
                  className: "increased-xs-margin",
                }}
                style={{ width: 90 }}
                control={control}
                name="leaving_canada_amount"
                placeholder="Estimate"
              />
            </Col>
          </Row>
        )}
      </Card>
      <Card className="form-card-style" bordered={false} title="Other">
        <Row gutter={12}>
          <Col span={24}>
            <CustomRadioGroup
              formItem={{
                required: true,
                label: "Do you participate in airshows?",
                style: airshows ? { marginBottom: "8px" } : null,
              }}
              control={control}
              name="airshows_checkbox"
              onChange={(e) => {
                if (!e.target.value) {
                  trigger("airshows_input");
                }
              }}
            >
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </CustomRadioGroup>
          </Col>
          {airshows && (
            <Col xs={24} sm={24} md={12}>
              <CustomInput
                formItem={{
                  required: true,
                  label: "Please provide details",
                }}
                control={control}
                name="airshows_input"
                placeholder="Type here"
              />
            </Col>
          )}
        </Row>
        <Row gutter={12}>
          <Col span={24}>
            <CustomRadioGroup
              formItem={{
                required: true,
                label: "Do you use the aircraft for aerobatics?",
                style: aircraftForAerobatics ? { marginBottom: "8px" } : null,
              }}
              control={control}
              name="aircraft_for_aerobatics_checkbox"
              onChange={(e) => {
                if (!e.target.value) {
                  trigger("aircraft_for_aerobatics_input");
                }
              }}
            >
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </CustomRadioGroup>
          </Col>
          {aircraftForAerobatics && (
            <Col xs={24} sm={24} md={12}>
              <CustomInput
                formItem={{
                  required: true,
                  label: "Please provide details",
                }}
                control={control}
                name="aircraft_for_aerobatics_input"
                placeholder="Type here"
              />
            </Col>
          )}
        </Row>

        <Row gutter={12} style={{ marginBottom: "12px" }}>
          <Col span={24}>
            <CustomRadioGroup
              formItem={{
                required: true,
                label: "Do you plan on landing at off airport locations?",
                style: landingOffAirport ? { marginBottom: "8px" } : null,
              }}
              control={control}
              name="landing_off_airport_checkbox"
              onChange={(e) => {
                if (!e.target.value) {
                  trigger("landing_off_airport_input");
                }
              }}
            >
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </CustomRadioGroup>
          </Col>
          {landingOffAirport && (
            <Col xs={24} sm={24} md={12}>
              <CustomInput
                formItem={{
                  required: true,
                  label: "Please provide details",
                }}
                control={control}
                name="landing_off_airport_input"
                placeholder="Type here"
              />
            </Col>
          )}
        </Row>
      </Card>
    </div>
  );
};

export default AircraftInformationExtended;
