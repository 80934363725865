import { Spin } from "antd";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuthStatus } from "./useAuthStatus";
import { logoutUser } from "../../store/user/userThunks";

const Logout = () => {
  const dispatch = useDispatch();
  const { isLoggedIn, refreshAuthStatus } = useAuthStatus();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(logoutUser());
      navigate("/login");
    } else {
      refreshAuthStatus();
    }
  }, [isLoggedIn]);

  if (!isLoggedIn) {
    return <Navigate to="/login" replace={true} />;
  }

  return (
    <Spin
      spinning={true}
      tip="Please wait for a moment..."
      fullscreen
      size="large"
    />
  );
};

export default Logout;
