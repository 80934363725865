import { Form, Input } from "antd";
import React, { memo } from "react";

import { useController } from "react-hook-form";
import { checkFormFieldsEquality } from "../../../../shared/formFields/checkFormFieldsEquality";

export const PostalCodeInput = (props) => {
  const {
    field,
    fieldState: { error, isTouched, isDirty },
  } = useController({
    name: props.name,
    control: props.control,
  });

  return (
    <Content
      {...props}
      field={field}
      error={error}
      isTouched={isTouched}
      isDirty={isDirty}
    />
  );
};

const Content = memo((props) => {
  const { field, error, formItem, name, ...inputProps } = props;
  delete inputProps.isTouched;
  delete inputProps.isDirty;
  return (
    <Form.Item
      name={name}
      validateStatus={error ? "error" : ""}
      help={error?.message}
      {...formItem}
    >
      <div>
        {props.children}
        <Input
          {...field}
          {...inputProps}
          maxLength={7}
          onChange={(e) => {
            var value = (e?.target?.value ?? "").toUpperCase();
            if (value.length > 3 && value[3] !== " ") {
              value = value.slice(0, 3) + " " + value.slice(3);
            }
            value = value.slice(0, 7);
            field.onChange(value);
          }}
        />
      </div>
    </Form.Item>
  );
}, checkFormFieldsEquality);
