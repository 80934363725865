import { Navigate, Outlet } from "react-router-dom";
import PrivateLayout from "../layouts/PrivateLayout";
import { useAuthStatus } from "../useAuthStatus";

const PrivateRoutesWrapper = () => {
  const { isLoggedIn } = useAuthStatus();

  if (!isLoggedIn) {
    return <Navigate to="/login" replace={true} />;
  } else {
    return (
      <PrivateLayout>
        <Outlet />
      </PrivateLayout>
    );
  }
};

export default PrivateRoutesWrapper;
