import { createSlice } from "@reduxjs/toolkit";
import { fetchQuote, saveQuote, searchQuotes } from "./quotesThunks";
import { fetchRecentHistory } from "./quotesThunks";

const initialState = {
  history: {
    isLoading: false,
    data: [],
    quotingData: [],
    applicationsData: [],
  },
  options: [],
  current: { isLoading: false, data: {}, isSaving: false },
};

export const quotes = createSlice({
  name: "quotes",
  initialState,
  reducers: {
    clearQuote: {
      reducer(state) {
        state.current = { isLoading: false, data: {}, isSaving: false };
      },
    },
    clearQuoteSearchOptions: {
      reducer(state) {
        state.options = [];
      },
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchQuote.pending, (state) => {
        state.current.isLoading = true;
      })
      .addCase(fetchQuote.fulfilled, (state, { payload }) => {
        state.current.isLoading = false;
        state.current.data = payload;
      })
      .addCase(fetchQuote.rejected, (state) => {
        state.current.isLoading = false;
        state.current.data = null;
      })
      .addCase(saveQuote.pending, (state) => {
        state.current.isSaving = true;
      })
      .addCase(saveQuote.fulfilled, (state, { payload }) => {
        state.current.isSaving = false;
        state.current.data = payload;
      })
      .addCase(saveQuote.rejected, (state) => {
        state.current.isSaving = false;
      })
      .addCase(searchQuotes.fulfilled, (state, { payload }) => {
        state.options = payload;
      })
      .addCase(searchQuotes.rejected, (state) => {
        state.options = [];
      })
      .addCase(fetchRecentHistory.pending, (state) => {
        state.history.isLoading = true;
      })
      .addCase(fetchRecentHistory.fulfilled, (state, { payload }) => {
        state.history.isLoading = false;
        state.history.data = payload;
        state.history.quotingData = payload.filter(
          (item) => item.category === "quoting"
        );
        state.history.applicationsData = payload.filter(
          (item) => item.category === "applications"
        );
      })
      .addCase(fetchRecentHistory.rejected, (state) => {
        state.history.isLoading = false;
      });
  },
});

export const { clearQuote, clearQuoteSearchOptions } = quotes.actions;

export default quotes.reducer;
