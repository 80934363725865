import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Layout, Menu } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { usePathname } from "../../../shared/usePathname";
import { privatePagesMap } from "../router/privatePagesMap";
import { useSelector } from "react-redux";

const getMenuItem = (key) => {
  return {
    key: key,
    icon: privatePagesMap[key].icon,
    label: privatePagesMap[key].label,
  };
};

const menuItems = (isAdmin) => [
  // {
  //   ...getMenuItem("/forms"),
  //   children: [getMenuItem("/private-aviation")],
  // },
  {
    ...getMenuItem("/applications"),
    children: [getMenuItem("/search"), getMenuItem("/quote")],
  },
  isAdmin && {
    ...getMenuItem("/users"),
  },
];

const SideMenu = () => {
  const { subpaths } = usePathname();
  const navigate = useNavigate();

  const [collapsed, setCollapsed] = useState(false);
  const [collapsedWidth, setCollapsedWidth] = useState(80);
  const [broken, setBroken] = useState(false);

  const openKeys = [subpaths[0]];
  const selectedKeys = [subpaths[Math.min(1, subpaths.length - 1)]];
  const isAdmin = useSelector((state) => state.user.admin);

  return (
    <Layout.Sider
      collapsible
      className="main-side-menu"
      collapsedWidth={collapsedWidth}
      collapsed={collapsed}
      breakpoint="md"
      onBreakpoint={(b) => {
        setBroken(b);
        setCollapsed(b);
        setCollapsedWidth(b ? 0 : 80);
      }}
      zeroWidthTriggerStyle={{
        backgroundColor: "#ffffff",
        background: "#ffffff",
        border: "1px solid #d5dde6",
        top: "auto",
        bottom: "184px",
      }}
      onCollapse={(_) => {
        if ((!broken && collapsed) || (broken && collapsedWidth === 0)) {
          if (broken) {
            setCollapsedWidth(80);
          } else {
            setCollapsed(!collapsed);
          }
        } else {
          if (broken) {
            setCollapsedWidth(0);
          } else {
            setCollapsed(!collapsed);
          }
        }
      }}
      trigger={
        (!broken && collapsed) || (broken && collapsedWidth === 0) ? (
          <RightOutlined className="main-sider-menu-trigger" />
        ) : (
          <LeftOutlined className="main-sider-menu-trigger" />
        )
      }
    >
      <Menu
        className="sider-menu-component"
        onClick={({ key, keyPath }) => {
          navigate(keyPath.length < 2 ? key : `${keyPath[1]}${keyPath[0]}`);
        }}
        defaultSelectedKeys={selectedKeys}
        defaultOpenKeys={openKeys}
        selectedKeys={selectedKeys}
        // openKeys={openKeys}
        mode="inline"
        items={menuItems(isAdmin)}
      />
    </Layout.Sider>
  );
};

export default SideMenu;
