import { combineReducers } from "@reduxjs/toolkit";
import applicationsSearchSlice from "./applicationsSearch/applicationsSearchSlice";
import aviationFormSlice from "./aviationForm/aviationFormSlice";
import { baseApi } from "./baseApi";
import downloadsSlice from "./downloads/downloadsSlice";
import notificationsSlice from "./notifications/notificationsSlice";
import userSlice from "./user/userSlice";
import quotesSlice from "./quotes/quotesSlice";

function createRootReducer() {
  const appReducer = combineReducers({
    quotes: quotesSlice,
    user: userSlice,
    aviationForm: aviationFormSlice,
    notifications: notificationsSlice,
    downloads: downloadsSlice,
    applicationsSearch: applicationsSearchSlice,
    [baseApi.reducerPath]: baseApi.reducer,
  });

  const rootReducer = (state, action) => {
    if (action.type === "ERASE_REDUX_STORE") {
      return appReducer(undefined, action);
    }

    return appReducer(state, action);
  };

  return rootReducer;
}
export default createRootReducer;
