import {
  ControlOutlined,
  LogoutOutlined,
  SelectOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Grid } from "antd";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const getDropdownItems = (canOpenPanel, isSmall) => [
  ...(canOpenPanel
    ? [
        {
          key: "0",
          label: <Link to="/">Admin Panel</Link>,
          icon: <ControlOutlined />,
        },
      ]
    : []),
  ...(isSmall
    ? [
        {
          key: "2",
          label: (
            <Link to="/all-forms/private-aviation">
              Private Aviation Application
            </Link>
          ),
          icon: <SelectOutlined />,
        },
      ]
    : []),
  {
    key: "1",
    label: <Link to="/logout">Logout</Link>,
    icon: <LogoutOutlined />,
  },
];
const AuthenticatedHeaderDropdown = ({ canOpenPanel }) => {
  const screens = Grid.useBreakpoint();
  const isSmall = (screens.xs === true || screens.sm === true) && !screens.md;
  const isXs = screens.xs === true && !screens.sm;
  const name = useSelector((state) => state.user.name);
  return (
    <div>
      <Dropdown
        menu={{ items: getDropdownItems(canOpenPanel, isSmall) }}
        placement="bottom"
      >
        <Button type="primary">
          <UserOutlined />
          {!isXs && <>{name}</>}
        </Button>
      </Dropdown>
    </div>
  );
};

export default AuthenticatedHeaderDropdown;
