import { DatePicker, Form } from "antd";
import dayjs from "dayjs";
import React, { memo } from "react";

import { useController } from "react-hook-form";
import { checkFormFieldsEquality } from "./checkFormFieldsEquality";

export const CustomDatePicker = (props) => {
  const {
    field,
    fieldState: { error, isTouched, isDirty },
  } = useController({
    name: props.name,
    control: props.control,
  });

  return (
    <Content
      {...props}
      field={field}
      error={error}
      isTouched={isTouched}
      isDirty={isDirty}
    />
  );
};

const Content = memo((props) => {
  const {
    field,
    error,
    formItem,
    name,
    shouldHideErrors,
    showErrors,
    ...inputProps
  } = props;
  delete inputProps.isTouched;
  delete inputProps.isDirty;

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current > dayjs().endOf("day");
  };

  const format = props.format ?? "YYYY-MM-DD";

  return (
    <Form.Item
      name={name}
      validateStatus={error && (!shouldHideErrors || showErrors) ? "error" : ""}
      help={
        (!shouldHideErrors || showErrors) && error?.message != null
          ? error?.message
          : null
      }
      {...formItem}
    >
      <div>
        {props.children}
        <DatePicker
          disabledDate={disabledDate}
          style={{ width: "100%" }}
          {...field}
          {...inputProps}
          format={format}
          onChange={(v) => {
            field.onChange(v?.format(format) ?? null);
          }}
          value={
            typeof field.value === "string" ? dayjs(field.value) : field.value
          }
        />
      </div>
    </Form.Item>
  );
}, checkFormFieldsEquality);
