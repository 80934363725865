import * as yup from "yup";

export const applicationsSearchSchema = yup.object().shape({
  date_range: yup
    .array()
    .of(yup.string().required("Please select a month."))
    .required("Please select a date range."),
  search_type: yup.string().nullable(),
  search_criteria: yup.string().nullable(),
});
