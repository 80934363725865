import { Navigate, Outlet } from "react-router-dom";
import PrivateLayout from "../layouts/PrivateLayout";
import { useAuthStatus } from "../useAuthStatus";

const AdminRoutesWrapper = () => {
  const { isAdmin } = useAuthStatus();

  if (isAdmin === false) {
    return <Navigate to="/login" replace={true} />;
  } else {
    return (
      <PrivateLayout>
        <Outlet />
      </PrivateLayout>
    );
  }
};

export default AdminRoutesWrapper;
