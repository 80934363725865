import { DashOutlined, UserAddOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Modal,
  Popconfirm,
  Row,
  Skeleton,
  Table,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTextFilterProps } from "../../shared/table/filterFunctions";
import { compareStrings } from "../../shared/table/sorterFunctions";
import { clearUsersList } from "../../store/user/userSlice";
import {
  disableUser,
  enableUser,
  fetchUsersList,
  resendConfirmationInstructions,
  sendResetPasswordInstructions,
} from "../../store/user/userThunks";
import UserForm from "./UserForm";

const ModalState = { ADD: -1, CLOSED: -2 };

const UsersList = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.user.list.isLoading);
  const data = useSelector((state) => state.user.list.data);

  const [openModalIndex, setOpenModalIndex] = useState(ModalState.CLOSED);

  const showModal = (index) => {
    setOpenModalIndex(index);
  };

  const onClose = (reload) => {
    setOpenModalIndex(ModalState.CLOSED);
    if (reload === true) {
      dispatch(fetchUsersList());
    }
  };

  useEffect(() => {
    dispatch(fetchUsersList());
    return () => {
      dispatch(clearUsersList());
    };
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 200,
      sorter: (a, b) => compareStrings(a, b, "name"),
      ...getTextFilterProps("name", "Name"),
      render: (value, record) => (
        <Button
          size="small"
          type="link"
          loading={false}
          onClick={() => {
            showModal(record.id);
          }}
          style={{ margin: 0, padding: 0 }}
        >
          {value}
        </Button>
      ),
    },
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
      width: 200,
      sorter: (a, b) => compareStrings(a, b, "email"),
      ...getTextFilterProps("email", "Email"),
    },
    {
      title: "Type",
      key: "access",
      dataIndex: "access",
      sorter: (a, b) => compareStrings(a, b, "access"),
      ...getTextFilterProps("access", "Type"),
      width: 100,
    },
    {
      title: "Enabled",
      key: "disabled",
      dataIndex: "disabled",
      width: 125,
      render: (value, record) =>
        value ? (
          <Popconfirm
            title="Enable account"
            description={
              <div>
                This user's account is disabled. <br />
                Do you want to enable it?
              </div>
            }
            onConfirm={async () => {
              var result = await dispatch(enableUser({ id: record.id }));
              if (result?.meta?.requestStatus === "fulfilled") {
                dispatch(fetchUsersList());
              }
            }}
            onCancel={null}
            okText="Yes"
            cancelText="No"
          >
            <Button
              size="small"
              type="link"
              onClick={() => {}}
              style={{ margin: 0, padding: 0 }}
            >
              No, enable
            </Button>
          </Popconfirm>
        ) : (
          <Popconfirm
            title="Disable account"
            description={
              <div>
                This user's account is enabled. <br />
                Do you want to disable it?
              </div>
            }
            onConfirm={async () => {
              var result = await dispatch(disableUser({ id: record.id }));
              if (result?.meta?.requestStatus === "fulfilled") {
                dispatch(fetchUsersList());
              }
            }}
            onCancel={null}
            okText="Yes"
            cancelText="No"
          >
            <Button
              size="small"
              type="link"
              onClick={() => {}}
              style={{ margin: 0, padding: 0 }}
            >
              Yes, disable
            </Button>
          </Popconfirm>
        ),
    },
    {
      title: "Confirmed",
      key: "is_confirmed",
      dataIndex: "is_confirmed",
      width: 150,
      render: (is_confirmed, record) =>
        record.disabled ? (
          <DashOutlined />
        ) : is_confirmed ? (
          <Popconfirm
            title="Reset password"
            description={<div>Do you want to reset this user's password?</div>}
            onConfirm={() => {
              dispatch(sendResetPasswordInstructions({ id: record.id }));
            }}
            onCancel={null}
            okText="Yes"
            cancelText="No"
          >
            <Button
              size="small"
              type="link"
              loading={false}
              onClick={() => {}}
              style={{ margin: 0, padding: 0 }}
            >
              Yes, reset password
            </Button>
          </Popconfirm>
        ) : (
          <Popconfirm
            title="Send confirmation email"
            description={
              <div>
                This user's email is not confirmed. <br />
                Do you want to resend the confirmation email?
              </div>
            }
            onConfirm={() => {
              dispatch(resendConfirmationInstructions({ id: record.id }));
            }}
            onCancel={null}
            okText="Yes"
            cancelText="No"
          >
            <Button
              size="small"
              type="link"
              loading={false}
              onClick={() => {}}
              style={{ margin: 0, padding: 0 }}
            >
              No, resend email
            </Button>
          </Popconfirm>
        ),
    },
  ];

  return (
    <div>
      <Card className="body-card-content-no-min-height">
        <Skeleton active loading={isLoading && !data}>
          <Row justify="end" style={{ marginBottom: 12 }}>
            <Col>
              <Button
                type="primary"
                size="small"
                htmlType="submit"
                onClick={() => showModal(ModalState.ADD)}
              >
                <UserAddOutlined /> Add user
              </Button>
            </Col>
          </Row>
          <Row justify="end">
            <Col span={24}>
              <Table
                columns={columns}
                dataSource={data}
                size="small"
                scroll={{ x: 785, y: 500 }}
                bordered
                sticky
                pagination={{
                  defaultPageSize: 50,
                  pageSizeOptions: [10, 20, 50, 100],
                }}
                loading={isLoading}
              />
            </Col>
          </Row>
        </Skeleton>
      </Card>
      <Modal
        title={openModalIndex === ModalState.ADD ? "Add User" : "Edit User"}
        open={openModalIndex != ModalState.CLOSED}
        onOk={onClose}
        onCancel={onClose}
        okText="Done"
        cancelText=""
        maskClosable={false}
        mask={true}
        destroyOnClose={true}
        cancelButtonProps={{ style: { display: "none" } }}
        footer={null}
      >
        <UserForm
          isNew={openModalIndex === ModalState.ADD}
          id={openModalIndex}
          onClose={onClose}
        />
      </Modal>
    </div>
  );
};

export default UsersList;
