import { createSlice } from "@reduxjs/toolkit";
import {
  createUser,
  fetchUserDetails,
  fetchUsersList,
  loginUser,
  logoutUser,
  resetPassword,
  updateUser,
  verifyResetPasswordToken,
} from "./userThunks";

export const LoginStatus = {
  UNKNOWN: "unknown",
  SUCCESS: "success",
  ERROR: "error",
  PENDING: "pending",
};

export const ResetPasswordStatus = {
  UNKNOWN: "unknown",
  SUCCESS: "success",
  ERROR: "error",
};

const initialState = {
  isAuthenticated: false,
  status: LoginStatus.UNKNOWN,
  message: null,
  name: "",
  admin: false,
  resetPassword: {
    tokenStatus: ResetPasswordStatus.UNKNOWN,
    isSuccess: false,
    isLoading: false,
  },
  list: {
    isLoading: null,
    data: null,
  },
  edit: {
    isLoading: false,
    isSaving: false,
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetUser: {
      reducer(state) {
        state.status = LoginStatus.UNKNOWN;
        state.message = null;
        state.name = "";
        state.admin = false;
        state.isAuthenticated = false;
      },
    },
    setIsAuthenticated: {
      reducer(state, action) {
        state.isAuthenticated = action.payload;
      },
    },
    clearResetPasswordPage: {
      reducer(state) {
        state.resetPassword.tokenStatus = ResetPasswordStatus.UNKNOWN;
        state.resetPassword.isSuccess = false;
        state.resetPassword.isLoading = false;
      },
    },
    clearUsersList: {
      reducer(state) {
        state.list.isLoading = null;
        state.list.data = null;
      },
    },
    clearUserEdit: {
      reducer(state) {
        state.edit.isSaving = false;
        state.edit.isLoading = false;
      },
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(verifyResetPasswordToken.fulfilled, (state) => {
        state.resetPassword.tokenStatus = ResetPasswordStatus.SUCCESS;
      })
      .addCase(verifyResetPasswordToken.rejected, (state) => {
        state.resetPassword.tokenStatus = ResetPasswordStatus.ERROR;
      })
      .addCase(resetPassword.pending, (state) => {
        state.resetPassword.isLoading = true;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.resetPassword.isSuccess = true;
        state.resetPassword.isLoading = false;
      })
      .addCase(resetPassword.rejected, (state) => {
        state.resetPassword.isLoading = false;
      })
      .addCase(loginUser.pending, (state) => {
        state.status = LoginStatus.PENDING;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = LoginStatus.SUCCESS;
        state.name = action.payload.name;
        state.message = null;
        state.admin = action.payload.admin;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = LoginStatus.ERROR;
        state.message = action.payload;
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.status = LoginStatus.UNKNOWN;
        state.message = null;
        state.name = "";
        state.isAuthenticated = false;
        state.admin = false;
      })
      .addCase(logoutUser.rejected, (state) => {
        state.status = LoginStatus.UNKNOWN;
        state.message = null;
        state.name = "";
        state.isAuthenticated = false;
        state.admin = false;
      })
      .addCase(logoutUser.pending, (state) => {
        state.status = LoginStatus.PENDING;
        state.isAuthenticated = false;
      })
      .addCase(fetchUserDetails.pending, (state) => {
        state.edit.isLoading = true;
      })
      .addCase(fetchUserDetails.fulfilled, (state) => {
        state.edit.isLoading = false;
      })
      .addCase(fetchUserDetails.rejected, (state) => {
        state.edit.isLoading = false;
      })
      .addCase(updateUser.pending, (state) => {
        state.edit.isSaving = true;
      })
      .addCase(updateUser.fulfilled, (state) => {
        state.edit.isSaving = false;
      })
      .addCase(updateUser.rejected, (state) => {
        state.edit.isSaving = false;
      })
      .addCase(createUser.pending, (state) => {
        state.edit.isSaving = true;
      })
      .addCase(createUser.fulfilled, (state) => {
        state.edit.isSaving = false;
      })
      .addCase(createUser.rejected, (state) => {
        state.edit.isSaving = false;
      })
      .addCase(fetchUsersList.pending, (state) => {
        state.list.isLoading = true;
      })
      .addCase(fetchUsersList.fulfilled, (state, { payload }) => {
        state.list.isLoading = false;
        state.list.data = payload;
      })
      .addCase(fetchUsersList.rejected, (state) => {
        state.list.isLoading = false;
        state.list.data = [];
      });
  },
});

export const {
  resetUser,
  setIsAuthenticated,
  clearResetPasswordPage,
  clearUsersList,
  clearUserEdit,
} = userSlice.actions;

export default userSlice.reducer;
