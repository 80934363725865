import { Alert } from "antd";
import { isArray } from "lodash";
import React, { memo } from "react";
import { getFormErrorsList } from "./getFormErrorsList";

export const FormErrorsAlert = memo(
  ({ isVisible, errors, onParseErrorsList }) => {
    const showErrors = getFormErrorsList(errors);
    return isVisible === true && Object.keys(errors ?? {}).length > 0 ? (
      <Alert
        closeIcon
        message={"Please solve all the following errors"}
        description={
          onParseErrorsList != null ? (
            onParseErrorsList(showErrors)
          ) : (
            <>
              <p></p>
              <ul>
                {showErrors.map((item, index) => {
                  var message = isArray(item) ? item[0] : item;
                  return <li key={index}>{message}</li>;
                })}
              </ul>
            </>
          )
        }
        type="error"
        showIcon
        style={{ marginBottom: 16 }}
      />
    ) : (
      <></>
    );
  },
  (prev, next) => {
    if (
      prev.isVisible !== next.isVisible ||
      Object.keys(prev.errors ?? {}).length !==
        Object.keys(next.errors ?? {}).length
    ) {
      return false;
    }
    for (const key in prev.errors) {
      if (prev.errors[key]?.message !== next.errors[key]?.message) {
        return false;
      }
    }
    return true;
  }
);
