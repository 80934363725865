import { Button, Col, Divider, Form, Row } from "antd";
import { useFormContext, useWatch } from "react-hook-form";
import { CustomInput } from "../../../shared/formFields/CustomInput";
import { CustomInputCurrency } from "../../../shared/formFields/CustomInputCurrency";
import { isNumeric } from "../../../shared/InputCurrency";

const OptionForm = ({ index }) => {
  const { control, setValue } = useFormContext();
  const option = useWatch({
    control,
    name: `options[${index}]`,
  });

  return (
    <Form
      name="quote_option_form"
      autoComplete="off"
      layout="vertical"
      labelWrap
      size="small"
      preserve={false}
    >
      <Divider style={{ marginTop: 8, marginBottom: 8 }} dashed />
      <Row>
        <CustomInputCurrency
          formItem={{
            hasFeedback: true,
            label: "Agreed Hull Value",
            validateStatus: option.agreed_hull_value ? "success" : "",
            className: "reduced-label-padding",
          }}
          name={`options[${index}].agreed_hull_value`}
          control={control}
          placeholder="Amount"
        />
      </Row>
      <Row style={{ marginBottom: 1 }}>
        <Col span={24}>3rd Party Liability Coverage</Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col>
          <Button
            type={
              option.third_party_liability_coverage_amount == null &&
              option.third_party_liability_coverage_option === "Not Included"
                ? "primary"
                : "default"
            }
            onClick={() => {
              setValue(
                `options[${index}].third_party_liability_coverage_option`,
                "Not Included"
              );
              setValue(
                `options[${index}].third_party_liability_coverage_amount`,
                null
              );
            }}
          >
            Not Included
          </Button>
        </Col>
        <Col>or</Col>
        <Col>
          <CustomInputCurrency
            formItem={{
              hasFeedback: true,
              validateStatus: option.third_party_liability_coverage_amount
                ? "success"
                : "",
              required: true,
              className: "reduced-label-padding",
              style: { borderColor: "red" },
            }}
            control={control}
            name={`options[${index}].third_party_liability_coverage_amount`}
            placeholder="Amount"
            onBlur={() => {
              if (
                option.third_party_liability_coverage_amount != null &&
                option.third_party_liability_coverage_option
              ) {
                setValue(
                  `options[${index}].third_party_liability_coverage_option`,
                  null
                );
              }
            }}
          />
        </Col>
      </Row>
      <Row style={{ marginBottom: 8 }}>
        <Col span={24}>Passenger Liability Coverage</Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col>
          <Button
            type={
              option.passenger_liability_coverage_amount == null &&
              option.passenger_liability_coverage_option === "Not Included"
                ? "primary"
                : "default"
            }
            onClick={() => {
              setValue(
                `options[${index}].passenger_liability_coverage_option`,
                "Not Included"
              );
              setValue(
                `options[${index}].passenger_liability_coverage_amount`,
                null
              );
            }}
          >
            Not Included
          </Button>
        </Col>
        <Col>or</Col>
        <Col>
          <Button
            type={
              option.passenger_liability_coverage_amount == null &&
              option.passenger_liability_coverage_option === "Incl. In Above"
                ? "primary"
                : "default"
            }
            onClick={() => {
              setValue(
                `options[${index}].passenger_liability_coverage_option`,
                "Incl. In Above"
              );
              setValue(
                `options[${index}].passenger_liability_coverage_amount`,
                null
              );
            }}
          >
            Incl. In Above
          </Button>
        </Col>

        <Col>or</Col>
        <Col>
          <CustomInputCurrency
            formItem={{
              hasFeedback: true,
              validateStatus: option.passenger_liability_coverage_amount
                ? "success"
                : "",
              required: true,
              className: "reduced-label-padding",
              style: { borderColor: "red" },
            }}
            control={control}
            name={`options[${index}].passenger_liability_coverage_amount`}
            placeholder="Amount"
            onBlur={() => {
              if (
                option.passenger_liability_coverage_amount != null &&
                option.passenger_liability_coverage_option
              ) {
                setValue(
                  `options[${index}].passenger_liability_coverage_option`,
                  null
                );
              }
            }}
          />
        </Col>
      </Row>
      <Row style={{ marginBottom: 8 }}>
        <Col span={24}>Premises Liability</Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col>
          <Button
            type={
              option.premises_liability_amount == null &&
              option.premises_liability_option === "Not Included"
                ? "primary"
                : "default"
            }
            onClick={() => {
              setValue(
                `options[${index}].premises_liability_option`,
                "Not Included"
              );
              setValue(`options[${index}].premises_liability_amount`, null);
            }}
          >
            Not Included
          </Button>
        </Col>
        <Col>or</Col>
        <Col>
          <Button
            type={
              option.premises_liability_amount == null &&
              option.premises_liability_option === "Incl. In Above"
                ? "primary"
                : "default"
            }
            onClick={() => {
              setValue(
                `options[${index}].premises_liability_option`,
                "Incl. In Above"
              );
              setValue(`options[${index}].premises_liability_amount`, null);
            }}
          >
            Incl. In Above
          </Button>
        </Col>

        <Col>or</Col>
        <Col>
          <CustomInputCurrency
            formItem={{
              hasFeedback: true,
              validateStatus: option.premises_liability_amount ? "success" : "",
              required: true,
              className: "reduced-label-padding",
            }}
            control={control}
            name={`options[${index}].premises_liability_amount`}
            placeholder="Amount"
            onBlur={() => {
              if (
                option.premises_liability_amount != null &&
                option.premises_liability_option
              ) {
                setValue(`options[${index}].premises_liability_option`, null);
              }
            }}
          />
        </Col>
      </Row>
      <Divider style={{ marginTop: 12, marginBottom: 8 }} dashed />
      <CustomInput
        formItem={{
          hasFeedback: true,
          validateStatus: option.deductible ? "success" : "",
          required: false,
          label: "Deductible",
          className: "reduced-label-padding",
        }}
        prefix={
          isNumeric(option.deductible) ||
          option.deductible == "" ||
          option.deductible == null
            ? "CA$"
            : ""
        }
        control={control}
        name={`options[${index}].deductible`}
        placeholder="Type here"
        style={{ width: 170 }}
      />
      <CustomInputCurrency
        formItem={{
          hasFeedback: true,
          validateStatus: option.premium ? "success" : "",
          required: false,
          label: "Premium",
          className: "reduced-label-padding",
        }}
        control={control}
        name={`options[${index}].premium`}
        placeholder="Amount"
        style={{ width: 170 }}
      />
      <CustomInputCurrency
        formItem={{
          hasFeedback: true,
          validateStatus: option.annual_account_fee ? "success" : "",
          required: false,
          label: "Annual Account Fee",
          className: "reduced-label-padding",
        }}
        control={control}
        name={`options[${index}].annual_account_fee`}
        placeholder="Amount"
        style={{ width: 170 }}
      />
    </Form>
  );
};

export default OptionForm;
