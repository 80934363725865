import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isDownloading: false,
};

export const downloads = createSlice({
  name: "downloads",
  initialState,
  reducers: {
    setIsDownloading: {
      reducer(state, action) {
        state.isDownloading = action.payload;
      },
    },
  },
});

export const { setIsDownloading } = downloads.actions;

export default downloads.reducer;
