export const canadian_provinces = [
  {
    value: "BC",
  },
  {
    value: "ON",
  },
  {
    value: "AB",
  },
  {
    value: "MB",
  },
  {
    value: "NB",
  },
  {
    value: "QC",
  },
  {
    value: "NS",
  },
  {
    value: "PE",
  },
  {
    value: "SK",
  },
  {
    value: "NL",
  },
  {
    value: "YT",
  },
  {
    value: "NT",
  },
  {
    value: "NU",
  },
];

export const referral_options = [
  {
    value: "Friend/Relatives",
  },
  {
    value: "Instagram",
  },
  {
    value: "Others",
  },
];

export const hull_coverage_requested = [
  {
    value: "None",
  },
  {
    value: "All risk flight & ground",
  },
  {
    value: "All risk ground only",
  },
  {
    value: "Liability only",
  },
];

export const aircraft_storage = [
  {
    value: "Hangared",
  },
  {
    value: "Tied",
  },
  {
    value: "Not Tied Down",
  },
  {
    value: "Moored",
  },
];

export const trip_duration_options = [
  {
    value: "Months",
  },
  {
    value: "Days",
  },
];

export const HANGARED_AIRCRAFT_STORAGE = "Hangared";
export const MOORED_AIRCRAFT_STORAGE = "Moored";

export const APPLICATIONS_SEARCH_OPTIONS = [
  { label: "Insured Name", value: "INSURED_NAME" },
  { label: "Aircraft", value: "AIRCRAFT" },
];

export const TPL_OPTIONS = [
  { label: "CA$1,000,000", value: 1000000 },
  { label: "CA$2,000,000", value: 2000000 },
  { label: "CA$3,000,000", value: 3000000 },
  { label: "CA$5,000,000", value: 5000000 },
];

export const PASSENGER_OPTIONS = [
  { label: "CA$100,000", value: 100000 },
  { label: "CA$200,000", value: 200000 },
  { label: "CA$300,000", value: 300000 },
  { label: "CA$500,000", value: 500000 },
];

export const PREMISES_OPTIONS = [
  { label: "CA$1,000,000", value: 1000000 },
  { label: "CA$2,000,000", value: 2000000 },
  { label: "CA$3,000,000", value: 3000000 },
  { label: "CA$5,000,000", value: 5000000 },
  { label: "None", value: 0 },
];
