import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { HttpStatusCode } from "axios";
import { notifyApiCallFailed } from "../notifications/notificationsSlice";

export const searchQuotes = createAsyncThunk(
  "quotes/search",
  async ({ term }, api) => {
    const baseUrl =
      process.env.REACT_APP_USE_LOCAL_API_URL === "true"
        ? process.env.REACT_APP_LOCAL_API_URL
        : process.env.REACT_APP_EXTERNAL_API_URL;
    try {
      const response = await axios.get(
        baseUrl + "/private_aviation/forms/autocomplete",
        {
          params: {
            term: term,
          },
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      if (response.status === HttpStatusCode.Ok) {
        return response.data;
      }
      throw new Error();
    } catch (error) {
      api.dispatch(notifyApiCallFailed());
      return api.rejectWithValue(null);
    }
  }
);

export const fetchRecentHistory = createAsyncThunk(
  "quotes/history",
  async (_, api) => {
    const baseUrl =
      process.env.REACT_APP_USE_LOCAL_API_URL === "true"
        ? process.env.REACT_APP_LOCAL_API_URL
        : process.env.REACT_APP_EXTERNAL_API_URL;
    try {
      let config = {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      };
      const response = await axios.get(
        baseUrl + "/private_aviation/activities",
        config
      );
      if (response.status === HttpStatusCode.Ok) {
        return response.data;
      }
      throw new Error();
    } catch (error) {
      api.dispatch(notifyApiCallFailed());
      return api.rejectWithValue(null);
    }
  }
);

export const fetchQuote = createAsyncThunk(
  "quotes/fetch",
  async ({ id }, api) => {
    const baseUrl =
      process.env.REACT_APP_USE_LOCAL_API_URL === "true"
        ? process.env.REACT_APP_LOCAL_API_URL
        : process.env.REACT_APP_EXTERNAL_API_URL;
    try {
      let config = {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      };
      const response = await axios.post(
        `${baseUrl}/private_aviation/forms/${id}/create_or_retrieve_quote`,
        null,
        config
      );
      if (response.status === HttpStatusCode.Ok) {
        return response.data;
      }
      throw new Error();
    } catch (error) {
      api.dispatch(notifyApiCallFailed());
      return api.rejectWithValue(null);
    }
  }
);

export const saveQuote = createAsyncThunk(
  "quotes/save",
  async ({ id, data }, api) => {
    const baseUrl =
      process.env.REACT_APP_USE_LOCAL_API_URL === "true"
        ? process.env.REACT_APP_LOCAL_API_URL
        : process.env.REACT_APP_EXTERNAL_API_URL;
    try {
      let config = {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      };
      const response = await axios.patch(
        `${baseUrl}/private_aviation/quotes/${id}`,
        data,
        config
      );

      if (response.status === HttpStatusCode.Ok) {
        return response.data;
      }
      throw new Error();
    } catch (error) {
      api.dispatch(notifyApiCallFailed());
      return api.rejectWithValue(null);
    }
  }
);
