import { Button, Card, Col, Progress, Radio, Row, Typography } from "antd";
import { CustomRadioGroup } from "../../../../../../shared/formFields/CustomRadioGroup";
import { useFormContext } from "react-hook-form";

export const PrefaceStep1 = ({ navigationButtons }) => {
  const { control } = useFormContext();
  return (
    <Card>
      <Row justify="space-between">
        <Col>
          <Typography.Title
            level={4}
            style={{ marginTop: 0, marginBottom: 12 }}
          >
            Initial Information
          </Typography.Title>
        </Col>
        <Col>
          <Progress steps={4} percent={25} showInfo={false} />
        </Col>
      </Row>

      <Typography.Text type="secondary">
        To begin your private aviation application, we need to gather some basic
        information.
      </Typography.Text>
      <Row style={{ marginTop: 24 }}>
        <Col span={24}>
          <Row>
            <Col span={24}>
              <CustomRadioGroup
                formItem={{
                  required: true,
                  label: "Are you currently insured with Air1?",
                }}
                buttonStyle="solid"
                control={control}
                name="currently_insured"
                removeMargin={true}
              >
                <Radio.Button value={true}>Yes</Radio.Button>
                <Radio.Button value={false}>No</Radio.Button>
              </CustomRadioGroup>
            </Col>
          </Row>
          {navigationButtons}
        </Col>
      </Row>
    </Card>
  );
};
