import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  description: "",
  message: "",
  type: "",
  visible: false,
};

export const notifications = createSlice({
  name: "aviationForm",
  initialState,
  reducers: {
    clearNotifications: {
      reducer(state) {
        state.type = "";
        state.message = "";
        state.description = "";
        state.visible = false;
      },
    },
    notifyApiCallFailed: {
      reducer(state) {
        state.type = "error";
        state.message = "Error";
        state.description =
          "Something went wrong. Please try again or contact our support team.";
        state.visible = true;
      },
    },
    showNotification: {
      reducer(state, action) {
        const { type, message, description } = action.payload;
        state.type = type;
        state.message = message;
        state.description = description;
        state.visible = true;
      },
      prepare({ type, message, description }) {
        return {
          payload: { type, message, description },
        };
      },
    },
  },
});

export const { clearNotifications, notifyApiCallFailed, showNotification } =
  notifications.actions;

export default notifications.reducer;
