import { Alert, Flex, Spin } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { verifyConfirmationToken } from "../../store/user/userThunks";

const ConfirmUser = () => {
  //
  const dispatch = useDispatch();
  const [isConfirmed, setIsConfirmed] = useState(null);

  //
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const confirmationToken = queryParams.get("confirmation_token");

  //
  useEffect(() => {
    validateConfirmationToken();
  }, []);

  const validateConfirmationToken = async () => {
    var result = await dispatch(
      verifyConfirmationToken({ confirmation_token: confirmationToken })
    );
    if (result?.meta?.requestStatus !== "fulfilled") {
      setIsConfirmed(false);
    } else {
      setIsConfirmed(true);
    }
  };

  return isConfirmed === null ? (
    <div style={{ height: "100%" }}>
      <Flex
        justify="center"
        align="center"
        className="expanded-height"
        vertical
      >
        <Spin spinning={true} />
      </Flex>{" "}
    </div>
  ) : isConfirmed === true ? (
    <Alert
      message={
        <div>
          Your account has been confirmed. Please check your inbox for
          instructions on setting your password.
        </div>
      }
      type="success"
      showIcon
      className="default-margin"
    />
  ) : (
    <Alert
      message={`Error: This link is expired or invalid!`}
      type="error"
      showIcon
      className="default-margin"
    />
  );
};

export default ConfirmUser;
