import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Col, Flex, Form, Row } from "antd";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setShowPreface } from "../../../../../../store/aviationForm/aviationFormSlice";
import { PrefaceStep1 } from "./PrefaceStep1";
import { PrefaceStep2 } from "./PrefaceStep2";
import { PrefaceStep3 } from "./PrefaceStep3";
import {
  step1Schema,
  step2Schema,
  step3Schema,
  step4Schema,
} from "./prefaceSchemas";
import { PrefaceStep4 } from "./PrefaceStep4";

export const PrefaceWrapper = ({ showPreface }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.aviationForm.data);
  const {
    control,
    handleSubmit,
    reset,
    watch,
    trigger,
    formState: { isValid, isSubmitted, isSubmitSuccessful },
  } = useForm({
    mode: "onChange",
    defaultValues: data,
    values: data,
    resetOptions: {
      keepIsSubmitted: false,
    },
    resolver: yupResolver(
      showPreface === 1
        ? step1Schema
        : showPreface === 2
        ? step2Schema
        : showPreface === 3
        ? step3Schema
        : step4Schema
    ),
  });

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({ ...data });
    }
  }, [isSubmitSuccessful]);

  const onFinish = (values) => {
    var data = values;
    if (showPreface === 1) {
      data = { currently_insured: values.currently_insured };
    } else if (showPreface === 2) {
      data = {
        new_or_renew: values.new_or_renew,
        has_other_insurance: values.has_other_insurance,
      };
    } else if (showPreface === 3) {
      data = {
        other_insurance_expiry_date: values.other_insurance_expiry_date,
        other_insurance_carrier: values.other_insurance_carrier,
        other_insurance_broker: values.other_insurance_broker,
        coverage_date: values.coverage_date,
      };
    } else if (showPreface === 4) {
      data = {
        referral_reason: values.referral_reason,
        hangar_option_checkbox: values.hangar_option_checkbox,
        hangar_option_date: values.hangar_option_date,
        marine_option_checkbox: values.marine_option_checkbox,
        marine_option_date: values.marine_option_date,
        home_option_checkbox: values.home_option_checkbox,
        home_option_date: values.home_option_date,
        business_option_checkbox: values.business_option_checkbox,
        business_option_date: values.business_option_date,
      };
    }

    dispatch(
      setShowPreface({
        showPreface: showPreface + 1 > 4 ? null : showPreface + 1,
        data: data,
      })
    );
  };

  const navigationButtons = (
    <Row justify="end" gutter={[8, 8]}>
      {showPreface > 1 && (
        <Col>
          <Button
            onClick={() => {
              dispatch(
                setShowPreface({
                  showPreface: showPreface - 1,
                  data: data,
                })
              );
            }}
          >
            Previous
          </Button>
        </Col>
      )}
      <Col>
        <Button
          htmlType="submit"
          type="primary"
          disabled={isSubmitted && !isValid}
        >
          Next
        </Button>
      </Col>
    </Row>
  );

  return (
    <div style={{ height: "100%" }} className="centered-body-background">
      <Flex
        justify="center"
        align="center"
        className="expanded-height"
        vertical
      >
        <div
          style={{
            // minWidth: 450,
            marginTop: 52,
            maxWidth: 450,
            marginLeft: 18,
            marginRight: 18,
          }}
        >
          <FormProvider
            control={control}
            watch={watch}
            trigger={trigger}
            isSubmitted={isSubmitted}
          >
            <Form
              name="private_aviation_user_preface"
              autoComplete="off"
              layout="vertical"
              labelWrap
              onFinish={() => {
                handleSubmit(onFinish, () => {})();
              }}
              size="medium"
            >
              {data.showPreface === 1 && (
                <PrefaceStep1 navigationButtons={navigationButtons} />
              )}
              {data.showPreface === 2 && (
                <PrefaceStep2 navigationButtons={navigationButtons} />
              )}
              {data.showPreface === 3 && (
                <PrefaceStep3 navigationButtons={navigationButtons} />
              )}
              {data.showPreface === 4 && (
                <PrefaceStep4 navigationButtons={navigationButtons} />
              )}
            </Form>
          </FormProvider>
        </div>
      </Flex>
    </div>
  );
};
