import {
  ArrowLeftOutlined,
  FileTextOutlined,
  PrinterOutlined,
  ReloadOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  Alert,
  Button,
  Card,
  Col,
  Divider,
  Input,
  InputNumber,
  Popconfirm,
  Row,
  Select,
  Skeleton,
  Spin,
  Typography,
} from "antd";
import dayjs from "dayjs";
import { useEffect, useRef } from "react";
import { FormProvider, useController, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { downloadFile } from "../../../shared/downloadFile";
import { clearQuote } from "../../../store/quotes/quotesSlice";
import { fetchQuote, saveQuote } from "../../../store/quotes/quotesThunks";
import QuoteOptions from "./QuoteOptions";
import { CustomInput } from "../../../shared/formFields/CustomInput";

const ApplicationsQuote = () => {
  //
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { applicationId } = useParams();

  //
  const {
    control,
    reset,
    watch,
    trigger,
    getValues,
    setValue,
    setFocus,
    formState: { isDirty },
  } = useForm({
    mode: "onChange",
    defaultValues: {},
  });

  //
  const { field: select } = useController({ name: "status", control });
  const { field: input } = useController({
    name: "internal_comments",
    control,
  });
  const { field: customerCodeInput } = useController({
    name: "customer_code",
    control,
  });

  const { field: namedInsuredInput } = useController({
    name: "named_insured",
    control,
  });

  const { field: aircraftInput } = useController({
    name: "aircraft",
    control,
  });

  const { field: approvedPilotsInput } = useController({
    name: "approved_pilots",
    control,
  });

  const { field: registrationInput } = useController({
    name: "registration",
    control,
  });

  const { field: passengerSeatsInput } = useController({
    name: "passenger_seats",
    control,
  });

  //
  const data = useSelector((state) => state.quotes.current.data);
  const isLoading = useSelector((state) => state.quotes.current.isLoading);
  const isSaving = useSelector((state) => state.quotes.current.isSaving);
  const isDownloading = useSelector((state) => state.downloads.isDownloading);
  const isGenerateQuoteClicked = useRef(false);

  //
  useEffect(() => {
    dispatch(fetchQuote({ id: applicationId }));
    return () => {
      dispatch(clearQuote());
    };
  }, []);

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data]);

  const saveData = async () => {
    var data = getValues();
    var result = await dispatch(saveQuote({ id: data.id, data: data }));
    return result?.meta?.requestStatus === "fulfilled" ?? false;
  };

  return data == null ? (
    <Alert
      message={`Error: Something went wrong while loading this quote!`}
      type="error"
      showIcon
      className="default-margin"
    />
  ) : (
    <Card className="body-card-content-no-min-height">
      <Skeleton active loading={isLoading}>
        <Spin spinning={isSaving}>
          <Row justify={"start"} gutter={[8, 8]}>
            {location.key !== "default" && (
              <Col>
                {isDirty ? (
                  <Popconfirm
                    title={"Unsaved changes"}
                    description={
                      "You have unsaved changes. Are you sure you want to go back?"
                    }
                    onConfirm={() => navigate(-1)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button size="small">
                      <ArrowLeftOutlined /> Go Back
                    </Button>
                  </Popconfirm>
                ) : (
                  <Button size="small" onClick={() => navigate(-1)}>
                    <ArrowLeftOutlined /> Go Back
                  </Button>
                )}
              </Col>
            )}
            <Button
              size="small"
              disabled={!isDirty}
              onClick={() => {
                reset(data);
              }}
            >
              <ReloadOutlined /> Restore
            </Button>
            <Col>
              <Button
                size="small"
                disabled={!isDirty || isDownloading}
                onClick={saveData}
              >
                <SaveOutlined /> Save
              </Button>
            </Col>

            <Col>
              <Button
                type="primary"
                size="small"
                disabled={isDownloading}
                loading={isDownloading && isGenerateQuoteClicked.current}
                onClick={async () => {
                  // Save data if there are changes
                  if (isDirty) {
                    const isSuccess = await saveData();
                    if (!isSuccess) return;
                  }

                  // Generate quote .pdf
                  const quoteId = getValues().id;
                  isGenerateQuoteClicked.current = true;
                  dispatch(
                    downloadFile({
                      url: `/private_aviation/quotes/${quoteId}/serve_pdf`,
                      defaultName: "Quote",
                    })
                  );
                }}
              >
                <FileTextOutlined /> Generate Quote
              </Button>
            </Col>
          </Row>
          <Divider dashed />
          <Row style={{ marginBottom: "24px" }}>
            <Col span={24}>
              <Row justify={"space-between"}>
                <Col flex="1 0 400px">
                  <Row gutter={[0, 16]} style={{ marginBottom: 16 }}>
                    {/* <Col span={24}>
                      <span className="description-title-span">Aircraft</span>
                      {data.aircraft ?? "-"}
                    </Col>
                    <Col span={24}>
                      <span className="description-title-span">Owner</span>
                      {data.owner ?? "-"} {data.email ? `(${data.email})` : ""}
                    </Col> */}
                    <Col span={24}>
                      <span className="description-title-span">
                        Application #
                      </span>
                      <Button
                        type="link"
                        style={{ padding: 0, margin: 0 }}
                        disabled={isDownloading}
                        loading={
                          isDownloading && !isGenerateQuoteClicked.current
                        }
                        onClick={() => {
                          isGenerateQuoteClicked.current = false;
                          dispatch(
                            downloadFile({
                              defaultName: "PrivateAviationForm",
                              url: `/private_aviation/forms/${applicationId}/serve_pdf`,
                            })
                          );
                        }}
                      >
                        {data.application_number ?? "-"} <PrinterOutlined />
                      </Button>
                    </Col>
                    <Col span={24}>
                      <span className="description-title-span">
                        Received On
                      </span>
                      {data.submitted_at
                        ? dayjs(data.submitted_at).format("MMMM D, YYYY h:mm A")
                        : "-"}
                    </Col>
                    <Col span={24}>
                      <span className="description-title-span">
                        Last Quoted By
                      </span>
                      {data.last_quoted_by ?? "-"}
                    </Col>
                    <Col span={24}>
                      <span className="description-title-span">
                        Last Quoted On
                      </span>
                      {data.last_quoted_on
                        ? dayjs(data.last_quoted_on).format(
                            "MMMM D, YYYY h:mm A"
                          )
                        : "-"}
                    </Col>
                  </Row>
                </Col>
                <Col flex="200px" style={{ marginBottom: 16 }}>
                  <Row justify={"start"}>
                    <Col span={24}>
                      <Typography.Text strong>Status: </Typography.Text>
                    </Col>
                    <Col span={24}>
                      <Select
                        {...select}
                        placeholder="Status"
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        style={{ width: "200px", marginBottom: 16 }}
                        options={[
                          { value: "Complete" },
                          { value: "In Progress" },
                          { value: "Pending" },
                          { value: "Sent" },
                        ]}
                      />
                    </Col>
                    <Col span={24}>
                      <Typography.Text strong>Customer Code:</Typography.Text>
                    </Col>
                    <Col span={24}>
                      <Input
                        {...customerCodeInput}
                        placeholder="Type here"
                        style={{ maxWidth: "200px" }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Typography.Text strong>Named Insured:</Typography.Text>
            </Col>
            <Col span={24} style={{ marginBottom: 16 }}>
              <Input
                {...namedInsuredInput}
                placeholder="Type here"
                style={{ maxWidth: "300px" }}
              />
            </Col>
            <Col span={24}>
              <Row gutter={16}>
                <Col flex="0 1 350px">
                  <Row>
                    <Col span={24}>
                      <Typography.Text strong>Aircraft:</Typography.Text>
                    </Col>
                    <Col span={24} style={{ marginBottom: 16 }}>
                      <Input
                        {...aircraftInput}
                        placeholder="Type here"
                        // style={{ maxWidth: "200px" }}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col flex="0 1 200px">
                  <Row>
                    <Col span={24}>
                      <Typography.Text strong>Registration:</Typography.Text>
                    </Col>
                    <Col span={24} style={{ marginBottom: 16 }}>
                      <Input
                        {...registrationInput}
                        placeholder="Type here"
                        // style={{ maxWidth: "200px" }}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col flex="0 1 150px">
                  <Row>
                    <Col span={24}>
                      <Typography.Text strong>Passenger Seats:</Typography.Text>
                    </Col>
                    <Col span={24} style={{ marginBottom: 16 }}>
                      <InputNumber
                        {...passengerSeatsInput}
                        placeholder="Type here"
                        style={{ width: "100%" }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={24} style={{ marginBottom: 16 }}>
              <Row>
                <Col flex="0 1 500px">
                  <Typography.Text strong>Approved Pilots: </Typography.Text>
                  <Input.TextArea
                    {...approvedPilotsInput}
                    rows={5}
                    placeholder="Type here"
                  />
                </Col>
              </Row>
            </Col>
            <Col flex="0 1 700px">
              <Typography.Text strong>Internal Comments: </Typography.Text>
              <Input.TextArea {...input} rows={2} placeholder="Type here" />
            </Col>
          </Row>

          <FormProvider
            control={control}
            trigger={trigger}
            watch={watch}
            setValue={setValue}
            getValues={getValues}
          >
            <QuoteOptions
              setFocus={setFocus}
              control={control}
              getValues={getValues}
              setValue={setValue}
            />
          </FormProvider>
        </Spin>
      </Skeleton>
    </Card>
  );
};

export default ApplicationsQuote;
