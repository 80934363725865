import { Navigate } from "react-router-dom";
import { useAuthStatus } from "./useAuthStatus";

const HomeRedirect = () => {
  const { isLoggedIn } = useAuthStatus();

  if (isLoggedIn) {
    return <Navigate to="/applications/search" replace={true} />;
  } else {
    return <Navigate to="/all-forms/private-aviation" replace={true} />;
  }
};

export default HomeRedirect;
