import { Route, Routes } from "react-router-dom";
import ApplicationsQuote from "../../applications/quote/ApplicationsQuote";
import QuoteSearch from "../../applications/quote/QuoteSearch";
import ApplicationsSearch from "../../applications/search/ApplicationsSearch";
import PrivateAviationUser from "../../forms/privateAviation/user/PrivateAviationUser";
import StartApplication from "../../forms/privateAviation/user/startApplication/StartApplication";
import UsersList from "../../users/UsersList";
import ConfirmUser from "../ConfirmUser";
import CreatePassword from "../CreatePassword";
import ForgotPassword from "../ForgotPassword";
import HomeRedirect from "../HomeRedirect";
import Login from "../Login";
import Logout from "../Logout";
import ResultPage from "../ResultPage";
import PublicLayout from "../layouts/PublicLayout";
import AdminRoutesWrapper from "./AdminRoutesWrapper";
import PrivateRoutesWrapper from "./PrivateRoutesWrapper";

const AppRoutes = () => {
  return (
    <Routes>
      {/********
       *
       *  All Public Routes
       *
       *********/}
      <Route element={<PublicLayout />}>
        <Route path="/all-forms/private-aviation/">
          <Route path=":applicationId" element={<PrivateAviationUser />} />
          <Route index element={<StartApplication />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<CreatePassword />} />
        <Route path="/confirm-email" element={<ConfirmUser />} />
      </Route>

      {/********
       *
       *  All Private Routes
       * You must add it to privatePagesMap.js for SubHeader.js to work properly
       * You must also add it to menuItems in SideMenu.js if you want to use side menu navigation
       *
       *********/}
      <Route element={<PrivateRoutesWrapper />}>
        {/* <Route
          path="/forms/private-aviation"
          element={<PrivateAviationBroker />}
        /> */}
        <Route path="/applications/search" element={<ApplicationsSearch />} />
        <Route path="/applications/quote/">
          <Route path=":applicationId" element={<ApplicationsQuote />} />
          <Route index element={<QuoteSearch />} />
        </Route>
      </Route>
      <Route element={<AdminRoutesWrapper />}>
        <Route path="/users" element={<UsersList />} />
      </Route>
      <Route path="/logout" element={<Logout />} />
      <Route path="/" element={<HomeRedirect />} />
      <Route path="*" element={<ResultPage status={404} />} />
    </Routes>
  );
};

export default AppRoutes;
