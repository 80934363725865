import { ReloadOutlined } from "@ant-design/icons";
import {
  AutoComplete,
  Button,
  Card,
  Col,
  Divider,
  Input,
  Row,
  Spin,
  Steps,
  Tabs,
  Typography,
} from "antd";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearQuoteSearchOptions } from "../../../store/quotes/quotesSlice";
import {
  fetchRecentHistory,
  searchQuotes,
} from "../../../store/quotes/quotesThunks";
import HistoryList from "./HistoryList";
import { useEffect } from "react";

const QuoteSearch = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const options = useSelector((state) => state.quotes.options);
  const isHistoryLoading = useSelector(
    (state) => state.quotes.history.isLoading
  );
  const data = useSelector((state) => state.quotes.history.data);
  const quotingData = useSelector((state) => state.quotes.history.quotingData);
  const applicationsData = useSelector(
    (state) => state.quotes.history.applicationsData
  );

  useEffect(() => {
    dispatch(fetchRecentHistory());
  }, []);
  const items = [
    {
      key: "1",
      label: "All history",
      children: <HistoryList data={data} />,
    },
    {
      key: "2",
      label: "Quoting",
      children: "Content of Tab Pane 2",
      children: <HistoryList data={quotingData} />,
    },
    {
      key: "3",
      label: "Applications",
      children: "Content of Tab Pane 3",
      children: <HistoryList data={applicationsData} />,
    },
  ];

  const searchQuotesByTerm = (term) => {
    if (!term) {
      dispatch(clearQuoteSearchOptions());
    } else {
      dispatch(searchQuotes({ term }));
    }
  };

  return (
    <div>
      <Card className="body-card-content-no-min-height">
        <Row justify="center">
          <Col flex="0 1 800px">
            <Steps
              current={0}
              items={[
                {
                  title: "Select an application",
                },
                {
                  title: "Generate a quote",
                },
                {
                  title: "Send a quote",
                },
              ]}
            />
          </Col>
        </Row>
        <Divider dashed />
        <Row justify="center">
          <Col flex="0 1 500px">
            <Typography.Title level={5} style={{ marginTop: 0 }}>
              Start or continue a quote
            </Typography.Title>
            <AutoComplete
              style={{ width: "100%" }}
              options={options}
              onSelect={(value) => {
                if (value) {
                  navigate(`/applications/quote/${value}`);
                }
              }}
              onSearch={debounce(searchQuotesByTerm, 500)}
              onChange={(v) => {
                if (!v) {
                  dispatch(clearQuoteSearchOptions());
                }
              }}
              size="medium"
            >
              <Input.Search
                loading={false}
                size="medium"
                placeholder="Type owner name, aircraft, submission date..."
                enterButton
              />
            </AutoComplete>
          </Col>
        </Row>
      </Card>
      <Card
        className="body-card-content-no-min-height"
        title="Recent history"
        extra={
          <Button
            type="link"
            onClick={() => {
              dispatch(fetchRecentHistory());
            }}
          >
            <ReloadOutlined /> Refresh
          </Button>
        }
      >
        <Spin spinning={isHistoryLoading}>
          <Tabs onChange={() => {}} items={items} />
        </Spin>
      </Card>
    </div>
  );
};

export default QuoteSearch;
