/* eslint-disable jsx-a11y/anchor-is-valid */
import { Alert, Card, Col, Flex, Row, Skeleton, Spin, Steps } from "antd";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { parseItemizedErrorList } from "../../../../shared/parseItemizedErrorList";
import {
  clearAviationForm,
  navigateToPreviousPage,
} from "../../../../store/aviationForm/aviationFormSlice";
import { useFetchApplicationMutation } from "../../../../store/baseApi";
import PrivateAviationUserWrapper from "./PrivateAviationUserWrapper";
import {
  aircraftInformationExtendedSchema,
  aircraftInformationSchema,
  airportInformationSchema,
  applicantsInformationSchema,
  pilotInformationSchema,
} from "./formSchema";
import AircraftInformation from "./steps/AircraftInformation";
import AircraftInformationExtended from "./steps/AircraftInformationExtended";
import AirportInformation from "./steps/AirportInformation";
import ApplicantsInformation from "./steps/ApplicantsInformation";
import { Submitted } from "./steps/Submitted";
import PilotInformation from "./steps/pilotInformation/PilotInformation";
import { PrefaceWrapper } from "./steps/preface/PrefaceWrapper";

const PrivateAviationUser = () => {
  const { applicationId } = useParams();
  const [
    fetchApplication,
    { isError, isSuccess, data, isUninitialized, isLoading },
  ] = useFetchApplicationMutation(applicationId);
  const dispatch = useDispatch();
  const step = useSelector((state) => state.aviationForm.data.step);
  const showPreface = useSelector(
    (state) => state.aviationForm.data.showPreface
  );
  const isSaving = useSelector((state) => state.aviationForm.isSaving);

  const onChange = (value) => {
    if (value + 1 < step) {
      dispatch(navigateToPreviousPage(value + 1));
    }
  };

  useEffect(() => {
    fetchApplication({ id: applicationId });
    return () => {
      dispatch(clearAviationForm());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.querySelector(".scroll-body-layout").scrollTo(0, 0);
  }, [step]);

  const items = useMemo(
    () => [
      {
        title: "Applicants Information",
      },
      {
        title: "Aircraft Information",
        disabled: step < 2,
      },
      {
        title: "Aircraft Information",
        disabled: step < 3,
      },
      {
        title: "Airport Information",
        disabled: step < 4,
      },
      {
        title: "Pilot Information",
        disabled: step < 5,
      },
    ],
    [step]
  );

  return isError ||
    (isSuccess && data != null && (data.success === false || isNaN(step))) ? (
    <Alert
      message={`Error: ${
        isError || isNaN(step)
          ? "Something went wrong while loading this application!"
          : data.errorMessage ?? ""
      }`}
      type="error"
      showIcon
      className="default-margin"
    />
  ) : step === 6 && !isLoading && !isUninitialized ? (
    <Submitted />
  ) : isLoading || isUninitialized ? (
    <div style={{ height: "100%" }} className="centered-body-background">
      <Flex
        justify="center"
        align="center"
        className="expanded-height"
        vertical
      >
        <Spin spinning={true}>
          <div
            style={{
              minWidth: 450,
              maxWidth: 450,
              marginLeft: 32,
              marginRight: 32,
            }}
          ></div>
        </Spin>
      </Flex>
    </div>
  ) : !isLoading && !isUninitialized && showPreface ? (
    <PrefaceWrapper showPreface={showPreface} />
  ) : (
    <Row justify="center">
      {/* <Col span={24} style={{ maxWidth: "1400px", minWidth: "300px" }}> */}
      <Col span={24}>
        <Card className="body-card-content" style={{ height: "100%" }}>
          <Spin spinning={isLoading || isUninitialized}>
            <div>
              <Row justify="center" style={{ marginBottom: 32 }}>
                <Col span={18} style={{ maxWidth: 1200 }}>
                  <Steps
                    current={step - 1}
                    onChange={onChange}
                    labelPlacement="vertical"
                    size="small"
                    items={items}
                  />
                </Col>
              </Row>
              <Spin spinning={isSaving}>
                {step <= 1 && (
                  <PrivateAviationUserWrapper
                    isFirstStep={true}
                    schema={applicantsInformationSchema}
                    title="1. Applicants Information"
                    description="Provide information about the registered owner (including contact details) and indicate products you may be interested in."
                  >
                    <ApplicantsInformation />
                  </PrivateAviationUserWrapper>
                )}
                {step === 2 && (
                  <PrivateAviationUserWrapper
                    schema={aircraftInformationSchema}
                    title="2. Aircraft Information"
                    description="Enter details about the aircraft to insure. Choose coverage type and premium, and include information that may impact the quote (additional parties, lienholders etc.)"
                  >
                    <AircraftInformation />
                  </PrivateAviationUserWrapper>
                )}
                {step === 3 && (
                  <PrivateAviationUserWrapper
                    schema={aircraftInformationExtendedSchema}
                    title="3. Aircraft Information"
                    description="Provide details about trips, events and their frequency."
                  >
                    <AircraftInformationExtended />
                  </PrivateAviationUserWrapper>
                )}
                {step === 4 && (
                  <PrivateAviationUserWrapper
                    schema={airportInformationSchema}
                    title="4. Airport Information"
                    description="Answer questions about home base of aircraft & storage type."
                  >
                    <AirportInformation />
                  </PrivateAviationUserWrapper>
                )}
                {step === 5 && (
                  <PrivateAviationUserWrapper
                    schema={pilotInformationSchema}
                    isLastStep={true}
                    onParseErrorsList={parseItemizedErrorList}
                    title="5. Pilot Information"
                    description="List all pilots and provide information that will help with the quote. You can add up to 5 pilots but at least 1 pilot is required."
                  >
                    <PilotInformation />
                  </PrivateAviationUserWrapper>
                )}
              </Spin>
            </div>
          </Spin>
        </Card>
      </Col>
    </Row>
  );
};

export default PrivateAviationUser;
