export const mainTheme = {
  token: {
    borderRadius: 1,
    // colorPrimary: "#0077b6",
    colorPrimary: "#0f4c85",
  },
};

export const outerLayersTheme = {
  token: {
    borderRadius: 1,
    colorPrimary: "#ab2217",
  },
  components: {
    Button: {
      colorPrimaryHover: "#910000",
      colorPrimaryActive: "#ab2217",
    },
  },
};
