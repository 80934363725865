import { DatePicker, Form } from "antd";
import dayjs from "dayjs";
import React, { memo } from "react";

import { useController } from "react-hook-form";
import { checkFormFieldsEquality } from "./checkFormFieldsEquality";

export const CustomDateRangePicker = (props) => {
  const {
    field,
    fieldState: { error, isTouched, isDirty },
  } = useController({
    name: props.name,
    control: props.control,
  });

  return (
    <Content
      {...props}
      field={field}
      error={error}
      isTouched={isTouched}
      isDirty={isDirty}
    />
  );
};

const Content = memo((props) => {
  const { field, error, formItem, name, ...inputProps } = props;
  delete inputProps.isTouched;
  delete inputProps.isDirty;

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current > dayjs().endOf("day");
  };

  return (
    <Form.Item
      name={name}
      validateStatus={error ? "error" : ""}
      help={error?.message}
      {...formItem}
    >
      <div>
        {props.children}
        <DatePicker.RangePicker
          disabledDate={disabledDate}
          //   style={{ width: "100%" }}
          {...field}
          {...inputProps}
          value={[
            typeof field.value?.[0] === "string"
              ? dayjs(field.value[0])
              : field.value?.[0],
            typeof field.value?.[1] === "string"
              ? dayjs(field.value[1])
              : field.value?.[1],
          ]}
        />
      </div>
    </Form.Item>
  );
}, checkFormFieldsEquality);
