import { InputNumber } from "antd";
import React, { useState } from "react";

export function isNumeric(str) {
  if (typeof str != "string") return false; // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

export const InputCurrency = (props) => {
  const [blurred, setBlurred] = useState(null);

  const handleFormatter = (value, info) => {
    const parsedInput = info.input.replace(/CA\$|\s|,/g, "");

    if (value === "") {
      return "";
    }

    if (info.userTyping === false && blurred === true) {
      const formattedValue = parseFloat(value).toFixed(2);
      return `CA$ ${formattedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    if (info.userTyping === true) {
      if (parsedInput === "") {
        return "";
      }
      return `CA$ ${parsedInput}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    if (blurred === null && value) {
      const formattedValue = parseFloat(value).toFixed(2);
      return `CA$ ${formattedValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  };
  const handleBlur = (e) => {
    props.onBlur && props.onBlur(e);

    e.preventDefault();
    setBlurred(true);
  };

  return (
    <InputNumber
      style={{ width: "100%" }}
      {...props}
      role="input-number"
      controls={false}
      formatter={(value, info) => handleFormatter(value, info)}
      parser={(value) => value.replace(/CA\$|\s|,/g, "")}
      onBlur={handleBlur}
      onFocus={() => setBlurred(false)}
    />
  );
};
