import { FilterOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Input, Row, Space } from "antd";
import dayjs from "dayjs";
const isBetween = require("dayjs/plugin/isBetween");
dayjs.extend(isBetween);

export const getDateFilterProps = (dataIndex) => {
  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
  };
  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Row>
          <Col>
            <DatePicker.RangePicker
              format={[
                "DD-MMM-YYYY",
                "MMM-DD-YYYY",
                "MM-DD-YY",
                "MM/DD/YY",
                "MMDDYY",
                "MM-DD-YYYY",
                "MM/DD/YYYY",
              ]}
              value={selectedKeys[0]}
              onChange={(dates) => setSelectedKeys(dates ? [dates] : [])}
              style={{ marginBottom: 8 }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Space>
              <Button
                type="primary"
                onClick={() => {
                  confirm();
                }}
                onPressEnter={() => {
                  confirm();
                }}
                icon={<FilterOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Filter
              </Button>
              <Button
                onClick={() => {
                  handleReset(clearFilters, confirm);
                }}
                size="small"
                style={{ width: 90 }}
              >
                Reset
              </Button>
            </Space>
          </Col>
        </Row>
      </div>
    ),
    onFilter: (value, record) => {
      if (record?.[dataIndex] == null) return false;

      return dayjs(record[dataIndex]).isBetween(
        value[0].format("YYYY-MM-DD"),
        value[1].format("YYYY-MM-DD"),
        "day",
        "[]"
      );
    },
  };
};

export const getTextFilterProps = (dataIndex, title) => {
  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Filter ${title} By`}
          value={selectedKeys[0] ?? ""}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onKeyDown={(e) => e.stopPropagation()}
          onPressEnter={() => {
            confirm();
          }}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
            icon={<FilterOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Filter
          </Button>
          <Button
            onClick={() => {
              clearFilters();
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    onFilter: (value, record) => {
      if (record?.[dataIndex] == null) return false;

      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
  };
};
