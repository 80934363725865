import {
  Card,
  Col,
  Divider,
  Grid,
  InputNumber,
  List,
  Radio,
  Row,
  Space,
  Typography,
  message,
} from "antd";
import { Controller, useFormContext } from "react-hook-form";
import {
  canadian_provinces,
  hull_coverage_requested,
} from "../../../../../shared/constants";
import { CustomCheckbox } from "../../../../../shared/formFields/CustomCheckbox";
import { CustomDatePicker } from "../../../../../shared/formFields/CustomDatePicker";
import { CustomInput } from "../../../../../shared/formFields/CustomInput";
import { CustomInputCurrency } from "../../../../../shared/formFields/CustomInputCurrency";
import { CustomInputNumber } from "../../../../../shared/formFields/CustomInputNumber";
import { CustomRadioGroup } from "../../../../../shared/formFields/CustomRadioGroup";
import { CustomSelectField } from "../../../../../shared/formFields/CustomSelectField";
import { CustomCoverageOptions } from "../../formFields/CustomCoverageOptions";
import { HullCoverageField } from "../../formFields/HullCoverageField";
import { PostalCodeInput } from "../../formFields/PostalCodeInput";
import { OptionalProductField } from "../../formFields/OptionalProductField";
import { CardField } from "../../formFields/CardField";
import { ExclamationCircleTwoTone } from "@ant-design/icons";
import { LiabilityErrorMessage } from "../../formFields/LiabilityErrorMessage";

const AircraftInformation = () => {
  const { control, trigger, watch, setValue } = useFormContext();
  const [messageApi, contextHolder] = message.useMessage();
  const screens = Grid.useBreakpoint();
  const isXs = screens.xs === true && !screens.sm;
  const willChargeForAircraftUseDetails = watch("will_charge_for_aircraft_use");
  const changeInUse = watch("will_change_aircraft_configuration");
  const equipmentAdded = watch("equipment_checkbox");
  const hasAdditionalInsured = watch("has_additional_insured");
  const hasLienholder = watch("has_lienholder");
  const optionalChecked = watch([
    "optional_1million_checked",
    "optional_2million_checked",
    "optional_3million_checked",
    "optional_5million_checked",
  ]);
  const customCoverageOptions = watch("custom_coverage_options");
  const coverageOptionsCount =
    (optionalChecked[0] ? 1 : 0) +
    (optionalChecked[1] ? 1 : 0) +
    (optionalChecked[2] ? 1 : 0) +
    (optionalChecked[3] ? 1 : 0) +
    (customCoverageOptions?.length ?? 0);

  const data = [
    {
      id: "1",
      title: "CA$1,000,000",
    },
    {
      id: "2",
      title: "CA$2,000,000",
    },
    {
      id: "3",
      title: "CA$3,000,000",
    },
    {
      id: "5",
      title: "CA$5,000,000",
    },
  ];
  const sendMaxiumOptionsWarning = (execute) => {
    if (coverageOptionsCount >= 5) {
      messageApi.open({
        type: "warning",
        content: "You cannot select more than 5 options.",
      });
    } else {
      execute && execute();
    }
  };
  return (
    <div>
      {contextHolder}
      <Card
        className="form-card-style"
        bordered={false}
        title="Type of aircraft"
      >
        <Row gutter={12}>
          <Col xs={24} sm={24} md={6}>
            <CustomInputNumber
              formItem={{ required: true, label: "Year" }}
              control={control}
              name="year"
              placeholder="Year"
            />
          </Col>
          <Col xs={24} sm={24} md={6}>
            <CustomInput
              formItem={{ required: true, label: "Make" }}
              control={control}
              name="make"
              placeholder="Make"
            />
          </Col>
          <Col xs={24} sm={24} md={6}>
            <CustomInput
              formItem={{ required: true, label: "Model" }}
              control={control}
              name="model"
              placeholder="Model"
            />
          </Col>
          <Col xs={24} sm={24} md={6}>
            <CustomInput
              formItem={{ required: true, label: "Registration" }}
              control={control}
              name="registration"
              placeholder="Registration"
            />
          </Col>
        </Row>
      </Card>
      <Card
        className="form-card-style"
        bordered={false}
        title="Aircraft use: Private, Business & Pleasure"
      >
        <Row gutter={12}>
          <Col xs={24} sm={24} md={24}>
            <Row gutter={12}>
              <Col span={24}>
                <CustomRadioGroup
                  isXs={isXs}
                  formItem={{
                    required: true,
                    label:
                      "Will you be charging other people for the use of your aircraft?",
                    style: willChargeForAircraftUseDetails
                      ? { marginBottom: !isXs ? "8px" : "35px" }
                      : !isXs
                      ? null
                      : { marginBottom: "60px" },
                    // className: "increased-xs-margin",
                  }}
                  control={control}
                  name="will_charge_for_aircraft_use"
                  onChange={(e) => {
                    if (!e.target.value) {
                      trigger("will_charge_for_aircraft_use_details");
                    }
                  }}
                >
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </CustomRadioGroup>
              </Col>
            </Row>
            {willChargeForAircraftUseDetails && (
              <Row gutter={12} style={!isXs ? null : { marginBottom: "20px" }}>
                <Col span={24}>
                  <CustomInput
                    isXs={isXs}
                    formItem={{
                      required: true,
                      label:
                        "Please explain how you will charge other people for the use of your aircraft",
                      className: "increased-xs-margin",
                    }}
                    control={control}
                    name="will_charge_for_aircraft_use_details"
                    placeholder="Enter more details"
                    style={!isXs ? null : { marginBottom: "10px" }}
                  />
                </Col>
              </Row>
            )}
          </Col>
          <Col xs={24} sm={24} md={24}>
            <Row gutter={12}>
              <Col span={24}>
                <CustomRadioGroup
                  formItem={{
                    required: true,
                    label:
                      "During the policy term, will you be changing the configuration of your aircraft to amphibious, floats, or skis?",
                    style: changeInUse ? { marginBottom: "8px" } : null,
                    className: "increased-xs-margin",
                  }}
                  control={control}
                  name="will_change_aircraft_configuration"
                  onChange={(e) => {
                    if (!e.target.value) {
                      trigger("will_change_aircraft_configuration_details");
                    }
                  }}
                >
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </CustomRadioGroup>
              </Col>
            </Row>
            {changeInUse && (
              <Row gutter={12}>
                <Col span={24}>
                  <CustomInput
                    formItem={{
                      required: true,
                      label: "Please provide details",
                    }}
                    control={control}
                    name="will_change_aircraft_configuration_details"
                    placeholder="Enter details about configuration changes"
                  />
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Card>
      <Card
        className="form-card-style"
        bordered={false}
        title="Hull Configuration (select all that apply below)"
      >
        <Row gutter={[12, 12]}>
          <Col xs={24} sm={24} md={14} lg={12}>
            <CardField>
              <Row
                style={{
                  background: "#fafafa",
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
              >
                <Col flex="1 1 10px"></Col>
                <Col flex="3 3 40px">
                  <Typography.Text strong>Configuration</Typography.Text>
                </Col>
                <Col flex="4 4 40px">
                  <Typography.Text strong>Amount</Typography.Text>
                </Col>
              </Row>
              <Divider style={{ marginTop: 0, marginBottom: 5 }} />
              <HullCoverageField
                label="Wheels fixed gear"
                control={control}
                name="wheels_fixed_gear"
                watch={watch}
                trigger={trigger}
              />
              <Divider style={{ marginTop: 0, marginBottom: 5 }} />
              <HullCoverageField
                label="Wheels tailwheel"
                control={control}
                name="wheels_tailwheel"
                watch={watch}
                trigger={trigger}
              />
              <Divider style={{ marginTop: 0, marginBottom: 5 }} />
              <HullCoverageField
                label="Wheels retract"
                control={control}
                name="wheels_retract"
                watch={watch}
                trigger={trigger}
              />
              <Divider style={{ marginTop: 0, marginBottom: 5 }} />
              <HullCoverageField
                label="Floats"
                control={control}
                name="floats"
                watch={watch}
                trigger={trigger}
              />
              <Divider style={{ marginTop: 0, marginBottom: 5 }} />
              <HullCoverageField
                label="Skis"
                control={control}
                name="skis"
                watch={watch}
                trigger={trigger}
              />
              <Divider style={{ marginTop: 0, marginBottom: 5 }} />
              <HullCoverageField
                label="Amphibian"
                control={control}
                name="amphibian"
                watch={watch}
                trigger={trigger}
              />
              <Divider style={{ marginTop: 0, marginBottom: 5 }} />
              <HullCoverageField
                label="Helicopter"
                control={control}
                name="helicopter"
                watch={watch}
                trigger={trigger}
              />
            </CardField>
          </Col>
          <Col xs={24} sm={24} md={10} lg={12}>
            <CustomInputNumber
              formItem={{
                required: true,
                label: "Number of passenger seats (excluding pilot)",
              }}
              control={control}
              name="passenger_seats"
              placeholder="Seats"
              style={{ width: 90 }}
            />

            <CustomInput
              formItem={{
                required: true,
                label: "Horse power & engine make",
              }}
              control={control}
              name="horse_power"
              placeholder="Horse power & engine make"
              style={{ width: 200 }}
            />
          </Col>
        </Row>
      </Card>
      <Card
        className="form-card-style"
        bordered={false}
        title="Coverage Requested"
      >
        <Row gutter={12}>
          <Col xs={24} sm={24} md={18}>
            <CustomSelectField
              mode="multiple"
              options={hull_coverage_requested}
              formItem={{
                required: true,
                label: "Hull Coverage Requested",
              }}
              control={control}
              name="hull_coverage_requested"
              placeholder="Hull Coverage Requested"
              style={{ maxWidth: 600 }}
            />
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={24}>
            Combined Liability:
            <div style={{ fontStyle: "italic", color: "grey" }}>
              (Combined Liability combines Third Party Liability & Passenger
              Liability into one limit. You can also add Premises Liability
              coverage. If you choose multiple options, we will send you
              multiple quotes. Additionally, you can press 'I want to add custom
              coverage' to choose your own third party, passenger and premises
              liabilities. )
            </div>
            <LiabilityErrorMessage />
            <br />
            <List
              grid={{
                gutter: 12,
                xs: 1,
                sm: 1,
                md: 2,
                lg: 4,
                xl: 4,
                xxl: 4,
              }}
              dataSource={data}
              renderItem={(item) => {
                const isChecked =
                  optionalChecked?.[Math.min(parseInt(item.id) - 1, 3)] ===
                  true;
                const newNode = (
                  <Row justify="space-between">
                    <Col>{item.title}</Col>
                    <Col>
                      <CustomCheckbox
                        isXs={coverageOptionsCount}
                        control={control}
                        name={"optional_" + item.id + "million_checked"}
                        onChange={(e) => {
                          trigger("liability_validation");
                          if (
                            e?.target?.checked === true &&
                            coverageOptionsCount >= 5
                          ) {
                            sendMaxiumOptionsWarning();
                            setValue(
                              "optional_" + item.id + "million_checked",
                              false
                            );
                          }
                        }}
                      />
                    </Col>
                  </Row>
                );
                return (
                  <List.Item>
                    <Card
                      title={isChecked ? newNode : null}
                      className={isChecked ? "checked-coverage" : ""}
                    >
                      {isChecked ? (
                        <div>
                          <Row>
                            <Col>Include premises liability coverage?</Col>
                          </Row>
                          <br />
                          <Row justify="center">
                            <Col>
                              <CustomRadioGroup
                                defaultValue={false}
                                buttonStyle="solid"
                                control={control}
                                name={
                                  "optional_" + item.id + "million_premises"
                                }
                              >
                                <Radio.Button value={true}>Yes</Radio.Button>
                                <Radio.Button value={false}>No</Radio.Button>
                              </CustomRadioGroup>
                            </Col>
                          </Row>
                        </div>
                      ) : (
                        newNode
                      )}
                    </Card>
                  </List.Item>
                );
              }}
            />
          </Col>
        </Row>
        <Divider dashed={true} />
        <CustomCoverageOptions
          control={control}
          sendMaxiumOptionsWarning={sendMaxiumOptionsWarning}
          trigger={trigger}
        />
      </Card>
      <Card
        className="form-card-style"
        bordered={false}
        title="Additional Insured"
      >
        <Row gutter={12}>
          <Col span={24}>
            <CustomRadioGroup
              formItem={{
                required: true,
                label: "Do you wish to add an additional insured?",
              }}
              control={control}
              name="has_additional_insured"
              onChange={(e) => {
                if (!e.target.value) {
                  trigger([
                    "additional_insured_name",
                    "additional_insured_nature",
                    "additional_insured_address",
                    "additional_insured_city",
                    "additional_insured_province",
                    "additional_insured_postal_code",
                  ]);
                }
              }}
            >
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </CustomRadioGroup>
          </Col>
          {hasAdditionalInsured === true && (
            <>
              <Col xs={24} sm={24} md={12}>
                <CustomInput
                  formItem={{
                    required: true,
                    label: "Name",
                  }}
                  control={control}
                  name="additional_insured_name"
                  placeholder="Enter name"
                />
              </Col>
              <Col xs={24} sm={24} md={12}>
                <CustomInput
                  formItem={{
                    required: true,
                    label: "Reason for being added as an additional insured",
                  }}
                  control={control}
                  name="additional_insured_nature"
                  placeholder="Nature"
                />
              </Col>
              <Col xs={24} sm={24} md={12}>
                <CustomInput
                  formItem={{
                    required: true,
                    label: "Address",
                  }}
                  control={control}
                  name="additional_insured_address"
                  placeholder="Address"
                />
              </Col>
              <Col xs={24} sm={24} md={5}>
                <CustomInput
                  formItem={{
                    required: true,
                    label: "City",
                  }}
                  control={control}
                  name="additional_insured_city"
                  placeholder="City"
                />
              </Col>
              <Col xs={24} sm={24} md={3}>
                <CustomSelectField
                  options={canadian_provinces}
                  formItem={{
                    required: true,
                    label: "Province",
                  }}
                  control={control}
                  name="additional_insured_province"
                  placeholder="Province"
                />
              </Col>
              <Col xs={24} sm={24} md={4}>
                <PostalCodeInput
                  formItem={{
                    required: true,
                    label: "Postal Code",
                  }}
                  control={control}
                  name="additional_insured_postal_code"
                  placeholder="Postal Code"
                />
              </Col>
            </>
          )}
        </Row>
      </Card>
      <Card
        className="form-card-style"
        bordered={false}
        title="Aircraft Lienholder"
      >
        <Row gutter={12}>
          <Col span={24}>
            <CustomRadioGroup
              formItem={{
                required: true,
                label:
                  "Do you have a loan against your aircraft that requires a loss payee named on your insurance policy?",
                className: "increased-xs-margin",
              }}
              control={control}
              name="has_lienholder"
              onChange={(e) => {
                if (!e.target.value) {
                  trigger([
                    "lienholder_name",
                    "lienholder_amount",
                    "lienholder_address",
                    "lienholder_vision",
                    "lienholder_city",
                    "lienholder_province",
                    "lienholder_postal_code",
                  ]);
                }
              }}
            >
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </CustomRadioGroup>
          </Col>
          {hasLienholder === true && (
            <>
              <Col xs={24} sm={24} md={12}>
                <CustomInput
                  formItem={{ required: true, label: "Lienholder Name" }}
                  control={control}
                  name="lienholder_name"
                  placeholder="Enter name"
                />
              </Col>
              <Col xs={24} sm={24} md={12}>
                <CustomInputCurrency
                  formItem={{
                    required: true,
                    label: "Lien amount",
                  }}
                  control={control}
                  name="lienholder_amount"
                  placeholder="Amount"
                  style={{ width: 200 }}
                />
              </Col>
              <Col xs={24} sm={24} md={12}>
                <CustomInput
                  formItem={{ required: true, label: "Lienholder Address" }}
                  control={control}
                  name="lienholder_address"
                  placeholder="Address"
                />
              </Col>
              <Col xs={24} sm={24} md={5}>
                <CustomInput
                  formItem={{
                    required: true,
                    label: "City",
                  }}
                  control={control}
                  name="lienholder_city"
                  placeholder="City"
                />
              </Col>
              <Col xs={24} sm={24} md={3}>
                <CustomSelectField
                  options={canadian_provinces}
                  formItem={{
                    required: true,
                    label: "Province",
                  }}
                  control={control}
                  name="lienholder_province"
                  placeholder="Province"
                />
              </Col>
              <Col xs={24} sm={24} md={4}>
                <PostalCodeInput
                  formItem={{
                    required: true,
                    label: "Postal Code",
                  }}
                  control={control}
                  name="lienholder_postal_code"
                  placeholder="Postal Code"
                />
              </Col>
            </>
          )}
        </Row>
      </Card>
      <Card
        className="form-card-style"
        bordered={false}
        title="Aircraft maintenance"
      >
        <Row gutter={12}>
          <Col xs={24} sm={24} md={8}>
            {/* <CustomInputNumber
              formItem={{ label: "Engine hours since new" }}
              control={control}
              name="engine_hours_new"
              placeholder="Hours"
              style={{ width: 90 }}
            /> */}
            <Row style={{ marginBottom: 8 }}>
              <Col>Engine hours since new</Col>
            </Row>
            <Row style={{ marginBottom: 24 }}>
              <Col>
                <Space direction="horizontal">
                  <Controller
                    control={control}
                    name="engine_hours_new"
                    render={({ field }) => (
                      <InputNumber
                        {...field}
                        placeholder="Engine 1"
                        style={{ width: 90 }}
                        controls={false}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="engine2_hours_new"
                    render={({ field }) => (
                      <InputNumber
                        {...field}
                        placeholder="Engine 2"
                        style={{ width: 90 }}
                        controls={false}
                      />
                    )}
                  />
                </Space>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={9}>
            {/* <CustomInputNumber
              formItem={{
                label: "Engine hours since last major overhaul",
              }}
              control={control}
              name="engine_hours_last"
              placeholder="Hours"
              style={{ width: 90 }}
            /> */}
            <Row style={{ marginBottom: 8 }}>
              <Col>Engine hours since last major overhaul</Col>
            </Row>
            <Row style={{ marginBottom: 24 }}>
              <Col>
                <Space direction="horizontal">
                  <Controller
                    control={control}
                    name="engine_hours_last"
                    render={({ field }) => (
                      <InputNumber
                        {...field}
                        placeholder="Engine 1"
                        style={{ width: 90 }}
                        controls={false}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="engine2_hours_last"
                    render={({ field }) => (
                      <InputNumber
                        {...field}
                        placeholder="Engine 2"
                        style={{ width: 90 }}
                        controls={false}
                      />
                    )}
                  />
                </Space>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={7}>
            <CustomDatePicker
              formItem={{
                label: "Date of last aircraft annual",
              }}
              control={control}
              name="last_aircraft_annual"
              placeholder="Select or type a date"
              style={{ width: 175 }}
            />
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={24}>
            <CustomRadioGroup
              formItem={{
                label: "Has equipment been added in the last 12 months?",
                style: equipmentAdded ? { marginBottom: "8px" } : null,
              }}
              control={control}
              name="equipment_checkbox"
              onChange={(e) => {
                if (!e.target.value) {
                  trigger("equipment_input");
                }
              }}
            >
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </CustomRadioGroup>
          </Col>
          {equipmentAdded && (
            <Col span={24}>
              <CustomInput
                formItem={{ required: true, label: "Equipment list" }}
                control={control}
                name="equipment_input"
                placeholder="Equipment list"
              />
            </Col>
          )}
        </Row>
      </Card>
    </div>
  );
};

export default AircraftInformation;
