import fileDownload from "js-file-download";
import {
  showNotification,
  notifyApiCallFailed,
} from "../store/notifications/notificationsSlice";
import { setIsDownloading } from "../store/downloads/downloadsSlice";
import axios, { HttpStatusCode } from "axios";
export const downloadFile =
  ({ url, name }) =>
  async (dispatch) => {
    const baseUrl =
      process.env.REACT_APP_USE_LOCAL_API_URL === "true"
        ? process.env.REACT_APP_LOCAL_API_URL
        : process.env.REACT_APP_EXTERNAL_API_URL;
    try {
      dispatch(setIsDownloading(true));
      var urlToAppend = url[0] == "/" ? url : `/${url}`;
      const response = await axios.get(`${baseUrl}${urlToAppend}`, {
        responseType: "blob",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });

      var disposition = "";
      var filename = "";

      //
      if (response.headers.has("Content-Disposition")) {
        disposition = response.headers.get("Content-Disposition");
        if (disposition.startsWith("inline; ")) {
          disposition = disposition.substring(7);
        }
        filename = disposition.split(/;(.+)/)[0].split(/=(.+)/)[1];
      }

      if (filename.toLowerCase().startsWith("utf-8''")) {
        filename = decodeURIComponent(filename.replace(/utf-8''/i, ""));
      } else {
        filename = filename.replace(/['"]/g, "");
      }

      if (response.status != HttpStatusCode.Ok) {
        throw new Error();
      }

      fileDownload(response.data, !filename ? `${name}.pdf` : filename);

      dispatch(
        showNotification({
          type: "success",
          message: "Downloaded",
          description: "Your file has been downloaded!",
        })
      );
    } catch (e) {
      dispatch(notifyApiCallFailed());
    }
    dispatch(setIsDownloading(false));
  };
