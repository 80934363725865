import { Col, Row, Space } from "antd";
import React, { memo } from "react";

import { CustomCheckbox } from "../../../../shared/formFields/CustomCheckbox.js";
import { CustomInputCurrency } from "../../../../shared/formFields/CustomInputCurrency.js";

export const HullCoverageField = (props) => {
  return <Content {...props} />;
};

const Content = memo(
  (props) => {
    const { control, watch, name, label, trigger } = props;
    const checkboxName = `${name}_checkbox`;
    const amountName = `${name}_amount`;
    const checked = watch(checkboxName);

    return (
      <div>
        <Row>
          <Col flex="1 1 10px">
            <CustomCheckbox
              name={checkboxName}
              control={control}
              onChange={(e) => {
                if (!e.target.checked) {
                  trigger(amountName);
                }
                trigger("hull_configuration");
              }}
            />
          </Col>
          <Col flex="3 3 40px">{label}</Col>
          <Col flex="4 4 40px">
            <CustomInputCurrency
              name={amountName}
              control={control}
              disabled={!checked}
              placeholder={!checked ? "" : "Coverage amount"}
              style={{ maxWidth: 150, width: "100%" }}
              formItem={{ style: { marginBottom: 5 } }}
              // style={{ width: "100%" }}
            />
          </Col>
        </Row>
      </div>
    );
  },
  (prev, next) => false
);
