/* eslint-disable react-hooks/exhaustive-deps */
import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Row, Table, Tabs, Tooltip } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useFieldArray, useFormContext, useFormState } from "react-hook-form";
import PilotInformationForm from "./PilotInformationForm";
import { emptyPilotObject } from "./emptyPilotObject";
import { pilotInformationColumns } from "./pilotInformationColumns";

const PilotInformation = () => {
  const { control, trigger } = useFormContext();
  const { errors, isSubmitting } = useFormState({
    control: control,
    name: "pilots",
  });

  const { fields, append, remove, update } = useFieldArray({
    control: control,
    name: "pilots",
  });
  const [currentActiveTab, setCurrentActiveTab] = useState(0);

  const columns = useCallback(
    pilotInformationColumns({
      setCurrentActiveTab,
      fields,
      remove,
      update,
      currentActiveTab,
      errors,
    }),
    [setCurrentActiveTab, fields, remove, update, currentActiveTab, errors]
  );
  const [showErrors, setShowErrors] = useState({});
  const updateShowErrorsList = () => {
    var newMap = { ...showErrors };
    fields.forEach((e) => {
      newMap[e.id] = true;
    });
    setShowErrors(newMap);
  };
  useEffect(() => {
    if (isSubmitting) {
      updateShowErrorsList();
    }
  }, [isSubmitting]);

  useEffect(() => {
    trigger("pilots");
  }, []);

  const items = fields.map((item, index) => {
    return {
      label:
        (item.pilot_first_name !== "" && item.pilot_first_name != null) ||
        (item.pilot_last_name !== "" && item.pilot_last_name != null)
          ? (
              (item.pilot_first_name ?? "") +
              " " +
              (item.pilot_last_name ?? "")
            ).trim()
          : `Pilot ${index + 1}`,
      children: (
        <PilotInformationForm
          showErrors={showErrors?.[item.id]}
          item={item}
          baseName={`pilots[${index}].`}
          index={index}
        />
      ),
      key: index,
    };
  });

  return (
    <div>
      <Row style={{ marginBottom: "24px" }}>
        <Col span={24}>
          <Table
            title={() => "Pilots List"}
            scroll={{ x: 630 }}
            size="small"
            columns={columns}
            dataSource={fields}
            pagination={false}
            // virtual
            bordered
            // sticky
            rowClassName={(_, index) =>
              index === currentActiveTab ? "highlight-pilot-table-row" : null
            }
          />
        </Col>
      </Row>
      <Row style={{ marginBottom: "24px" }}>
        <Col span={24}>
          <Button
            type="dashed"
            style={{ width: "100%" }}
            disabled={fields.length > 4}
            onClick={() => {
              updateShowErrorsList();
              append(emptyPilotObject);
              trigger("pilots");
              setCurrentActiveTab(fields.length);
            }}
          >
            <PlusOutlined /> Add a pilot
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Tabs
            activeKey={currentActiveTab}
            hideAdd
            type="card"
            onEdit={null}
            items={items}
            onChange={(e) => {
              setCurrentActiveTab(e);
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default PilotInformation;
