import { LoginOutlined, MenuOutlined, SelectOutlined } from "@ant-design/icons";
import { Alert, Button, Col, Dropdown, Row, Space, Tag } from "antd";

import { Link, useNavigate } from "react-router-dom";

const getDropdownItems = [
  {
    key: "0",
    label: (
      <Link to="/all-forms/private-aviation">Private Aviation Application</Link>
    ),
    icon: <SelectOutlined />,
  },
  {
    key: "1",
    label: <Link to="/login">Login</Link>,
    icon: <LoginOutlined />,
  },
];

const UnauthenticatedHeader = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Row>
        <Col xs={0} sm={24}>
          <Row justify="end">
            {process.env.REACT_APP_SHOW_ENVIRONMENT_FLAG !== "false" && <Col>
              <Row>
                <Col style={{ marginRight: "16px" }} xs={0} sm={0} md={24}>
                  <Space align="center">
                    <Alert message={`${process.env.REACT_APP_SHOW_ENVIRONMENT_FLAG}`} type="info" showIcon />
                  </Space>
                </Col>
              </Row>
            </Col>}
            <Col style={{ marginRight: "16px" }}>
              <Button onClick={() => navigate("/all-forms/private-aviation")}>
                <SelectOutlined />
                Private Aviation Application
              </Button>
            </Col>
            <Col style={{ marginRight: "16px" }}>
              <Button type="primary" onClick={() => navigate("/login")}>
                <LoginOutlined /> Login
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={0}>
          <Row justify="end">
            <Col style={{ marginRight: "16px" }}>
              <Dropdown menu={{ items: getDropdownItems }} placement="bottom">
                <Button type="primary">
                  <MenuOutlined />
                </Button>
              </Dropdown>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default UnauthenticatedHeader;
