import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Col, Form, InputNumber, Row, Select, Space } from "antd";
import React, { memo, useMemo } from "react";
import {
  Controller,
  useFieldArray,
  useFormState,
  useWatch,
} from "react-hook-form";

export const PhoneTypes = {
  HOME: "HOME",
  WORK: "WORK",
  CELL: "CELL",
  FAX: "FAX",
};

export class CustomPhone {
  constructor(number = "", type = PhoneTypes.HOME) {
    this.number = number;
    this.type = type;
  }
}

export const PhoneTypeAndNumberInput = (props) => {
  const { fields, append, remove } = useFieldArray({
    control: props.control,
    name: "phone_numbers",
  });

  const { errors } = useFormState({ control: props.control });

  const phoneNumbers = useWatch({
    control: props.control,
    name: "phone_numbers",
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const disabledValuesList = phoneNumbers
    ? phoneNumbers.map((e) => e.type)
    : [];

  const options = useMemo(
    () => [
      {
        value: PhoneTypes.HOME,
        disabled: disabledValuesList.includes(PhoneTypes.HOME),
        label: "Home Phone",
      },
      {
        value: PhoneTypes.WORK,
        disabled: disabledValuesList.includes(PhoneTypes.WORK),
        label: "Work Phone",
      },
      {
        value: PhoneTypes.CELL,
        disabled: disabledValuesList.includes(PhoneTypes.CELL),
        label: "Cell Phone",
      },
      {
        value: PhoneTypes.FAX,
        disabled: disabledValuesList.includes(PhoneTypes.FAX),
        label: "Fax Number",
      },
    ],
    [disabledValuesList]
  );

  return (
    <Content
      {...props}
      fields={fields}
      options={options}
      control={props.control}
      append={append}
      remove={remove}
      errors={errors}
      trigger={props.trigger}
    />
  );
};

const Content = memo((props) => {
  const { fields, control, append, remove } = props;
  return (
    <Form.Item
      name="phones"
      label="Phone"
      required={true}
      style={{ marginBottom: 0 }}
    >
      {fields.map((item, index) => (
        <div key={item.id}>
          <Row>
            <Col md={14}>
              <Form.Item
                name={`phone_numbers[${index}]`}
                validateStatus={
                  props.errors?.phone_numbers != null &&
                  props.errors?.phone_numbers[index] != null
                    ? "error"
                    : ""
                }
                help={
                  props.errors?.phone_numbers != null &&
                  props.errors?.phone_numbers[index] != null
                    ? props.errors?.phone_numbers[index]?.type?.message ??
                      props.errors?.phone_numbers[index]?.number?.message ??
                      ""
                    : ""
                }
              >
                <div>
                  <Controller
                    control={control}
                    name={`phone_numbers[${index}].number`}
                    render={({ field }) => (
                      <Space.Compact block>
                        <PhoneTypeSelect
                          control={control}
                          options={props.options}
                          name={`phone_numbers[${index}].type`}
                        />
                        <InputNumber
                          placeholder="Phone Number"
                          style={{ width: "100%" }}
                          controls={false}
                          formatter={(value) => {
                            if (value.length >= 7) {
                              return `(${value.slice(0, 3)}) ${value.slice(
                                3,
                                6
                              )}-${value.slice(6)}`;
                            } else if (value.length >= 4) {
                              return `(${value.slice(0, 3)}) ${value.slice(
                                3,
                                6
                              )}`;
                            } else if (value.length === 3) {
                              return `(${value.slice(0, 3)})`;
                            }
                            return value;
                          }}
                          parser={(value) => {
                            let newValue = value.replace("(", "");
                            newValue = newValue.replace(")", "");
                            newValue = newValue.replace("-", "");
                            newValue = newValue.replace(" ", "");
                            return newValue;
                          }}
                          {...field}
                        />
                      </Space.Compact>
                    )}
                  />
                </div>
              </Form.Item>
            </Col>
            <Col>
              <Space.Compact>
                {fields.length > 1 && (
                  <Button
                    type="link"
                    icon={<MinusCircleOutlined />}
                    className="link-button-primary"
                    onClick={() => {
                      remove(index);
                    }}
                  />
                )}
                {fields.length < 4 && fields.length - 1 === index && (
                  <Button
                    type="link"
                    icon={<PlusCircleOutlined />}
                    className="link-button-primary"
                    disabled={
                      props.errors?.phone_numbers != null &&
                      props.errors?.phone_numbers[index] != null
                    }
                    onClick={async () => {
                      var val = await props.trigger("phone_numbers");

                      if (val) {
                        append(new CustomPhone("", null));
                      }
                    }}
                  />
                )}
              </Space.Compact>
            </Col>
          </Row>
        </div>
      ))}
    </Form.Item>
  );
});

export const PhoneTypeSelect = ({ control, name, options }) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <Select
          name={name}
          style={{ minWidth: 125, maxWidth: 150 }}
          options={options}
          placeholder="Type"
          {...field}
        />
      )}
    />
  );
};
