import { Card, Col, Progress, Row, Typography } from "antd";
import dayjs from "dayjs";
import { useFormContext } from "react-hook-form";
import { CustomDatePicker } from "../../../../../../shared/formFields/CustomDatePicker";
import { CustomInput } from "../../../../../../shared/formFields/CustomInput";

export const PrefaceStep3 = ({ navigationButtons }) => {
  const { control, watch } = useFormContext();
  const currentlyInsured = watch("currently_insured");
  const newOrRenew = watch("new_or_renew");
  const hasOtherInsurance = watch("has_other_insurance");
  return (
    <Card>
      <Row justify="space-between">
        <Col>
          <Typography.Title
            level={4}
            style={{ marginTop: 0, marginBottom: 12 }}
          >
            Coverage details
          </Typography.Title>
        </Col>
        <Col>
          <Progress steps={4} percent={75} showInfo={false} />
        </Col>
      </Row>

      {currentlyInsured === false && hasOtherInsurance === true ? (
        <Typography.Text type="secondary">
          Provide detailed information about your current insurance coverage to
          help us understand your needs.
        </Typography.Text>
      ) : (
        <Typography.Text type="secondary" style={{ marginBottom: 22 }}>
          We need more information to understand your needs.
        </Typography.Text>
      )}
      <Row style={{ marginTop: 24 }}>
        <Col span={24}>
          <Row>
            <Col span={24}>
              {currentlyInsured === false && hasOtherInsurance === true && (
                <div>
                  <CustomDatePicker
                    formItem={{
                      label: "What is the current expiry date?",
                      required: true,
                    }}
                    control={control}
                    name="other_insurance_expiry_date"
                    placeholder="Select or type a date"
                    disabledDate={(current) =>
                      current && current < dayjs().startOf("day")
                    }
                  />
                  <CustomInput
                    formItem={{
                      required: true,
                      label: "Who is your current insurance company?",
                    }}
                    control={control}
                    name="other_insurance_carrier"
                    placeholder="Type here"
                  />
                  <CustomInput
                    formItem={{
                      required: true,
                      label: "Which insurance broker do you currently use?",
                    }}
                    control={control}
                    name="other_insurance_broker"
                    placeholder="Type here"
                  />
                  <CustomDatePicker
                    formItem={{
                      label: "When is the expected date of coverage required?",
                      required: true,
                    }}
                    control={control}
                    name="coverage_date"
                    placeholder="Select or type a date"
                    disabledDate={(current) =>
                      current && current < dayjs().startOf("day")
                    }
                  />
                </div>
              )}
              {((currentlyInsured === true && newOrRenew == "new") ||
                (currentlyInsured === false &&
                  hasOtherInsurance === false)) && (
                <CustomDatePicker
                  formItem={{
                    label: "When is the expected date of coverage required?",
                    required: true,
                  }}
                  control={control}
                  name="coverage_date"
                  placeholder="Select or type a date"
                  disabledDate={(current) =>
                    current && current < dayjs().startOf("day")
                  }
                />
              )}
              {currentlyInsured === true && newOrRenew == "renew" && (
                <CustomDatePicker
                  formItem={{
                    label:
                      "What is the current expiry date of your policy or the expected date of coverage required?",
                    required: true,
                  }}
                  control={control}
                  name="coverage_date"
                  placeholder="Select or type a date"
                  disabledDate={(current) =>
                    current && current < dayjs().startOf("day")
                  }
                />
              )}
            </Col>
          </Row>
          {navigationButtons}
        </Col>
      </Row>
    </Card>
  );
};
