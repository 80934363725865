import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  notifyApiCallFailed,
  showNotification,
} from "./notifications/notificationsSlice";

const baseUrl =
  process.env.REACT_APP_USE_LOCAL_API_URL === "true"
    ? process.env.REACT_APP_LOCAL_API_URL
    : process.env.REACT_APP_EXTERNAL_API_URL;
export const baseApi = createApi({
  reducerPath: "baseApi",
  baseQuery: async (args, api, extraOptions) => {
    try {
      const result = await fetchBaseQuery({
        baseUrl: `${baseUrl}/`,
      })(args, api, extraOptions);
      if (result.error) {
        api.dispatch(notifyApiCallFailed());
      } else if (result.data.success === false) {
        api.dispatch(
          showNotification({
            type: "error",
            message: "Error",
            description: (
              <div
                dangerouslySetInnerHTML={{
                  __html: result.data.errorMessage.replace(
                    /(?:\r\n|\r|\n)/g,
                    "<br />"
                  ),
                }}
              />
            ),
          })
        );
      } else if (
        api.endpoint === "saveApplication" &&
        api.getState().aviationForm.data.step == args.body.content.step
      ) {
        api.dispatch(
          showNotification({
            type: "success",
            message: "Saved",
            description: "Changes successfully saved!",
          })
        );
      }

      return result;
    } catch (e) {
      api.dispatch(notifyApiCallFailed());
    }
  },
  tagTypes: ["Application"],
  endpoints: (builder) => ({
    startApplication: builder.mutation({
      query: ({ isNew, id, email }) => ({
        url: "private_aviation_forms/start",
        method: "POST",
        body: { is_new: isNew, id: id, email: email },
      }),
      providesTags: ["Application"],
      invalidatesTags: ["Application"],
    }),
    fetchApplication: builder.mutation({
      query: ({ id }) => ({
        url: `private_aviation_forms/fetch`,
        method: "POST",
        body: { id: id },
      }),
      refetchOnMountOrArgChange: true,
    }),
    saveApplication: builder.mutation({
      query: ({ content }) => ({
        url: "private_aviation_forms/save",
        method: "POST",
        body: { content: content },
      }),
      providesTags: ["Application"],
      invalidatesTags: ["Application"],
    }),
  }),
});

export const {
  useVerifyApplicationMutation,
  useStartApplicationMutation,
  useFetchApplicationMutation,
  useSaveApplicationMutation,
} = baseApi;
