import dayjs from "dayjs";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoginStatus, setIsAuthenticated } from "../../store/user/userSlice";
import { logoutUser } from "../../store/user/userThunks";

export const useAuthStatus = () => {
  const dispatch = useDispatch();
  const status = useSelector((state) => state.user.status);
  const isLoggedIn = useSelector((state) => state.user.isAuthenticated);
  const admin = useSelector((state) => state.user.admin);
  const isAdmin = admin && isLoggedIn;

  const refreshAuthStatus = () => {
    const expiry = localStorage.getItem("expiry") ?? "0";
    const hasToken = localStorage.getItem("token");
    const isNonExpired = dayjs().unix() < Number(expiry);
    const isSuccess = status === LoginStatus.SUCCESS;
    // const hasToken = true;
    // const isNonExpired = true;
    // const isSuccess = true;
    if (hasToken && isNonExpired && isSuccess) {
      dispatch(setIsAuthenticated(true));
    } else if (hasToken || isSuccess) {
      dispatch(logoutUser());
    } else {
      dispatch(setIsAuthenticated(false));
    }
  };

  useEffect(() => {
    if (status != LoginStatus.PENDING) {
      refreshAuthStatus();
    }
  }, [status]);

  return { isLoggedIn, refreshAuthStatus, isAdmin };
};
