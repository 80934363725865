import { isArray } from "lodash";

export const parseItemizedErrorList = (showErrors) => (
  <div>
    <ul>
      {showErrors.map((item, index) => {
        if (isArray(item)) {
          return (
            <li>
              {/*style={{ listStyleType: "none" }}>*/}
              Pilot {(index + 1).toString()}:
              <ul>
                {item.map((i, idx) => {
                  return (
                    <li key={idx} style={{ color: "black" }}>
                      {i}
                    </li>
                  );
                })}
              </ul>
            </li>
          );
        } else {
          return <></>;
        }
      })}
    </ul>
  </div>
);
