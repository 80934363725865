import { Col, Flex, Layout, Row } from "antd";

const MainHeader = (props) => {
  return (
    <Layout.Header className="main-header">
      <Row>
        <Col flex="200px" style={{ marginRight: "0px" }}>
          <Flex
            align="center"
            justify="center"
            style={{ width: 200, height: 64 }}
          >
            <div className="air1-logo"></div>
          </Flex>
        </Col>
        <Col flex="auto">
          {props.children}
        </Col>
      </Row>
    </Layout.Header>
  );
};

export default MainHeader;
