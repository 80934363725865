import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Select, Space } from "antd";
import React, { memo, useMemo } from "react";
import {
  Controller,
  useFieldArray,
  useFormState,
  useWatch,
} from "react-hook-form";

export const MembershipTypes = {
  EAA: "EAA",
  COPA: "COPA",
  CENTURY_FLIGHT_CLUB: "CENTURY FLIGHT CLUB",
  OTHER: "Other",
};

export class Membership {
  constructor(number = "", type = null) {
    this.number = number;
    this.type = type;
  }
}

export const MembershipNumbers = (props) => {
  const { control, index } = props;
  const { fields, append, remove } = useFieldArray({
    control: control,
    name: `pilots[${index}].membership_numbers`,
  });

  const { errors } = useFormState({
    control: control,
    name: `pilots[${index}].membership_numbers`,
  });

  const membershipNumbers = useWatch({
    control: control,
    name: `pilots[${index}].membership_numbers`,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const disabledValuesList = membershipNumbers
    ? membershipNumbers.map((e) => e.type)
    : [];

  const options = useMemo(
    () => [
      {
        value: MembershipTypes.EAA,
        disabled: disabledValuesList.includes(MembershipTypes.EAA),
        label: "EAA",
      },
      {
        value: MembershipTypes.COPA,
        disabled: disabledValuesList.includes(MembershipTypes.COPA),
        label: "COPA",
      },
      {
        value: MembershipTypes.OTHER,
        disabled: disabledValuesList.includes(MembershipTypes.OTHER),
        label: "Other",
      },
    ],
    [disabledValuesList]
  );

  return (
    <Content
      {...props}
      fields={fields}
      options={options}
      append={append}
      remove={remove}
      errors={errors}
    />
  );
};

const Content = memo((props) => {
  const { fields, control, append, remove, index: pilotIndex } = props;

  return (
    <Form.Item
      name="memberships"
      label="Memberships"
      required={false}
      style={{ marginBottom: 0 }}
    >
      {fields.map((item, index) => (
        <div key={item.id}>
          <Row>
            <Col md={14}>
              <Form.Item
                name={`pilots[${pilotIndex}].membership_numbers[${index}]`}
                validateStatus={
                  props.errors?.pilots != null &&
                  props.errors.pilots[pilotIndex] != null &&
                  props.errors.pilots[pilotIndex].membership_numbers != null &&
                  props.errors.pilots[pilotIndex].membership_numbers[index] !=
                    null
                    ? "error"
                    : ""
                }
                help={
                  props.errors?.pilots != null &&
                  props.errors.pilots[pilotIndex] != null &&
                  props.errors.pilots[pilotIndex].membership_numbers != null &&
                  props.errors.pilots[pilotIndex].membership_numbers[index] !=
                    null
                    ? props.errors.pilots[pilotIndex].membership_numbers[index]
                        ?.type?.message ??
                      props.errors.pilots[pilotIndex].membership_numbers[index]
                        ?.number?.message ??
                      ""
                    : ""
                }
              >
                <div>
                  <Controller
                    control={control}
                    name={`pilots[${pilotIndex}].membership_numbers[${index}].number`}
                    render={({ field }) => (
                      <Space.Compact block>
                        <Controller
                          control={control}
                          name={`pilots[${pilotIndex}].membership_numbers[${index}].type`}
                          render={({ field: typeFields }) => (
                            <Select
                              name={`pilots[${pilotIndex}].membership_numbers[${index}].type`}
                              style={{ minWidth: 200, maxWidth: 200 }}
                              options={props.options}
                              placeholder="Type"
                              {...typeFields}
                            />
                          )}
                        />
                        <Input placeholder="Membership Number" {...field} />
                      </Space.Compact>
                    )}
                  />
                </div>
              </Form.Item>
            </Col>
            <Col>
              <Space.Compact>
                {fields.length > 1 && (
                  <Button
                    type="link"
                    icon={<MinusCircleOutlined />}
                    className="link-button-primary"
                    onClick={() => {
                      remove(index);
                    }}
                  />
                )}
                {fields.length < 4 && fields.length - 1 === index && (
                  <Button
                    type="link"
                    icon={<PlusCircleOutlined />}
                    className="link-button-primary"
                    disabled={
                      props.errors?.membership_numbers != null &&
                      props.errors?.membership_numbers[index] != null
                    }
                    onClick={async () => {
                      var val = props.getValues(
                        `pilots[${pilotIndex}].membership_numbers`
                      );
                      const lastValues = val.at(-1);
                      if (!(!lastValues.number && !lastValues.type)) {
                        append(new Membership("", null));
                      }
                    }}
                  />
                )}
              </Space.Compact>
            </Col>
          </Row>
        </div>
      ))}
    </Form.Item>
  );
});
