import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card, Col, Modal, Row } from "antd";
import { useEffect, useState } from "react";
import { useFieldArray } from "react-hook-form";
import { CustomInput } from "../../../shared/formFields/CustomInput";
import OptionForm from "./OptionForm";
import { isNumeric } from "../../../shared/InputCurrency";

const toCurrency = (value, fixed = 2) =>
  `CA$ ${parseFloat(value).toFixed(fixed)}`.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ","
  );

const emptyOption = {
  header: "Option",
  agreed_hull_value: null,
  third_party_liability_coverage_option: null,
  third_party_liability_coverage_amount: null,
  passenger_liability_coverage_option: null,
  passenger_liability_coverage_amount: null,
  premises_liability_option: null,
  premises_liability_amount: null,
  deductible: null,
  premium: null,
  annual_account_fee: 200,
};

const QuoteOptions = ({ control, getValues, setFocus }) => {
  const { fields, append, remove, update } = useFieldArray({
    control: control,
    name: "options",
  });

  const [openModalIndex, setOpenModalIndex] = useState(-1);
  const [currentTitleIndex, setCurrentTitleIndex] = useState(-1);

  const showModal = (index) => {
    setOpenModalIndex(index);
  };

  const handleOk = () => {
    update(openModalIndex, getValues().options[openModalIndex]);
    setOpenModalIndex(-1);
  };

  const handleCancel = () => {
    setOpenModalIndex(-1);
  };

  useEffect(() => {
    if (currentTitleIndex != -1) {
      setFocus(`options[${currentTitleIndex}].header`);
    }
  }, [currentTitleIndex]);
  return (
    <div>
      <Row gutter={[8, 8]} justify={"start"}>
        {fields.map((item, index) => (
          <Col flex="325px">
            <div key={item.id}>
              <Card
                type="inner"
                title={
                  currentTitleIndex != index ? (
                    <div
                      id="editable-cell"
                      style={{
                        padding: "4px 11px",
                        textOverflow: "ellipsis",
                        maxWidth: "212px",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                      onClick={() => {
                        setCurrentTitleIndex(index);
                        setFocus(`options[${index}].header`);
                      }}
                    >
                      {item.header}
                    </div>
                  ) : (
                    <CustomInput
                      formItem={{
                        required: false,
                        label: "",
                        className: "quote-title-input",
                      }}
                      control={control}
                      name={`options[${index}].header`}
                      placeholder="Type here"
                      onBlur={() => {
                        var currentValues =
                          getValues().options[currentTitleIndex];
                        update(currentTitleIndex, {
                          ...currentValues,
                          header: !currentValues.header
                            ? "Option #" + (currentTitleIndex + 1).toString()
                            : currentValues.header,
                        });
                        setCurrentTitleIndex(-1);
                      }}
                    />
                  )
                }
                size="small"
                extra={[
                  <Button
                    type="link"
                    onClick={() => {
                      if (currentTitleIndex != -1) {
                        var currentValues =
                          getValues().options[currentTitleIndex];
                        update(currentTitleIndex, {
                          ...currentValues,
                          header: currentValues?.header ?? "Option #1",
                        });
                        setCurrentTitleIndex(-1);
                      }
                      showModal(index);
                    }}
                  >
                    <EditOutlined /> Edit
                  </Button>,
                  fields.length > 1 ? (
                    <Button
                      danger
                      type="link"
                      style={{ paddingRight: 0, paddingLeft: 0 }}
                      onClick={() => remove(index)}
                      disabled={fields.length <= 1}
                    >
                      <DeleteOutlined />
                    </Button>
                  ) : (
                    <></>
                  ),
                ]}
              >
                <Row justify={"space-between"}>
                  <Col>Agreed Hull Value</Col>
                  <Col>
                    {item.agreed_hull_value
                      ? toCurrency(item.agreed_hull_value, 0)
                      : "-"}
                  </Col>
                </Row>
                <Row justify={"space-between"}>
                  <Col>3rd Party Liability</Col>
                  <Col>
                    {item.third_party_liability_coverage_amount
                      ? toCurrency(
                          item.third_party_liability_coverage_amount,
                          0
                        )
                      : item.third_party_liability_coverage_option ?? "-"}
                  </Col>
                </Row>
                <Row justify={"space-between"}>
                  <Col>Passenger Liability</Col>
                  <Col>
                    {item.passenger_liability_coverage_amount
                      ? toCurrency(item.passenger_liability_coverage_amount, 0)
                      : item.passenger_liability_coverage_option ?? "-"}
                  </Col>
                </Row>
                <Row justify={"space-between"}>
                  <Col>Premises Liability</Col>
                  <Col>
                    {item.premises_liability_amount
                      ? toCurrency(item.premises_liability_amount, 0)
                      : item.premises_liability_option ?? "-"}
                  </Col>
                </Row>
                <Row justify={"space-between"}>
                  <Col>Deductible</Col>
                  <Col>
                    {item.deductible
                      ? isNumeric(item.deductible)
                        ? `CA$ ${parseFloat(item.deductible).toFixed(
                            2
                          )}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : item.deductible
                      : "-"}
                  </Col>
                </Row>
                <Row justify={"space-between"}>
                  <Col>Premium</Col>
                  <Col>{item.premium ? toCurrency(item.premium) : "-"}</Col>
                </Row>
                <Row justify={"space-between"}>
                  <Col>Annual Account Fee</Col>
                  <Col>
                    {item.annual_account_fee
                      ? toCurrency(item.annual_account_fee)
                      : "-"}
                  </Col>
                </Row>
              </Card>
            </div>
          </Col>
        ))}

        {fields.length < 3 && (
          <Col flex="100px">
            <Button
              type="link"
              style={{ width: "100%", height: 217 }}
              disabled={fields.length >= 3}
              onClick={() => {
                append({
                  ...emptyOption,
                  header: `Option #${fields.length + 1}`,
                });
              }}
            >
              <PlusOutlined /> Add option
            </Button>
          </Col>
        )}
      </Row>

      <Modal
        title={
          openModalIndex < 0
            ? `Option #${openModalIndex + 1}`
            : getValues().options[openModalIndex]?.header ??
              `Option #${openModalIndex + 1}`
          // <div>
          //   Header
          //   <CustomInput
          //     formItem={{
          //       required: false,
          //       label: "",
          //       className: "reduced-label-padding",
          //     }}
          //     size="medium"
          //     control={control}
          //     name={`options[${openModalIndex}].header`}
          //     placeholder="Type here"
          //   />
          // </div>
        }
        open={openModalIndex >= 0}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Done"
        cancelText=""
        maskClosable={false}
        mask={true}
        destroyOnClose={true}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <OptionForm index={openModalIndex} />
      </Modal>
    </div>
  );
};

export default QuoteOptions;
