import { Col, Form, InputNumber, Row, Select, Space } from "antd";
import React from "react";

import { Controller, useFormState } from "react-hook-form";
import { trip_duration_options } from "../../../../shared/constants";

export const TripDurationField = (props) => {
  const { control, isXs, trigger } = props;

  const selectName = `trip_duration_select`;
  const amountName = `trip_duration_amount`;

  const { errors } = useFormState({
    control: control,
    name: [selectName, amountName],
    exact: true,
  });

  return (
    <Form.Item
      name={"trip_duration"}
      validateStatus={
        errors != null &&
        (errors[selectName] != null || errors[amountName] != null)
          ? "error"
          : ""
      }
      help={
        errors == null
          ? ""
          : errors[selectName]?.message ?? errors[amountName]?.message
      }
      required={false}
      label="What is the longest duration of any of the trips you will be taking outside of canada within the 12 month period?"
      className="increased-xs-margin"
      style={!isXs ? null : { marginBottom: "60px" }}
    >
      <Row>
        <Col Col md={14}>
          <Space.Compact block>
            <Controller
              control={control}
              name={selectName}
              render={({ field }) => {
                return (
                  <div>
                    <Select
                      name={selectName}
                      options={trip_duration_options}
                      {...field}
                      style={{ minWidth: 125, maxWidth: 150 }}
                      placeholder={"Months/Days"}
                    />
                  </div>
                );
              }}
            />
            <Controller
              control={control}
              name={amountName}
              render={({ field }) => {
                return (
                  <InputNumber
                    name={amountName}
                    style={{ width: 90 }}
                    {...field}
                    placeholder={"Duration"}
                    controls={false}
                    onChange={(v) => {
                      field.onChange(v);
                      if (!v) {
                        trigger("trip_duration_select");
                      }
                    }}
                  />
                );
              }}
            />
          </Space.Compact>
        </Col>
      </Row>
    </Form.Item>
  );
};
