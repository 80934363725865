import { Breadcrumb, Col, Layout, Row, Typography } from "antd";
import { usePathname } from "../../../shared/usePathname";
import { privatePagesMap } from "../router/privatePagesMap";

const getBreadcrumbItems = (subpaths) => {
  const items = [];
  for (var i = 0; i < Math.min(subpaths.length, 2); i++) {
    const item = privatePagesMap[subpaths[i]];
    items.push({
      title: (
        <>
          {item.icon != null && item.icon}
          <span>{item.name ?? item.label}</span>
        </>
      ),
    });
  }
  return items;
};

const SubHeader = () => {
  const { subpaths } = usePathname();

  const currentPage = subpaths[Math.min(subpaths.length - 1, 1)];
  const pageName =
    privatePagesMap[currentPage] != null
      ? privatePagesMap[currentPage].name ?? privatePagesMap[currentPage].label
      : "Not Found";

  return (
    <Layout.Header className="body-header">
      <>
        <Row>
          <Col>
            <Breadcrumb
              style={{ marginTop: 16 }}
              items={getBreadcrumbItems(subpaths)}
            >
              {/* <Breadcrumb.Item>Home</Breadcrumb.Item>
              <Breadcrumb.Item>Private Aviation Application</Breadcrumb.Item> */}
            </Breadcrumb>
          </Col>
        </Row>
        <Row>
          <Col>
            <Typography>
              <Typography.Title
                level={4}
                style={{ marginTop: 16, marginBottom: 16 }}
              >
                {pageName}
                {/* {currentPage != null ? (
                      <div>
                        {currentPage.icon} {currentPage?.name ?? "Unknown"}
                      </div>
                    ) 
                    
                    : (
                      <div>
                        <QuestionCircleOutlined />{" "}
                        {currentPage?.name ?? "Unknown"}
                      </div>
                    )} */}
              </Typography.Title>
            </Typography>
          </Col>
        </Row>
      </>
    </Layout.Header>
  );
};

export default SubHeader;
