const constructErrorList = (errors) => {
  let list = [];
  for (const key in errors) {
    if (errors.hasOwnProperty(key)) {
      const error = errors[key];
      if (!error.message && error.length != null) {
        for (var i = 0; i < error.length; i++) {
          if (error[i] != null) {
            let newList = constructErrorList(error[i]);
            list = [...list, newList];
          }
        }
      } else if (
        error.message &&
        typeof error.message === "string" &&
        list.indexOf(error.message) === -1
      ) {
        list.push(error.message);
      }
    }
  }

  return list;
};

export const getFormErrorsList = (errors) => {
  let list = [];
  list = constructErrorList(errors);
  return list;
};
