import { createSlice } from "@reduxjs/toolkit";
import { searchApplications } from "./applicationsSearchThunks";

const initialState = {
  isLoading: false,
  data: [],
};

export const applicationsSearch = createSlice({
  name: "applicationsSearch",
  initialState,
  reducers: {
    clearApplicationsSearch: {
      reducer(state) {
        state.isLoading = false;
        state.data = [];
      },
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchApplications.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(searchApplications.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        if (payload.data.success === true) {
          state.data = payload.data.content;
        } else {
          state.data = [];
        }
      })
      .addCase(searchApplications.rejected, (state) => {
        state.isLoading = false;
        state.data = [];
      });
  },
});

export const { clearApplicationsSearch } = applicationsSearch.actions;

export default applicationsSearch.reducer;
