import { Alert, Button, Col, ConfigProvider, Layout, Row, Space, Tag } from "antd";
import { Outlet, useNavigate } from "react-router-dom";
import { mainTheme, outerLayersTheme } from "../../../shared/themes";
import AuthenticatedHeaderDropdown from "./AuthenticatedHeaderDropdown";
import LayoutFooter from "./LayoutFooter";
import MainHeader from "./MainHeader";
import NotificationsWrapper from "./NotificationsWrapper";
import UnauthenticatedHeader from "./UnauthenticatedHeader";
import { useAuthStatus } from "../useAuthStatus";
import { SelectOutlined } from "@ant-design/icons";

const PublicLayout = ({ isDirectParent, children }) => {
  const navigate = useNavigate();
  const { isLoggedIn } = useAuthStatus();

  return (
    <div>
      <ConfigProvider theme={mainTheme}>
        <NotificationsWrapper>
          <Layout className="layout">
            <ConfigProvider theme={outerLayersTheme}>
              <MainHeader>
                {isDirectParent ? (
                  <></>
                ) : isLoggedIn ? (
                  <Row justify="end">
                    {process.env.REACT_APP_SHOW_ENVIRONMENT_FLAG !==
                      "false" && (
                      <Col>
                        <Row>
                          <Col
                            style={{ marginRight: "16px" }}
                            xs={0}
                            sm={0}
                            md={0}
                            lg={24}
                          >
                            <Space align="center">
                              <Alert
                                message={`${process.env.REACT_APP_SHOW_ENVIRONMENT_FLAG}`}
                                type="info"
                                showIcon
                              />
                            </Space>
                          </Col>
                        </Row>
                      </Col>
                    )}
                    <Col>
                      <Row>
                        <Col
                          xs={0}
                          sm={0}
                          md={24}
                          style={{ marginRight: "16px" }}
                        >
                          <Button
                            onClick={() =>
                              navigate("/all-forms/private-aviation")
                            }
                          >
                            <SelectOutlined />
                            Private Aviation Application
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                    <Col style={{ marginRight: "16px" }}>
                      <AuthenticatedHeaderDropdown canOpenPanel={true} />
                    </Col>
                  </Row>
                ) : (
                  <UnauthenticatedHeader />
                )}
              </MainHeader>
            </ConfigProvider>
            <Layout className="body-layout-parent">
              <div className="scroll-body-layout">
                <div
                  style={{
                    background: "#f5f5f5",
                    flex: 1,
                  }}
                >
                  {isDirectParent === true ? children : <Outlet />}
                </div>
                <LayoutFooter />
              </div>
            </Layout>
          </Layout>
        </NotificationsWrapper>
      </ConfigProvider>
    </div>
  );
};

export default PublicLayout;
