import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import dayjs from "dayjs";
import { logoutUser } from "../user/userThunks";
import { notifyApiCallFailed } from "../notifications/notificationsSlice";

export const searchApplications = createAsyncThunk(
  "applicationsSearch/search",
  async ({ search_type, search_criteria, date_range }, api) => {
    const baseUrl =
      process.env.REACT_APP_USE_LOCAL_API_URL === "true"
        ? process.env.REACT_APP_LOCAL_API_URL
        : process.env.REACT_APP_EXTERNAL_API_URL;
    try {
      let config = {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      };
      const response = await axios.post(
        baseUrl + "/private_aviation_forms/list",
        {
          search_type: search_type,
          search_criteria: search_criteria,
          start_day: dayjs(date_range[0]).format("YYYY-MM-DD"),
          end_day: dayjs(date_range[1]).format("YYYY-MM-DD"),
        },
        config
      );

      return response;
    } catch (error) {
      if (error?.response?.status === 403 || error?.response?.status === 401) {
        // User is not authorized
        api.dispatch(logoutUser());
        return api.rejectWithValue(null);
      } else {
        api.dispatch(notifyApiCallFailed());
        return api.rejectWithValue(null);
      }
    }
  }
);
