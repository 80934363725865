import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import AppRoutes from "./components/navigation/router/AppRoutes";
import configuredStore from "./store/configuredStore";
import { Spin } from "antd";
import { ErrorBoundary } from "react-error-boundary";
import CustomErrorBoundary from "./components/navigation/CustomErrorBoundary";
import { App as Application } from "antd";

const { store, persistor } = configuredStore();

const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <PersistGate
          loading={
            <Spin
              spinning={true}
              tip="Please wait for a moment..."
              fullscreen
              size="large"
            />
          }
          persistor={persistor}
        >
          <ErrorBoundary FallbackComponent={CustomErrorBoundary}>
            <Application>
              <AppRoutes />
            </Application>
          </ErrorBoundary>
        </PersistGate>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
