import { ExclamationCircleTwoTone } from "@ant-design/icons";
import { Col, Row } from "antd";
import { useFormContext, useFormState } from "react-hook-form";

export const LiabilityErrorMessage = () => {
  const { control } = useFormContext();
  const { errors, isSubmitted } = useFormState({
    control: control,
    name: "liability_validation",
    exact: true,
  });
  const showError = errors?.liability_validation && isSubmitted;

  return (
    <div>
      {showError && (
        <Row>
          <Col>
            <ExclamationCircleTwoTone twoToneColor={"#FF4D4F"} />
            <span style={{ color: "#FF4D4F" }}>
              {" "}
              Please select at least 1 liability coverage option
            </span>
          </Col>
        </Row>
      )}
    </div>
  );
};
