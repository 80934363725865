import { UserOutlined } from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Button, Card, Col, Flex, Form, Row, Tabs } from "antd";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { CustomInput } from "../../shared/formFields/CustomInput";
import { sendResetInstructions } from "../../store/user/userThunks";

const forgotPasswordFormSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required("Email is required")
    .email("Email invalid"),
});

const ForgotPassword = () => {
  const {
    handleSubmit,
    control,
    getValues,
    formState: { isSubmitted, isSubmitSuccessful },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      email: "",
    },
    resolver: yupResolver(forgotPasswordFormSchema),
  });

  return isSubmitted && isSubmitSuccessful ? (
    <Alert
      message={
        <div>
          Password reset instructions have been sent to your email (
          {getValues().email}).
        </div>
      }
      type="success"
      showIcon
      className="default-margin"
    />
  ) : (
    <Flex justify="center" align="center" className="expanded-height" vertical>
      <Card style={{ minWidth: 350 }}>
        <Tabs
          defaultActiveKey="1"
          centered
          items={[
            {
              label: `Air1 Insurance - Forgot Password`,
              key: "1",
              children: (
                <ForgotPasswordForm
                  handleSubmit={handleSubmit}
                  control={control}
                />
              ),
            },
          ]}
        />
      </Card>
    </Flex>
  );
};

const ForgotPasswordForm = ({ handleSubmit, control }) => {
  //
  const dispatch = useDispatch();

  const onSubmit = (values) => {
    dispatch(
      sendResetInstructions({
        email: values.email,
      })
    );
  };

  return (
    <Form
      name="login_form"
      autoComplete="off"
      onFinish={() => handleSubmit(onSubmit)()}
      style={{ marginTop: 16, marginBottom: 14 }}
    >
      <Row style={{ marginBottom: 12 }}>
        <Col>Please enter your email below to reset your password:</Col>
      </Row>

      <CustomInput
        formItem={{ required: true }}
        control={control}
        name="email"
        placeholder="Email"
        prefix={<UserOutlined />}
      />

      <Row>
        <Col span={24}>
          <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
            Reset Password
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ForgotPassword;
