import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

async function deferRender() {
  if (
    process.env.NODE_ENV !== "development" ||
    process.env.REACT_APP_USE_MOCK_SERVICE !== "true"
  ) {
    return;
  }

  const { worker } = await import("./mocks/browser");

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return worker.start();
}

deferRender().then(() =>
  root.render(
    // <React.StrictMode>
      <App />
    // </React.StrictMode>
  )
);
