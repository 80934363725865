import { createSlice } from "@reduxjs/toolkit";
import { baseApi } from "../baseApi";

const initialState = {
  isSaving: false,
  data: {
    step: 0,
  },
};

export const aviationForm = createSlice({
  name: "aviationForm",
  initialState,
  reducers: {
    clearAviationForm: {
      reducer(state) {
        state.isSaving = false;
        state.data = { step: 0 };
      },
    },
    navigateToPreviousPage: {
      reducer(state, action) {
        if (action.payload != null && !isNaN(action.payload)) {
          state.data.step = Math.max(0, action.payload);
        } else {
          state.data.step = Math.max(0, (state.data.step ?? 0) - 1);
        }
      },
    },
    setShowPreface: {
      reducer(state, action) {
        const { showPreface, data } = action.payload;
        state.data = { ...state.data, ...data };
        state.data.showPreface = showPreface;
      },
      prepare({ showPreface, data }) {
        return {
          payload: { showPreface, data },
        };
      },
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      baseApi.endpoints.fetchApplication.matchFulfilled,
      (state, { payload }) => {
        if (payload.content) {
          state.data = {
            ...payload.content,
            showPreface: payload.content.currently_insured == null ? 1 : null,
          };
        }
      }
    );
    builder.addMatcher(
      baseApi.endpoints.saveApplication.matchPending,
      (state) => {
        state.isSaving = true;
      }
    );
    builder.addMatcher(
      baseApi.endpoints.saveApplication.matchRejected,
      (state) => {
        state.isSaving = false;
      }
    );
    builder.addMatcher(
      baseApi.endpoints.saveApplication.matchFulfilled,
      (state, { payload }) => {
        state.isSaving = false;
        if (payload.success === true) {
          state.data = payload.content;
        }
      }
    );
  },
});

export const { clearAviationForm, navigateToPreviousPage, setShowPreface } =
  aviationForm.actions;

export default aviationForm.reducer;
