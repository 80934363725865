import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { HttpStatusCode } from "axios";
import dayjs from "dayjs";
import {
  notifyApiCallFailed,
  showNotification,
} from "../notifications/notificationsSlice";

export const logoutUser = createAsyncThunk("user/logout", async (_, api) => {
  const baseUrl =
    process.env.REACT_APP_USE_LOCAL_API_URL === "true"
      ? process.env.REACT_APP_LOCAL_API_URL
      : process.env.REACT_APP_EXTERNAL_API_URL;
  let config = {
    headers: {
      Authorization: localStorage.getItem("token"),
    },
  };
  localStorage.removeItem("token");
  localStorage.removeItem("expiry");
  try {
    await axios.delete(baseUrl + "/users/sign_out", config);
  } catch (_) {}
  return api.fulfillWithValue(true);
});

export const loginUser = createAsyncThunk("user/login", async (user, api) => {
  const baseUrl =
    process.env.REACT_APP_USE_LOCAL_API_URL === "true"
      ? process.env.REACT_APP_LOCAL_API_URL
      : process.env.REACT_APP_EXTERNAL_API_URL;
  try {
    const response = await axios.post(baseUrl + "/users/sign_in", {
      user: user,
    });

    const token = response?.headers?.getAuthorization();
    if (token) {
      const expiry = dayjs().add(12, "hour").unix().toString();
      localStorage.setItem("token", token);
      localStorage.setItem("expiry", expiry);

      return response.data;
    }
    api.dispatch(notifyApiCallFailed());
    return api.rejectWithValue(null);
  } catch (error) {
    if (error?.response?.status === 401) {
      const message =
        error.response.data?.errors?.[0]?.message ?? "Something went wrong!";
      api.dispatch(
        showNotification({
          type: "error",
          message: "Error",
          description: (
            <div
              dangerouslySetInnerHTML={{
                __html: message.replace(/(?:\r\n|\r|\n)/g, "<br />"),
              }}
            />
          ),
        })
      );
      return api.rejectWithValue(message);
    } else {
      api.dispatch(notifyApiCallFailed());
      return api.rejectWithValue(null);
    }
  }
});

export const verifyResetPasswordToken = createAsyncThunk(
  "user/verifyToken",
  async ({ reset_password_token }, api) => {
    const baseUrl =
      process.env.REACT_APP_USE_LOCAL_API_URL === "true"
        ? process.env.REACT_APP_LOCAL_API_URL
        : process.env.REACT_APP_EXTERNAL_API_URL;
    try {
      const response = await axios.get(baseUrl + "/users/password/edit", {
        params: {
          reset_password_token: reset_password_token,
        },
      });
      if (response.status === HttpStatusCode.Ok) {
        return response.data;
      }
      throw new Error();
    } catch (error) {
      return api.rejectWithValue(null);
    }
  }
);

export const verifyConfirmationToken = createAsyncThunk(
  "user/verifyConfToken",
  async ({ confirmation_token }, api) => {
    const baseUrl =
      process.env.REACT_APP_USE_LOCAL_API_URL === "true"
        ? process.env.REACT_APP_LOCAL_API_URL
        : process.env.REACT_APP_EXTERNAL_API_URL;
    try {
      const response = await axios.get(baseUrl + "/users/confirmation", {
        params: {
          confirmation_token: confirmation_token,
        },
      });
      if (response.status === HttpStatusCode.Ok) {
        return response.data;
      }
      throw new Error();
    } catch (error) {
      return api.rejectWithValue(null);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "user/resetPassword",
  async ({ password, reset_password_token }, api) => {
    const baseUrl =
      process.env.REACT_APP_USE_LOCAL_API_URL === "true"
        ? process.env.REACT_APP_LOCAL_API_URL
        : process.env.REACT_APP_EXTERNAL_API_URL;
    try {
      const response = await axios.patch(`${baseUrl}/users/password`, {
        user: {
          reset_password_token: reset_password_token,
          password: password,
        },
      });

      if (response.status === HttpStatusCode.Ok) {
        return response.data;
      }
      throw new Error();
    } catch (error) {
      api.dispatch(notifyApiCallFailed());
      return api.rejectWithValue(null);
    }
  }
);

export const sendResetInstructions = createAsyncThunk(
  "user/sendResetInstructions",
  async ({ email }, api) => {
    const baseUrl =
      process.env.REACT_APP_USE_LOCAL_API_URL === "true"
        ? process.env.REACT_APP_LOCAL_API_URL
        : process.env.REACT_APP_EXTERNAL_API_URL;
    try {
      const response = await axios.post(`${baseUrl}/users/password`, {
        user: {
          email: email,
        },
      });
      if (response.status === HttpStatusCode.Ok) {
        return response.data;
      }
      throw new Error();
    } catch (error) {
      return api.rejectWithValue(null);
    }
  }
);

export const fetchUsersList = createAsyncThunk("user/list", async (_, api) => {
  const baseUrl =
    process.env.REACT_APP_USE_LOCAL_API_URL === "true"
      ? process.env.REACT_APP_LOCAL_API_URL
      : process.env.REACT_APP_EXTERNAL_API_URL;
  try {
    let config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    };
    const response = await axios.get(`${baseUrl}/users`, config);
    if (response.status === HttpStatusCode.Ok) {
      return response.data;
    }
    throw new Error();
  } catch (error) {
    api.dispatch(notifyApiCallFailed());
    return api.rejectWithValue(null);
  }
});

export const fetchUserDetails = createAsyncThunk(
  "user/get",
  async ({ id }, api) => {
    const baseUrl =
      process.env.REACT_APP_USE_LOCAL_API_URL === "true"
        ? process.env.REACT_APP_LOCAL_API_URL
        : process.env.REACT_APP_EXTERNAL_API_URL;
    try {
      let config = {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      };
      const response = await axios.get(`${baseUrl}/users/${id}/edit`, config);
      if (response.status === HttpStatusCode.Ok) {
        return response.data;
      }
      throw new Error();
    } catch (error) {
      api.dispatch(notifyApiCallFailed());
      return api.rejectWithValue(null);
    }
  }
);

export const disableUser = createAsyncThunk(
  "user/disable",
  async ({ id }, api) => {
    const baseUrl =
      process.env.REACT_APP_USE_LOCAL_API_URL === "true"
        ? process.env.REACT_APP_LOCAL_API_URL
        : process.env.REACT_APP_EXTERNAL_API_URL;
    try {
      let config = {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      };

      const response = await axios.post(
        `${baseUrl}/users/${id}/disable`,
        null,
        config
      );
      if (response.status === HttpStatusCode.Ok) {
        api.dispatch(
          showNotification({
            type: "success",
            message: "Disable account",
            description: `${
              response.data.name ?? ""
            }'s account successfully disabled!`,
          })
        );
        return response.data;
      }
      throw new Error();
    } catch (error) {
      api.dispatch(notifyApiCallFailed());
      return api.rejectWithValue(null);
    }
  }
);

export const enableUser = createAsyncThunk(
  "user/enable",
  async ({ id }, api) => {
    const baseUrl =
      process.env.REACT_APP_USE_LOCAL_API_URL === "true"
        ? process.env.REACT_APP_LOCAL_API_URL
        : process.env.REACT_APP_EXTERNAL_API_URL;
    try {
      let config = {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      };

      const response = await axios.post(
        `${baseUrl}/users/${id}/enable`,
        null,
        config
      );

      if (response.status === HttpStatusCode.Ok) {
        api.dispatch(
          showNotification({
            type: "success",
            message: "Enable account",
            description: `${
              response.data.name ?? ""
            }'s account successfully enabled!`,
          })
        );
        return response.data;
      }
      throw new Error();
    } catch (error) {
      api.dispatch(notifyApiCallFailed());
      return api.rejectWithValue(null);
    }
  }
);

export const resendConfirmationInstructions = createAsyncThunk(
  "user/resendConfirmationInstructions",
  async ({ id }, api) => {
    const baseUrl =
      process.env.REACT_APP_USE_LOCAL_API_URL === "true"
        ? process.env.REACT_APP_LOCAL_API_URL
        : process.env.REACT_APP_EXTERNAL_API_URL;
    try {
      let config = {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      };

      const response = await axios.post(
        `${baseUrl}/users/${id}/resend_confirmation`,
        null,
        config
      );

      if (response.status === HttpStatusCode.Ok) {
        api.dispatch(
          showNotification({
            type: "success",
            message: "Done",
            description: response.data.message ?? "",
          })
        );
        return response.data;
      }
      throw new Error();
    } catch (error) {
      api.dispatch(notifyApiCallFailed());
      return api.rejectWithValue(null);
    }
  }
);

export const sendResetPasswordInstructions = createAsyncThunk(
  "user/sendResetPasswordInstructions",
  async ({ id }, api) => {
    const baseUrl =
      process.env.REACT_APP_USE_LOCAL_API_URL === "true"
        ? process.env.REACT_APP_LOCAL_API_URL
        : process.env.REACT_APP_EXTERNAL_API_URL;
    try {
      let config = {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      };

      const response = await axios.post(
        `${baseUrl}/users/${id}/send_reset_password_instructions`,
        null,
        config
      );

      if (response.status === HttpStatusCode.Ok) {
        api.dispatch(
          showNotification({
            type: "success",
            message: "Done",
            description: response.data.message ?? "",
          })
        );
        return response.data;
      }
      throw new Error();
    } catch (error) {
      api.dispatch(notifyApiCallFailed());
      return api.rejectWithValue(null);
    }
  }
);

export const createUser = createAsyncThunk(
  "user/createUser",
  async (data, api) => {
    const baseUrl =
      process.env.REACT_APP_USE_LOCAL_API_URL === "true"
        ? process.env.REACT_APP_LOCAL_API_URL
        : process.env.REACT_APP_EXTERNAL_API_URL;
    try {
      let config = {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      };

      const response = await axios.post(
        `${baseUrl}/users`,
        { user: data },
        config
      );

      if (response.status === HttpStatusCode.Ok) {
        api.dispatch(
          showNotification({
            type: "success",
            message: "Add User",
            description: "User successfully created!",
          })
        );
        return response.data;
      }
      throw new Error();
    } catch (error) {
      api.dispatch(notifyApiCallFailed());
      return api.rejectWithValue(null);
    }
  }
);

export const updateUser = createAsyncThunk(
  "user/updateUser",
  async ({ id, user }, api) => {
    const baseUrl =
      process.env.REACT_APP_USE_LOCAL_API_URL === "true"
        ? process.env.REACT_APP_LOCAL_API_URL
        : process.env.REACT_APP_EXTERNAL_API_URL;
    try {
      let config = {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      };

      const response = await axios.patch(
        `${baseUrl}/users/${id}`,
        { user: user },
        config
      );

      if (response.status === HttpStatusCode.Ok) {
        api.dispatch(
          showNotification({
            type: "success",
            message: "Edit User",
            description: "User successfully updated!",
          })
        );
        return response.data;
      }
      throw new Error();
    } catch (error) {
      api.dispatch(notifyApiCallFailed());
      return api.rejectWithValue(null);
    }
  }
);
