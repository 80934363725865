import { Layout } from "antd";

const LayoutFooter = () => {
  return (
    <Layout.Footer style={{ textAlign: "center" }}>
      Air1 Insurance ©2024 Created by Fideli Software Inc. (v1.0.17 11-19-2024)
    </Layout.Footer>
  );
};

export default LayoutFooter;
