import { PlusOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Input, Row, Tag, Tooltip } from "antd";
import { useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { canadian_provinces } from "../../../../../shared/constants";
import { CustomInput } from "../../../../../shared/formFields/CustomInput";
import { CustomSelectField } from "../../../../../shared/formFields/CustomSelectField";
import { PhoneTypeAndNumberInput } from "../../formFields/PhoneTypeAndNumberInput";
import { PostalCodeInput } from "../../formFields/PostalCodeInput";
const ApplicantsInformation = () => {
  const { control, trigger, watch, setValue } = useFormContext();

  const tags = watch("additional_owner_names");
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  const handleClose = (removedTag) => {
    const newTags = tags?.filter((tag) => tag !== removedTag) ?? [];
    setValue("additional_owner_names", newTags, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue && !tags?.includes(inputValue)) {
      var currentTags = tags ?? [];
      setValue("additional_owner_names", [...currentTags, inputValue], {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
    setInputVisible(false);
    setInputValue("");
  };

  return (
    <div>
      <Card className="form-card-style" bordered={false}>
        <Row gutter={12}>
          <Col xs={24} sm={12} md={10}>
            <CustomInput
              formItem={{
                required: true,
                label: "Named Insured (Registered Owner of Aircraft)",
              }}
              // style={{maxWidth: 300}}
              control={control}
              name="main_owner_name"
              placeholder="Enter full name"
            />
          </Col>
          <Col xs={24} sm={12} md={7}>
            <CustomInput
              formItem={{
                required: true,
                label: "Occupation",
              }}
              control={control}
              name="occupation"
              placeholder="Enter occupation"
            />
          </Col>
          <Col xs={24} sm={12} md={7}>
            <CustomInput
              formItem={{ required: true, label: "Contact Person" }}
              control={control}
              name="contact_person"
              placeholder="Enter contact person"
            />
          </Col>
          <Col span={24}>
            <Form.Item
              name={"additional_owner_names"}
              label="Additional Owners of Aircraft"
            >
              <Row gutter={[4, 10]}>
                {tags &&
                  tags.map((tag) => {
                    const isLongTag = tag.length > 24;
                    const tagElem = (
                      <Col>
                        <Tag
                          key={tag}
                          closable={true}
                          style={{
                            userSelect: "none",
                            fontSize: "14px",
                            lineHeight: "22px",
                            background: "white",
                          }}
                          onClose={() => handleClose(tag)}
                        >
                          {isLongTag ? `${tag.slice(0, 24)}...` : tag}
                        </Tag>
                      </Col>
                    );
                    return isLongTag ? (
                      <Col>
                        <Tooltip title={tag} key={tag}>
                          {tagElem}
                        </Tooltip>
                      </Col>
                    ) : (
                      tagElem
                    );
                  })}
                {(tags?.length ?? 0) < 5 && (
                  <Col>
                    {inputVisible ? (
                      <Input
                        ref={inputRef}
                        type="text"
                        size="small"
                        placeholder="Enter full name"
                        style={{
                          width: 200,
                          height: 22,
                          marginInlineEnd: 8,
                          verticalAlign: "top",
                        }}
                        value={inputValue}
                        onChange={handleInputChange}
                        onBlur={handleInputConfirm}
                        onPressEnter={handleInputConfirm}
                      />
                    ) : (
                      <Button type="dashed" size="small" onClick={showInput}>
                        <PlusOutlined /> Add an additional owner
                      </Button>
                    )}
                  </Col>
                )}
              </Row>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={6}>
            <CustomInput
              formItem={{ required: true, label: "Email" }}
              control={control}
              name="email"
              placeholder="Enter email"
            />
          </Col>
          <Col xs={24} sm={12} md={6}>
            <CustomInput
              formItem={{ required: true, label: "Address" }}
              control={control}
              name="address"
              placeholder="Enter address"
            />
          </Col>
          <Col xs={12} sm={6} md={5}>
            <CustomInput
              formItem={{
                required: true,
                label: "City",
              }}
              control={control}
              name="city"
              placeholder="City"
            />
          </Col>
          <Col xs={12} sm={6} md={3}>
            <CustomSelectField
              options={canadian_provinces}
              formItem={{
                required: true,
                label: "Province",
              }}
              control={control}
              name="province"
              placeholder="Province"
            />
          </Col>
          <Col xs={24} sm={24} md={4}>
            <PostalCodeInput
              formItem={{
                required: true,
                label: "Postal Code",
              }}
              control={control}
              name="postal_code"
              placeholder="Postal Code"
            />
          </Col>
          <Col span={22}>
            <PhoneTypeAndNumberInput control={control} trigger={trigger} />
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default ApplicantsInformation;
