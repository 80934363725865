import { Col, Grid, Row } from "antd";
import React from "react";

import { CustomCheckbox } from "../../../../shared/formFields/CustomCheckbox.js";
import { CustomDatePicker } from "../../../../shared/formFields/CustomDatePicker.js";

export const OptionalProductField = (props) => {
  const { control, watch, name, label, trigger, isSubmitted } = props;
  const checkboxName = `${name}_checkbox`;
  const dateName = `${name}_date`;
  const checked = watch(checkboxName);

  return (
    <div>
      <Row>
        <Col flex="1 1 10px">
          <CustomCheckbox
            name={checkboxName}
            control={control}
            isSubmitted={isSubmitted}
            onChange={() => {
              isSubmitted && trigger(dateName);
            }}
          />
        </Col>
        <Col flex="2 2 40px">{label}</Col>
        <Col flex="3 3 40px">
          <CustomDatePicker
            formItem={{ style: { marginBottom: 5 } }}
            style={{ width: 130 }}
            name={dateName}
            control={control}
            disabled={!checked}
            size="small"
            placeholder={checked ? "Select a date" : ""}
            disabledDate={null}
          />
        </Col>
      </Row>
    </div>
  );
};
