import { Form } from "antd";
import React, { memo } from "react";

import { useController } from "react-hook-form";
import { InputCurrency } from "../InputCurrency";
import { checkFormFieldsEquality } from "./checkFormFieldsEquality";

export const CustomInputCurrency = (props) => {
  const {
    field,
    fieldState: { error, isTouched, isDirty },
  } = useController({
    name: props.name,
    control: props.control,
  });

  return (
    <Content
      {...props}
      field={field}
      error={error}
      isTouched={isTouched}
      isDirty={isDirty}
    />
  );
};

const Content = memo((props) => {
  const { field, error, formItem, name, ...inputProps } = props;
  delete inputProps.isTouched;
  delete inputProps.isDirty;
  return (
    <Form.Item
      name={name}
      validateStatus={error ? "error" : ""}
      help={error?.message}
      {...formItem}
    >
      <div>
        <InputCurrency
          onBlur={field.onBlur}
          onChange={field.onChange}
          value={field.value}
          {...inputProps}
        />
      </div>
    </Form.Item>
  );
}, checkFormFieldsEquality);
