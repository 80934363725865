import {
  EyeOutlined,
  MinusOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { Button, Popconfirm, Space, Tag } from "antd";
import { isEmpty } from "lodash";
import { emptyPilotObject } from "./emptyPilotObject";

export const pilotInformationColumns = ({
  setCurrentActiveTab,
  fields,
  remove,
  update,
  currentActiveTab,
  errors,
}) => [
  {
    title: "Pilot name",
    dataIndex: "pilot_first_name",
    key: "pilot_first_name",
    width: 150,
    render: (text, record, index) => {
      return (
        <a
          style={
            (text == null || isEmpty(text)) &&
            isEmpty(record?.pilot_last_name ?? "")
              ? { fontStyle: "italic", color: "grey" }
              : null
          }
          onClick={() => {
            setCurrentActiveTab(index);
          }}
        >
          {(text == null || isEmpty(text)) &&
          isEmpty(record?.pilot_last_name ?? "")
            ? `(Pilot ${index + 1})`
            : (
                (record.pilot_first_name ?? "") +
                " " +
                (record.pilot_last_name ?? "")
              ).trim()}
        </a>
      );
    },
  },
  {
    title: "Occupation",
    dataIndex: "pilot_occupation",
    key: "pilot_occupation",
    width: 150,
    render: (text) => {
      if (text == null || isEmpty(text)) {
        return <MinusOutlined />;
      }
      return text;
    },
  },
  {
    title: "Status",
    key: "status",
    width: 120,
    align: "center",
    render: (_, __, index) => {
      const tag =
        errors?.pilots != null && errors.pilots[index.toString()]
          ? "incomplete"
          : "complete";
      return (
        <>
          <Tag
            color={tag === "incomplete" ? "volcano" : "green"}
            key={tag + index.toString()}
          >
            {tag.toUpperCase()}
          </Tag>
        </>
      );
    },
  },
  {
    title: "Action",
    key: "action",
    width: 200,
    align: "center",
    render: (_, __, index) => (
      <Space>
        <Button
          disabled={fields.length === 1}
          size="small"
          onClick={() => setCurrentActiveTab(index)}
          style={{ width: "80px" }}
        >
          <EyeOutlined /> View
        </Button>
        <Popconfirm
          disabled={fields.length === 1}
          title="Delete pilot"
          description="Are you sure you want to delete this pilot?"
          icon={<QuestionCircleOutlined style={{ color: "red" }} />}
          onConfirm={(e) => {
            if (currentActiveTab === fields.length - 1) {
              setCurrentActiveTab(Math.max(currentActiveTab - 1, 0));
            }
            remove(index);
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <Button
            size="small"
            style={{ width: "80px" }}
            danger={fields.length !== 1}
            onClick={() => {
              if (fields.length === 1) {
                update(0, emptyPilotObject);
              }
            }}
          >
            {fields.length === 1 ? "Clear" : "Delete"}
          </Button>
        </Popconfirm>
      </Space>
    ),
  },
];
