import dayjs from "dayjs";

export const compareStrings = (a, b, name) => {
  return (a?.[name]?.toString() ?? "").localeCompare(
    b?.[name]?.toString() ?? ""
  );
};

export const compareDates = (a, b, name) => {
  if (a?.[name] === b?.[name]) {
    return 0;
  }
  if (a?.[name] == null) {
    return -1;
  }
  if (b?.[name] == null) {
    return 1;
  }
  return dayjs(a[name]).isAfter(dayjs(b[name])) ? 1 : -1;
};
