import { App } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearNotifications } from "../../../store/notifications/notificationsSlice";

const NotificationsWrapper = ({ children }) => {
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.notifications);
  const { notification } = App.useApp();

  useEffect(() => {
    if (notifications.visible === true) {
      const config = {
        message: notifications.message,
        description: notifications.description,
      };
      setTimeout(() => {
        if (notifications.type === "success") {
          notification.success(config);
        } else if (notifications.type === "error") {
          notification.error(config);
        } else {
          notification.info(config);
        }
      }, 200);
      dispatch(clearNotifications());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);

  return children;
};

export default NotificationsWrapper;
