import { FileDoneOutlined, FormOutlined } from "@ant-design/icons";
import { Avatar, Button, List, Typography } from "antd";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { downloadFile } from "../../../shared/downloadFile";
var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

const HistoryList = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <List
      pagination={{
        position: "bottom",
        align: "end",
        pageSize: 6,
        defaultPageSize: 6,
      }}
      dataSource={data}
      renderItem={(item) => (
        <List.Item
          actions={
            item.category === "applications"
              ? [
                  <Button
                    size="small"
                    type="link"
                    onClick={() => {
                      dispatch(
                        downloadFile({
                          defaultName: "PrivateAviationForm",
                          url: `/private_aviation/forms/${item.form_id}/serve_pdf`,
                        })
                      );
                    }}
                  >
                    Print
                  </Button>,
                  <Button
                    size="small"
                    type="link"
                    onClick={() => {
                      navigate(`/applications/quote/${item.form_id}`);
                    }}
                  >
                    View quote
                  </Button>,
                ]
              : [
                  <Button
                    size="small"
                    type="link"
                    onClick={() => {
                      navigate(`/applications/quote/${item.form_id}`);
                    }}
                  >
                    View quote
                  </Button>,
                ]
          }
        >
          <List.Item.Meta
            avatar={
              <Avatar
                shape="square"
                style={
                  item.category === "applications"
                    ? { backgroundColor: "#1677ff" }
                    : { backgroundColor: "#87d068" }
                }
                icon={
                  item.category === "quoting" ? (
                    <FormOutlined />
                  ) : (
                    <FileDoneOutlined />
                  )
                }
              />
            }
            title={
              <div>
                <Typography.Text strong>{item.title}</Typography.Text>
                <Typography.Text
                  italic
                  style={{
                    fontSize: 13,
                    fontWeight: "400",
                    color: "rgba(0,0,0,0.8)",
                  }}
                >
                  {/* {dayjs(item.timestamp).fromNow()} */}
                  {" • " + (item.timestamp ?? "")}
                </Typography.Text>
              </div>
            }
            description={item.description}
          />
        </List.Item>
      )}
    />
  );
};

export default HistoryList;
