import { Card, Col, Flex, Row, Segmented, Typography } from "antd";
import { useState } from "react";
import ContinueApplicationForm from "./ContinueApplicationForm";
import CreateApplicationForm from "./CreateApplicationForm";

const Segments = {
  START: "START",
  CONTINUE: "CONTINUE",
};

const StartApplication = () => {
  const [applicationSegment, setApplicationSegment] = useState(Segments.START);

  return (
    <div style={{ height: "100%" }} className="centered-body-background">
      <Flex
        justify="center"
        align="center"
        className="expanded-height"
        vertical
      >
        <div
          style={{ maxWidth: 450, marginLeft: 32, marginRight: 32 }}
        >
          <Segmented
            size="large"
            options={[
              { label: "Start application", value: Segments.START },
              { label: "Continue application", value: Segments.CONTINUE },
            ]}
            block
            style={{ maxWidth: 450, marginBottom: 16 }}
            onChange={(value) => setApplicationSegment(value)}
            value={applicationSegment}
          />
          <Card>
            <Typography.Title
              level={4}
              style={{ marginTop: 0, marginBottom: 12 }}
            >
              {applicationSegment == Segments.START
                ? "Start application"
                : "Continue application"}
            </Typography.Title>
            <Typography.Text type="secondary">
              {applicationSegment == Segments.START
                ? "Begin your Private Aviation Application by entering your email. This allows you to save progress and resume later."
                : "Please input your application number, which can be found in the initial email we sent when you first applied."}
            </Typography.Text>
            <Row style={{ marginTop: 24 }}>
              <Col span={24}>
                {applicationSegment == Segments.START ? (
                  <CreateApplicationForm />
                ) : (
                  <ContinueApplicationForm />
                )}
              </Col>
            </Row>
          </Card>
        </div>
      </Flex>
    </div>
  );
};

export default StartApplication;
