import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Col, Divider, Form, Row, Skeleton } from "antd";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  createUser,
  fetchUserDetails,
  updateUser,
} from "../../store/user/userThunks";
import { clearUserEdit } from "../../store/user/userSlice";
import { CustomInput } from "../../shared/formFields/CustomInput";
import { CustomCheckbox } from "../../shared/formFields/CustomCheckbox";
import { userFormSchema } from "./userFormSchema";

const initialValues = {
  name: "",
  email: "",
  admin: false,
  disabled: null,
};

const UserForm = ({ isNew, id, onClose }) => {
  //
  const dispatch = useDispatch();

  //
  const {
    control,
    reset,
    getValues,
    formState: { isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: initialValues,
    values: initialValues,
    resolver: yupResolver(userFormSchema),
  });

  //
  const isLoading = useSelector((state) => state.user.edit.isLoading);
  const isSaving = useSelector((state) => state.user.edit.isSaving);

  //
  useEffect(() => {
    if (!isNew) {
      loadFormData();
    }
    return () => {
      dispatch(clearUserEdit());
    };
  }, []);

  const loadFormData = async () => {
    var result = await dispatch(fetchUserDetails({ id: id }));

    if (result?.meta?.requestStatus !== "fulfilled") {
      dispatch(clearUserEdit());
      onClose();
    } else {
      var payload = result.payload;

      reset({
        id: payload.id,
        access_level: payload.access_level,
        name: payload.name,
        email:
          !payload.confirmed && !payload.email
            ? payload.unconfirmed_email
            : payload.email,
        admin: payload.access_level === 0 ? false : true,
        confirmed: payload.confirmed,
      });
    }
  };

  const onSave = async () => {
    var result = null;
    var data = getValues();
    if (isNew) {
      result = await dispatch(
        createUser({
          name: data.name,
          unconfirmed_email: data.email,
          access_level: data.admin ? 1 : 0,
        })
      );
    } else {
      result = await dispatch(
        updateUser({
          id: data.id,
          user: data.confirmed
            ? {
                email: data.email,
                access_level: data.admin ? Math.max(data.access_level, 1) : 0,
                name: data.name,
              }
            : {
                unconfirmed_email: data.email,
                access_level: data.admin ? Math.max(data.access_level, 1) : 0,
                name: data.name,
              },
        })
      );
    }
    if (result?.meta?.requestStatus === "fulfilled") {
      dispatch(clearUserEdit());
      onClose(true);
    }
    return result?.meta?.requestStatus === "fulfilled" ?? false;
  };

  return (
    <Form
      name="user_management_form"
      autoComplete="off"
      layout="horizontal"
      labelWrap
      size="small"
      preserve={false}
    >
      <Divider style={{ marginTop: 16, marginBottom: 16 }} dashed />
      <div>
        <Skeleton active loading={isLoading}>
          <Row>
            <Col span={24}>
              <CustomInput
                formItem={{ required: true, label: "Full Name" }}
                control={control}
                name="name"
                placeholder="Type here"
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <CustomInput
                formItem={{ required: true, label: "Email" }}
                control={control}
                name="email"
                placeholder="Type here"
              />
            </Col>
          </Row>
          <Row justify="start" gutter={8} style={{ marginBottom: 16 }}>
            <Col>Has Admin Access?</Col>
            <Col>
              <CustomCheckbox control={control} name={"admin"} />
            </Col>
          </Row>
          {/* {!isNew && (
            <Row justify="start" gutter={8} style={{ marginBottom: 16 }}>
              <Col>Accout disabled?</Col>
              <Col>
                <CustomCheckbox control={control} name={"disabled"} />
              </Col>
            </Row>
          )} */}
          <Row justify="end" gutter={16}>
            <Col>
              <Button
                size="middle"
                onClick={() => {
                  onClose();
                }}
              >
                Close
              </Button>
            </Col>
            <Col>
              <Button
                size="middle"
                type="primary"
                disabled={!isValid}
                loading={isSaving}
                onClick={() => {
                  onSave();
                }}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Skeleton>
      </div>
    </Form>
  );
};

export default UserForm;
