import {
  LeftCircleOutlined,
  ReloadOutlined,
  RightCircleOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Col,
  Divider,
  Form,
  Grid,
  Popconfirm,
  Row,
  Typography,
} from "antd";
import { useRef } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { FormErrorsAlert } from "../../../../shared/FormErrorsAlert";
import {
  navigateToPreviousPage,
  setShowPreface,
} from "../../../../store/aviationForm/aviationFormSlice";
import { useSaveApplicationMutation } from "../../../../store/baseApi";

const PrivateAviationUserWrapper = ({
  schema,
  isFirstStep,
  isLastStep,
  children,
  onParseErrorsList,
  title = "",
  description = "",
}) => {
  const data = useSelector((state) => state.aviationForm.data);
  const formMethods = useForm({
    mode: "onChange",
    defaultValues: data,
    values: data,
    shouldFocusError: false,
    resetOptions: {
      keepDirty: false,
      keepDirtyValues: false,
      keepErrors: true,
    },
    resolver: yupResolver(schema),
  });

  const {
    control,
    handleSubmit,
    getValues,
    reset,
    trigger,
    watch,
    resetField,
    setValue,
    clearErrors,
    formState: { isDirty, errors },
  } = formMethods;

  const dispatch = useDispatch();
  const isRevalidate = useRef(false); // Has 'Next' been pressed at least once?
  const screens = Grid.useBreakpoint();
  const [saveApplication] = useSaveApplicationMutation();
  const onFinish = (values) => {
    saveApplication({
      content: { ...values, step: values.step + 1 },
    });
  };

  const onError = () => {
    document.querySelector(".scroll-body-layout").scrollTo(0, 0);
  };

  const formSpan = screens.xxl === true ? 20 : screens.xl === true ? 19 : 24;
  const showButtons = screens.xxl === true || screens.xl === true;

  return (
    <>
      <Row justify="center">
        <Col span={24} style={{ maxWidth: 1600, minWidth: 300 }}>
          <FormProvider
            control={control}
            trigger={trigger}
            watch={watch}
            resetField={resetField}
            setValue={setValue}
            getValues={getValues}
            clearErrors={clearErrors}
          >
            <Form
              name="private_aviation_user"
              autoComplete="off"
              layout="vertical"
              labelWrap
              onFinish={() => {
                isRevalidate.current = true;
                handleSubmit(onFinish, onError)();
              }}
              size="small"
            >
              <Row gutter={32}>
                <Col span={formSpan}>
                  <FormErrorsAlert
                    errors={{ ...errors }}
                    onParseErrorsList={onParseErrorsList}
                    isVisible={isRevalidate.current === true}
                  />
                  {children}
                  {!showButtons && (
                    <div style={{ textAlign: "right" }}>
                      <Row justify="end" gutter={[8, 8]}>
                        <Col>
                          <Button
                            style={{ width: "110px" }}
                            onClick={() => {
                              saveApplication({
                                content: getValues(),
                              });
                            }}
                            size="medium"
                            disabled={!isDirty}
                          >
                            <SaveOutlined /> Save
                          </Button>
                        </Col>
                        <Col>
                          <Button
                            style={{ width: "110px" }}
                            disabled={!isDirty}
                            size="medium"
                            onClick={() => {
                              reset();
                            }}
                          >
                            <ReloadOutlined /> Restore
                          </Button>
                        </Col>
                        <Col>
                          <Popconfirm
                            title="Unsaved changes"
                            description={
                              <div>
                                You have unsaved changes that you might lose.{" "}
                                <br />
                                Are you sure you want to go to the previous
                                step?
                              </div>
                            }
                            onConfirm={() => {
                              if (isFirstStep === true) {
                                dispatch(
                                  setShowPreface({
                                    showPreface: 1,
                                    data: getValues(),
                                  })
                                );
                              } else {
                                dispatch(navigateToPreviousPage());
                              }
                            }}
                            onCancel={null}
                            okText="Yes"
                            cancelText="No"
                            disabled={!isDirty}
                          >
                            <Button
                              style={{ width: "110px" }}
                              type="primary"
                              size="medium"
                              onClick={() => {
                                if (!isDirty) {
                                  if (isFirstStep === true) {
                                    dispatch(
                                      setShowPreface({
                                        showPreface: 1,
                                        data: getValues(),
                                      })
                                    );
                                  } else {
                                    dispatch(navigateToPreviousPage());
                                  }
                                }
                              }}
                            >
                              <LeftCircleOutlined /> Previous
                            </Button>
                          </Popconfirm>
                        </Col>
                        <Col>
                          <Button
                            style={{ width: "110px" }}
                            type="primary"
                            htmlType="submit"
                            size="medium"
                            disabled={
                              isRevalidate.current === true &&
                              Object.keys(errors ?? {}).length > 0
                            }
                          >
                            {isLastStep === true ? "Submit" : "Next"}
                            <RightCircleOutlined />
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  )}
                </Col>
                {showButtons && (
                  <Col span={24 - formSpan}>
                    <div style={{ position: "sticky", top: 16 }}>
                      <Row gutter={[16, 16]}>
                        <Col span={24}>
                          <Button
                            style={{ width: "100%" }}
                            type="primary"
                            htmlType="submit"
                            disabled={
                              isRevalidate.current === true &&
                              Object.keys(errors ?? {}).length > 0
                            }
                            size="large"
                          >
                            {isLastStep === true ? "Submit" : "Next"}
                          </Button>
                        </Col>
                        <Col span={24}>
                          <Popconfirm
                            title="Unsaved changes"
                            description={
                              <div>
                                You have unsaved changes that you might lose.{" "}
                                <br />
                                Are you sure you want to go to the previous
                                step?
                              </div>
                            }
                            onConfirm={() => {
                              if (isFirstStep === true) {
                                dispatch(
                                  setShowPreface({
                                    showPreface: 1,
                                    data: getValues(),
                                  })
                                );
                              } else {
                                dispatch(navigateToPreviousPage());
                              }
                            }}
                            onCancel={null}
                            okText="Yes"
                            cancelText="No"
                            disabled={!isDirty}
                          >
                            <Button
                              style={{ width: "100%" }}
                              // type="primary"
                              onClick={() => {
                                if (!isDirty) {
                                  if (isFirstStep === true) {
                                    dispatch(
                                      setShowPreface({
                                        showPreface: 1,
                                        data: getValues(),
                                      })
                                    );
                                  } else {
                                    dispatch(navigateToPreviousPage());
                                  }
                                }
                              }}
                              size="large"
                            >
                              Previous
                            </Button>
                          </Popconfirm>
                        </Col>
                        <Col span={12}>
                          <Button
                            style={{ width: "100%" }}
                            onClick={() => {
                              saveApplication({
                                content: getValues(),
                              });
                            }}
                            size="medium"
                            disabled={!isDirty}
                            type="dashed"
                          >
                            <SaveOutlined /> Save
                          </Button>
                        </Col>
                        <Col span={12}>
                          <Button
                            style={{ width: "100%" }}
                            disabled={!isDirty}
                            onClick={() => {
                              reset();
                            }}
                            type="dashed"
                            size="medium"
                          >
                            <ReloadOutlined /> Restore
                          </Button>
                        </Col>
                      </Row>
                      <Typography.Title
                        level={5}
                        style={{ marginTop: 32, marginBottom: 0 }}
                      >
                        {title}
                      </Typography.Title>
                      {title !== "" && <Divider style={{ margin: 4 }} />}
                      <Typography.Text type="secondary">
                        {description}
                      </Typography.Text>
                    </div>
                  </Col>
                )}
              </Row>
            </Form>
          </FormProvider>
        </Col>
      </Row>
    </>
  );
};

export default PrivateAviationUserWrapper;
