import { Card } from "antd";
import { useFormContext, useFormState } from "react-hook-form";

export const CardField = (props) => {
  const { control } = useFormContext();
  const { errors, isSubmitted } = useFormState({
    control: control,
    name: "hull_configuration",
    exact: true,
  });
  const showError = errors?.hull_configuration && isSubmitted;

  return (
    <div>
      <span style={{ color: "#FF4D4F" }}>* </span>Select all that apply
      <Card style={showError ? { borderColor: "#FF4D4F" } : null}>
        {props.children}
      </Card>
      {showError && (
        <span style={{ color: "#FF4D4F" }}>
          At least 1 hull configuration required
        </span>
      )}
    </div>
  );
};
