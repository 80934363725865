import { Form, InputNumber } from "antd";
import React, { memo } from "react";

import { useController } from "react-hook-form";
import { checkFormFieldsEquality } from "./checkFormFieldsEquality";

export const CustomInputNumber = (props) => {
  const {
    field,
    fieldState: { error, isTouched, isDirty },
  } = useController({
    name: props.name,
    control: props.control,
  });

  return (
    <Content
      {...props}
      field={field}
      error={error}
      isTouched={isTouched}
      isDirty={isDirty}
    />
  );
};

const Content = memo((props) => {
  const {
    field,
    error,
    formItem,
    name,
    shouldHideErrors,
    showErrors,
    ...inputProps
  } = props;
  delete inputProps.isTouched;
  delete inputProps.isDirty;
  return (
    <Form.Item
      name={name}
      validateStatus={error && (!shouldHideErrors || showErrors) ? "error" : ""}
      help={
        (!shouldHideErrors || showErrors) && error?.message != null
          ? error?.message
          : null
      }
      {...formItem}
    >
      <div>
        {props.children}
        <InputNumber
          {...field}
          {...inputProps}
          controls={false}
          style={props.style ? props.style : { width: "100%" }}
        />
      </div>
    </Form.Item>
  );
}, checkFormFieldsEquality);
